import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo"
export default class extends Controller {
  static get targets() {
    return [ "sidebarContainer", "icon", "link" ]
  }

  toggle() {
    if (this.sidebarContainerTarget.dataset.expanded === "1") {
      this.collapse()
    } else {
      this.expand()
    }
  }

  collapse() {
    // collapse nav bar
    let sidebar_custom = document.getElementById("sidebar-custom");
    sidebar_custom.classList.add("sidebar-collapse");
    sidebar_custom.classList.remove("sidebar-expand");

    document.getElementById("sidebar-logo").classList.add("hidden");
    document.getElementById("sidebar-logo-id").classList.add("hidden");
    document.getElementById("sidebar-icon").classList.remove("hidden");
    document.getElementById("sidebar-icon-id").classList.remove("hidden");
    
    document.getElementById("sidebar-logo-id").classList.remove("ml-5");
    document.getElementById("sidebar-logo-id").classList.add("ml-1");

    document.getElementById("bottom-sidebar").classList.add("bottom-sidebar-collapse");
    document.getElementById("bottom-sidebar").classList.remove("bottom-sidebar-expand");

    document.getElementById("bottom-nav").style.display= "inline";
    this.sidebarContainerTarget.dataset.expanded = "0"
    this.linkTargets.forEach(link => {
      link.classList.add("sr-only")
    })
  }

  expand() {
    // follow default marginleft || if default change then need change here also
    let sidebar_custom = document.getElementById("sidebar-custom");
    sidebar_custom.classList.remove("sidebar-collapse");
    sidebar_custom.classList.add("sidebar-expand");

    document.getElementById("sidebar-logo").classList.remove("hidden");
    document.getElementById("sidebar-logo-id").classList.remove("hidden");
    document.getElementById("sidebar-icon").classList.add("hidden");
    document.getElementById("sidebar-icon-id").classList.add("hidden");
    
    document.getElementById("sidebar-logo-id").classList.add("ml-5");
    document.getElementById("sidebar-logo-id").classList.remove("ml-1");

    document.getElementById("bottom-sidebar").classList.remove("bottom-sidebar-collapse");
    document.getElementById("bottom-sidebar").classList.add("bottom-sidebar-expand");

    document.getElementById("bottom-nav").style.display= "";
    this.sidebarContainerTarget.dataset.expanded = "1"
    this.linkTargets.forEach(link => {
      link.classList.remove("sr-only")
    })
  }
}
