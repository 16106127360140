// import { Controller } from 'stimulus'
import { Controller } from "@hotwired/stimulus"
import createApp from '@shopify/app-bridge'
import { Toast } from '@shopify/app-bridge/actions'
import { getSessionToken } from "@shopify/app-bridge/utilities"

export default class extends Controller {
  connect () {
    const data = document.getElementById('shopify-app-init').dataset
    const createdApp = createApp({
      apiKey: data.apiKey,
      shopOrigin: data.shopOrigin,
      host: data.host,
      forceRedirect: true
    })

    getSessionToken(createdApp).then(token => {
      fetch(`/verify_token?shop=${data.shopDomain}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ token: token })
      })
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          console.log("Authenticated!");
        } else {
          console.error("Authentication failed.");
        }
      });
    });
    
    this.appBridge = createdApp
  }

  flashNotice (options) {
    options.isError = false
    const toastNotice = Toast.create(this.appBridge, options)
    toastNotice.dispatch(Toast.Action.SHOW)
  }

  flashError (options) {
    options.isError = true
    const toastNotice = Toast.create(this.appBridge, options)
    toastNotice.dispatch(Toast.Action.SHOW)
  }
}