import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to campaign");
    if (!window.location.href.includes("mass_edit")) this.onchangeCampaignType();
  }

  displaySpendingLimit(e) {
    let form = document.getElementById("spending_limit_form");
    if(e.target.checked){
      form.classList.remove("hidden");
    } else {
      form.classList.add("hidden");
    }
  }

  spendingLimitRealtimeDisplay(e){
    let spendingLimit = document.getElementById("spending-limit")
    if(e.target.value === ""){
      spendingLimit.innerHTML = "$0.00 limit set"
    } else {
      spendingLimit.innerHTML = (parseFloat(e.target.value) % 1 != 0) ? ("$" + e.target.value + " limit set") : ("$" + e.target.value + ".00" + " limit set")
    }
  }

  showCustomGoals() {
    let arrow_down = document.getElementById("arrow_down_goals");
    let arrow_up = document.getElementById("arrow_up_goals");
    let custom_goals = document.getElementById("custom_goals");

    if(arrow_down && arrow_down.classList.contains("hidden")) {
      arrow_down.classList.remove("hidden");
      arrow_up.classList.add("hidden");
      custom_goals.classList.add("hidden");
    } else {
      arrow_down.classList.add("hidden");
      arrow_up.classList.remove("hidden");
      custom_goals.classList.remove("hidden");
    }
  }

  onchangeCampaignType() {
    let campaign_type = document.getElementById("campaign-type");
    let conversion_goal = document.getElementById("conversion-goal");
    let merchant_center = document.getElementById("merchant-center");
    // let optimizations = document.getElementById("optimizations");
    let campaign_date = document.getElementById("campaign-date");
    let campaign_g_start_date = document.getElementById("campaign_g_start_date");
    let merchant_id_field = document.getElementById("campaign_g_merchant_id");
    let country_sales = document.getElementById("country-sales");
    let campaign_subtype_shopping = document.getElementById("campaign_subtype_shopping");
    let campaign_subtype_performance_max = document.getElementById("campaign_subtype_performance_max");
    let campaign_subtype_form = document.getElementById("campaign-subtype");

    if (merchant_id_field && merchant_id_field.hasAttribute("reviewpage")) merchant_id_field.removeAttribute("reviewpage");
    if (country_sales && country_sales.hasAttribute("reviewpage")) country_sales.removeAttribute("reviewpage");
    if (campaign_subtype_shopping && campaign_subtype_shopping.hasAttribute("reviewpage")) campaign_subtype_shopping.removeAttribute("reviewpage");
    if (campaign_subtype_performance_max && campaign_subtype_performance_max.hasAttribute("reviewpage")) campaign_subtype_performance_max.removeAttribute("reviewpage");
    
    // set all div into hidden
    // let div_arr = [conversion_goal, merchant_center, optimizations, campaign_date]
    let div_arr = [conversion_goal, merchant_center, campaign_date]
    for(let x in div_arr) {
      if (!div_arr[x].classList.contains("hidden")) {
        div_arr[x].classList.add("hidden");
      }
    }

    campaign_g_start_date.required = true;
    merchant_id_field.required = false;

    let show_div_arr = []
    switch(campaign_type.value) {
      case "search":
        show_div_arr = [conversion_goal, campaign_date]
        break;
      case "shopping":
        merchant_id_field.required = true;
        show_div_arr = [conversion_goal, merchant_center, campaign_date]
        this.getCountryofSales();
        this.getFeedLabel();
        merchant_id_field.setAttribute("reviewpage",'{"name": "Linked Account", "selectbox": "yes"}')
        if (country_sales) country_sales.setAttribute("reviewpage",'{"name": "Country of Sale", "selectbox": "yes"}')
        if (campaign_subtype_shopping) campaign_subtype_shopping.setAttribute("reviewpage",'{"group": "Campaign Subtype", "radio": "yes"}')
        if (campaign_subtype_performance_max) campaign_subtype_performance_max.setAttribute("reviewpage",'{"group": "Campaign Subtype", "radio": "yes"}')
        break;
      case "display":
        show_div_arr = [conversion_goal, campaign_date]
        break;
      case "smart":
        show_div_arr = []
        campaign_g_start_date.required = false;
        break;
      case "performance_max":
        merchant_id_field.required = false;
        show_div_arr = [conversion_goal, merchant_center, campaign_date]
        this.getCountryofSales();
        this.getFeedLabel();
        merchant_id_field.setAttribute("reviewpage",'{"name": "Linked Account", "selectbox": "yes"}')
        if (country_sales) country_sales.setAttribute("reviewpage",'{"name": "Country of Sale", "selectbox": "yes"}')
        if (campaign_subtype_form) campaign_subtype_form.classList.add("hidden");
        break;
      default:
        show_div_arr = []
    }
    
    // set disable hidden div
    for(let x in show_div_arr) {
        show_div_arr[x].classList.remove("hidden");
    }    
  }

  onchangeCampaignObjective() {
    let campaign_objective = document.getElementById("campaign-objective");
    let campaign_type = document.getElementById("campaign-type");

    Rails.ajax({
      url: '/google/get_campaign_type_option',
      type: 'GET',
      data: "objective=" + campaign_objective.value,
      success: function(data) {
        // reset campaign_type option
        campaign_type.innerHTML = ''
        for(let d in data) {
          let option = document.createElement("option");
          let option_text = data[d].replace("_", " ");
          option.text = option_text[0].toUpperCase() + option_text.substring(1);
          option.value = data[d];
          campaign_type.add(option);
        }
      }
    });
  }

  getCountryofSales() {
    let campaign_g_merchant_id = document.getElementById("campaign_g_merchant_id");
    let country_sales = document.getElementById("country-sales");
    let selected_country = this.data.get("selected-country")
    Rails.ajax({
      url: '/google/get_country_of_sales',
      type: 'GET',
      data: "merchant_id=" + campaign_g_merchant_id.value,
      success: function(data) {
        // reset country_sales option
        country_sales.innerHTML = ''
        for(let d in data) {
          let option = document.createElement("option");
          let option_text = data[d]["name"].replace("_", " ");
          option.text = option_text[0].toUpperCase() + option_text.substring(1);
          option.value = data[d]["id"];
          country_sales.add(option);
        }
        country_sales.value = selected_country;
      }
    });
  }

  getFeedLabel() {
    let campaign_g_merchant_id = document.getElementById("campaign_g_merchant_id");
    let feed_label = document.getElementById("feed-label");
    let selected_feed_label = this.data.get("selected-feed-label")
    Rails.ajax({
      url: '/google/get_feed_labels',
      type: 'GET',
      data: "merchant_id=" + campaign_g_merchant_id.value,
      success: function(data) {
        // reset country_sales option
        feed_label.innerHTML = ''
        for(let d in data) {
          let option = document.createElement("option");
          let option_text = data[d]["name"].replace("_", " ");
          option.text = option_text[0].toUpperCase() + option_text.substring(1);
          option.value = data[d]["id"];
          feed_label.add(option);
        }
        feed_label.value = selected_feed_label;
      }
    });
  }
}