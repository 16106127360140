import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => this.fadeOut(), 15000)
  }

  fadeOut(e) {
    let flash = document.getElementById("flash");
    flash.classList.add("fadeOut");
    setTimeout(function() {
        flash.classList.add("hidden");
    }, 1000);
  }
}