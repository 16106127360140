import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to Ad Account");
    // get dashboard metric
    this.getDashboardMetric();
  }

  update_active_ad_account(e){
    Rails.ajax({
      url: '/update_active_ad_account',
      type: 'PATCH',
      data: "ad_account_id=" + e.target.value,
      success: function(data) {
        window.location.href = "/";
      }
    });
  }

  addSidebarList(title) {
    let new_div = document.createElement("div");
    let new_span = document.createElement("span");
    new_span.innerText = title;
    // styling header 
    if (title == "Date presets") {
      new_div.classList.add("font-bold", "pl-2");
    } else {
      // styling sidebar list
      new_div.classList.add("custom-sidebar", "pl-2");
      new_div.dataset.action = "click->ad-accounts#setCalendar";
      new_div.dataset.name = title;
      new_span.classList.add("p4","text-monochrome-grey-blue-dark-1","select-none");
    }

    new_div.appendChild(new_span);
    return new_div
  }

  // Add Custom UI sidebar into default of calendar pickr plugin
  calendarSideRightBar(e) {
    let calendar_sidebar = document.getElementById("calendar-custom-side-bar");
    if (!calendar_sidebar) {
      // change width for default calendar size
      let calendar_flatpickr = document.getElementsByClassName("flatpickr-calendar rangeMode")[0];
      calendar_flatpickr.style.width = "450px";
      
      // add sidebar list
      let list_sidebar = ["Date presets","Maximum","Today","Yesterday","Last 7 days","Last 14 days","Last 30 days","This week","Last week","This month","Last month"];
      let sidebar = document.createElement("div");
      sidebar.id = "calendar-custom-side-bar"
      sidebar.classList.add("text-left", "w-full", "bg-monochrome-grey-light-1");
      sidebar.dataset.controller = "ad-accounts"
      for (let list in list_sidebar) {
        sidebar.appendChild(this.addSidebarList(list_sidebar[list]));
      }

      // add sidebar list into inside flatpickr inner container
      let flatpickr_innerContainer = document.getElementsByClassName("flatpickr-innerContainer")[0];
      flatpickr_innerContainer.appendChild(sidebar);
    }
  }

  setCalendar(e) {
    if (e.target && e.target.innerText && e.target.innerText != "search") {
      // select from date present / sidebar menu list
      document.cookie = `date_preset=${e.target.innerText}`;
    } else {
      // click button search on calendar
      let calendar_sidebar = document.getElementById("calendar_calendar");
      document.cookie = `date_preset=`;
      document.cookie = `date_preset_custom=${calendar_sidebar.value}`;
    }
    window.location.href = document.URL;
  }

  getDashboardMetric() {
    // set calendar value to cookie
    let calendar_sidebar = document.getElementById("calendar_calendar");
    if (calendar_sidebar) document.cookie = `calendar_value=${calendar_sidebar.value}`;
    
    Rails.ajax({
      url: `/get_dashboard_metrics`,
      type: 'POST',
      data: `campaigns=${this.data.get("campaigns")}&adsets=${this.data.get("adsets")}&ads=${this.data.get("ads")}`,
      success: function(data) {
        // object key is GA or Metric 
        if (typeof data === 'object') {
          for(const ga_or_metric in data) {
            for(const arr in data[ga_or_metric]) {
              // loop ids campaign, adset or ads
              for(const id_obj in data[ga_or_metric][arr]) {
                // loop for object key data
                for(const obj_key in data[ga_or_metric][arr][id_obj]) {
                  // set value into related field
                  let element = document.getElementById(`${id_obj}_${obj_key}`);
                  if (element) {
                    element.classList.remove("animate-pulse");
                    element.innerHTML = data[ga_or_metric][arr][id_obj][obj_key];
                  }
                }
              }
            }
          }
        }

        let arr_div_animate = document.getElementsByClassName(`animate-pulse`);
        for(const i in arr_div_animate) {
          if (arr_div_animate[i] && arr_div_animate[i].innerHTML) {
            arr_div_animate[i].innerHTML = "-";
          }
        }
      }
    });
  }
}

