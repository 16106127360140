import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to Organizations");
  }

  get_member_list(e){
    let clientSpinner = document.getElementById("client-spinner");
    clientSpinner.classList.remove("hidden");
    Rails.ajax({
      url: '/organizations/' + e.currentTarget.dataset.organization + '/get_members',
      type: 'GET',
      // data: "organization_id=" + e.currentTarget.dataset.organization,
      success: function(data) {
        clientSpinner.classList.add("hidden");
        // Find modal based on clicked button's data-modal attribute
        let modal = document.getElementById("form-member-modal");
        let modalContent = document.getElementById("member-modal-content");
        // Unhide the modal
        modal.classList.remove("hidden");
        // empty modal content every refresh
        modalContent.innerHTML = "";
        data.forEach(member => {
          let label = document.createElement("label");
          label.classList.add("my-1", "ml-3", "text-monochrome-grey-blue-dark-1");
          label.htmlFor = "members";
          let input = document.createElement("input");
          input.type = "checkbox";
          input.name = "members[]";
          input.id = "members_";
          input.value = member["id"];
          input.classList.add("my-2");
          let span = document.createElement("span");
          span.classList.add("p3", "ml-3");
          span.innerHTML = member["email"]
          label.appendChild(input);
          label.appendChild(span);
          modalContent.appendChild(label);
        });
      }
    });
  }

}