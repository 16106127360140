import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    ad_account: Boolean,
    ga_account: Boolean,
    ga_property: Boolean
  }
  
  initialize() {
    this.ad_account = this.ga_account = this.ga_property = false
  }

  connect() {
  }

  checkAllChecked(e) {
    switch(e.currentTarget.dataset.checked) {
      case "ad_account":
        this.ad_account = true
        break

      case "ga_account":
        this.ga_account = true
        this.ga_property = false
        break

      case "ga_property":
        this.ga_property = true
        break
    }
    let isAllChecked = this.ad_account && this.ga_account && this.ga_property

    let element = document.querySelector("input[type=submit]")
    if (element.classList.contains("btn-primary-disabled")) element.classList.remove("btn-primary-disabled")
    if (element.classList.contains("btn-primary-signal-green")) element.classList.remove("btn-primary-signal-green")
    isAllChecked ? element.classList.add("btn-primary-signal-green") : element.classList.add("btn-primary-disabled")
  }
}
