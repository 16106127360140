import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to toggle");
  }

  turn_on_off_campaign(e){
    this.showPageLoader();
    let platform = e.target.dataset.platform && e.target.dataset.platform == "google" ? "google" : "facebook"
    Rails.ajax({
      url: `/${platform}/campaigns/${e.target.dataset.campaignId}/update_status`,
      type: 'PATCH',
      data: "campaign_id=" + e.target.dataset.campaignId + "&status=" + e.target.checked,
      success: function(data) {
        Turbo.visit("/")
      },
      complete: (response) => {
        this.hiddenPageLoader();
      }
    });
  }

  turn_on_off_adset(e){
    this.showPageLoader();
    let platform = e.target.dataset.platform && e.target.dataset.platform == "google" ? "google" : "facebook"
    Rails.ajax({
      url: `/${platform}/adsets/${e.target.dataset.adsetId}/update_status`,
      type: 'PATCH',
      data: "adset_id=" + e.target.dataset.adsetId + "&status=" + e.target.checked,
      success: function(data) {
        Turbo.visit("/")
      },
      complete: (response) => {
        this.hiddenPageLoader();
      }
    });
  }

  turn_on_off_ad(e){
    this.showPageLoader();
    let platform = e.target.dataset.platform && e.target.dataset.platform == "google" ? "google" : "facebook"
    Rails.ajax({
      url: `/${platform}/ads/${e.target.dataset.adId}/update_status`,
      type: 'PATCH',
      data: "ad_id=" + e.target.dataset.adId + "&status=" + e.target.checked,
      success: function(data) {
        Turbo.visit("/")
      },
      complete: (response) => {
        this.hiddenPageLoader();
      }
    });
  }

  showPageLoader(e) {
    let page_loader = document.getElementById("page-loader");
    page_loader.classList.remove("opacity-0");
    page_loader.classList.add("absolute");
    page_loader.classList.add("opacity-30");
  }

  hiddenPageLoader(e) {
    let page_loader = document.getElementById("page-loader");
    page_loader.classList.remove("opacity-30");
    page_loader.classList.remove("absolute");
    page_loader.classList.add("opacity-0");
  }
}