import { Controller } from "@hotwired/stimulus"

import 'tom-select'
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    console.log('tom_select_controller connected');

    const selectInputs = document.querySelectorAll('.select-tags');
    selectInputs.forEach(selectInput => {
      if (selectInput){
        new TomSelect(selectInput, {
          persist: false,
          createOnBlur: true,
          create: true
        })
      }
    })
  }
}