import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to UTM controller");
  }

  showUtmSourceAndMedium(e){
    let facebookUtm = document.getElementById("facebook-utm")
    let googleUtm = document.getElementById("google-utm")
    if(e.target.dataset.platform === "facebook"){
      if(facebookUtm.classList.contains("hidden")){
        facebookUtm.classList.remove("hidden")
      } else {
        facebookUtm.classList.add("hidden")
      }
    } else {
      if(googleUtm.classList.contains("hidden")){
        googleUtm.classList.remove("hidden")
      } else {
        googleUtm.classList.add("hidden")
      }
    }
  }
}