import ApplicationController from '../application_controller'
import { Redirect, AppLink, NavigationMenu } from '@shopify/app-bridge/actions'

export default class extends ApplicationController {
  initialize () {
    super.initialize()
  }

  connect () {
    super.connect()
    const app = this.appBridge

    // App Link / Navigation | START Code
    const viewCampaignsLink = AppLink.create(app, {
        label: 'View Campaigns',
        destination: '/api_app_shopify',
      });
      const createCampaignLink = AppLink.create(app, {
        label: 'Create Campaign',
        destination: '/shopify_create_campaign',
      });

    // create NavigationMenu with no active links
      const navigationMenu = NavigationMenu.create(app, {
        items: [viewCampaignsLink, createCampaignLink],
        active: viewCampaignsLink,
      });  
  }

  redirect_to_create_campaign() {
    const redirect = Redirect.create(this.appBridge);
    redirect.dispatch(Redirect.Action.APP, '/shopify_create_campaign');
  }

  disconnect () {
    super.disconnect()
  }
}