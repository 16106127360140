import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ "modal" ]
  connect() {
    console.log("Im connected to Audiences");
  }

  displayAudience(e){
    // Get back the form elements for saved audience
    let locationForm = document.getElementById("audience-location");
    let excludeLocationForm = document.getElementById("audience-exclude-location");
    let ageRangeForm = document.getElementById("audience-age");
    let genderForm = document.getElementById("audience-gender");
    let languageForm = document.getElementById("audience-language");
    let detailedTargetingForm = document.getElementById("audience-detailed-targeting");
    let excludedDetailedTargetingForm = document.getElementById("audience-excluded-detailed-targeting");

    // Map location to display the attribute for saved audience
    let locations = document.querySelectorAll('input[name="location_lists[]"]');
    let mapLocations = [...locations].map(l => JSON.parse(l.value)["name"]).join(", ");
    locationForm.innerHTML = "Location: <b>" + mapLocations + "</b>";

    let excludeLocations = document.querySelectorAll('input[name="exclude_location_lists[]"]');
    let mapExcludeLocations = [...excludeLocations].map(l => JSON.parse(l.value)["name"]).join(", ");
    excludeLocationForm.innerHTML = "Excluded Location: <b>" + mapExcludeLocations + "</b>";

    let minAge = document.getElementById("min_age");
    let maxAge = document.getElementById("max_age");
    ageRangeForm.innerHTML = "Age: <b>" + minAge.value + " - " + maxAge.value + "</b>";

    // Get gender data
    let gender = document.querySelector('input[name="gender"]:checked');
    if (gender.value == 0){
      genderForm.innerHTML = "Gender: <b>All Genders</b>"
    } else if (gender.value == 1){
      genderForm.innerHTML = "Gender: <b>Male</b>"
    } else {
      genderForm.innerHTML = "Gender: <b>Female</b>"
    }

    // ensure narrow further data is appended
    let targetings = document.querySelectorAll('input[name^="selected_items"]');
    let groupedData = {};

    targetings.forEach(item => {
        let parsedItem = JSON.parse(item.value);
        let groupKey = item.name.replace("selected_items", "");

        if (!groupedData[groupKey]) {
            groupedData[groupKey] = [];
        }

        groupedData[groupKey].push(parsedItem["name"]);
    });
    
    let detailedTargetingText = "Detailed Targeting: ";

    let groupTexts = [];
    for (let key in groupedData) {
        if (groupedData[key].length > 0) {
            groupTexts.push("<b>" + groupedData[key].join(", ") + "</b>");
        }
    }

    if (groupTexts.length > 0) {
        detailedTargetingText += groupTexts.join(" AND ");
    } else {
        detailedTargetingText += "<b>Off</b>";
    }

    detailedTargetingForm.innerHTML = detailedTargetingText;


    //exclude targeting 
    let excluded_targetings =  document.querySelectorAll('input[name^="exclude_selected_items"]');
    let excludedGroupedData = {};

    excluded_targetings.forEach(item => {
      let parsedItem = JSON.parse(item.value);
      let groupKey = item.name.replace("exclude_selected_items", "");

      if (!excludedGroupedData[groupKey]) {
        excludedGroupedData[groupKey] = [];
      }

      excludedGroupedData[groupKey].push(parsedItem["name"]);
    });

    let excludedDetailedTargetingText = "Excluded Targeting: ";

    let excludedGroupTexts = [];
    for (let key in excludedGroupedData) {
        if (excludedGroupedData[key].length > 0) {
            excludedGroupTexts.push("<b>" + excludedGroupedData[key].join(", ") + "</b>");
        }
    }
    if (excludedGroupTexts.length > 0) {
      excludedDetailedTargetingText += excludedGroupTexts.join(" AND ");
    } else {
      excludedDetailedTargetingText += "<b>Off</b>";
    }

    excludedDetailedTargetingForm.innerHTML = excludedDetailedTargetingText;
  }

  saveAudience(e) {
    e.preventDefault();
    let audienceName = document.getElementById("audience_name");
    // Get back the form elements for saved audience
    let locations = document.querySelectorAll('input[name="location_lists[]"]');
    let mapLocations = [...locations].map(l => l.value);
    let excludeLocations = document.querySelectorAll('input[name="exclude_location_lists[]"]');
    let mapExcludeLocations = [...excludeLocations].map(l => l.value);
    let minAge = document.getElementById("min_age");
    let maxAge = document.getElementById("max_age");
    let gender = document.querySelector('input[name="gender"]:checked');
    let languages = document.querySelectorAll('input[name="locale_lists[]"]');
    let mapLanguages = [...languages].map(l => l.value);

    let modalTarget = this.modalTarget;
    let bgModal = document.getElementById("modal-background");

    // Audience Dropdown
    let savedAudienceDropdown = document.getElementById("saved_audience");
    // Audience section
    let createAudience = document.getElementById("create-audience");
    let saveAudience = document.getElementById("save-audience");
    // Saved audience radio button
    let savedAudienceRadioButton = document.getElementById("audience_saved_audience");
    // Locationtype
    let locationType = document.getElementById("adset_location_type");
    let detailedTargetingExpansion = document.getElementById("detailed_targeting_expansion");
    let detailedTargetingExpansionValue = (detailedTargetingExpansion) ? detailedTargetingExpansion.checked : false;

    // Frontend display of data
    let genderDisplay;
    let audienceNameDisplay = document.getElementById("save-audience-name");
    let audienceLocationDisplay = document.getElementById("save-audience-location");
    let audienceExcludeLocationDisplay = document.getElementById("save-audience-exclude-location")
    let audienceAgeDisplay = document.getElementById("save-audience-age");
    let audienceGenderDisplay = document.getElementById("save-audience-gender");
    let audiencedetailedTargetingDisplay = document.getElementById("save-audience-detailed-targeting"); 
    let audienceexcludedDetailedTargetingDisplay = document.getElementById("save-audience-excluded-detailed-targeting"); 
    let audienceLanguageDisplay = document.getElementById("save-audience-languages"); 
    let audienceLocationTypeDisplay = document.getElementById("save-audience-location-type"); 
    
    // Audience Targeting - with Narrow Futher
    let obj_targetings = {}
    let targetings = document.querySelectorAll('input[name^="selected_items"]');
    for (let i in targetings) {

      if (targetings[i] && targetings[i].id) {
        if (obj_targetings && targetings[i].id in obj_targetings) {
          obj_targetings[targetings[i].id].push(targetings[i].value)
        } else {
          obj_targetings[targetings[i].id] = [targetings[i].value];
        }
      }
    }
    
    let obj_exclude_targetings = {}
    let exclude_targetings = document.querySelectorAll('input[name^="exclude_selected_items"]');
    for (let i in exclude_targetings) {

      if (exclude_targetings[i] && exclude_targetings[i].id) {
        if (obj_exclude_targetings && exclude_targetings[i].id in obj_exclude_targetings) {
          obj_exclude_targetings[exclude_targetings[i].id].push(exclude_targetings[i].value)
        } else {
          obj_exclude_targetings[exclude_targetings[i].id] = [exclude_targetings[i].value];
        }
      }
    }
    

    // Send data to adapter for reach estimates returns
    Rails.ajax({
      url: '/clients/' + e.target.dataset.clientId + '/audiences',
      type: 'POST',
      // Encode URI component for targeting in case of targeting having query params which contains & or + within
      data: "client_id=" + e.target.dataset.clientId + "&audience_name=" + audienceName.value + "&language_data=" + mapLanguages + "&targeting_data=" + encodeURIComponent(JSON.stringify(obj_targetings)) + "&excluded_targeting_data=" + encodeURIComponent(JSON.stringify(obj_exclude_targetings)) + "&location_data=" + mapLocations + "&exclude_location_data=" + mapExcludeLocations + "&min_age=" + minAge.value + "&max_age=" + maxAge.value + "&gender=" + gender.value + "&location_type=" + locationType.value + "&detailed_targeting_expansion=" + detailedTargetingExpansionValue,
      success: function(data) {
        // Show saved audience section
        createAudience.classList.add("hidden");
        saveAudience.classList.remove("hidden");
        // Remove existing saved audience and display new audience dropdown based on created audience
        savedAudienceDropdown.remove();
        // Create select dropdown with created audience option
        let newAudienceDropdown = document.createElement("select");
        let audienceOption = document.createElement("option");
        audienceOption.value = data.id;
        audienceOption.innerHTML = data.name;
        newAudienceDropdown.name = "saved_audience";
        newAudienceDropdown.id = "saved_audience";
        newAudienceDropdown.classList.add("w-10/12");
        newAudienceDropdown.appendChild(audienceOption);
        saveAudience.appendChild(newAudienceDropdown);
        savedAudienceRadioButton.checked = true;

        let locations = data["location"].map(l => l["name"]).join(", ");
        let exclude_locations = data["exclude_location"].map(l => l["name"]).join(", ")
        // After creating audience, redirect to "Use Saved Audience" and display audience's attributes
        if(data.gender === null || parseInt(data.gender[0]) == 0){
          genderDisplay = "All Gender";
        } else if (parseInt(data.gender[0]) == 1){
          genderDisplay = "Male";
        } else {
          genderDisplay = "Female";
        }

        // Need to account for narrow further data too
        let detailedTargetings = "";
        if (data["detailed_targeting"] && data["detailed_targeting"][0] && data["detailed_targeting"][0][0]) {
          for (let detailed_targeting in data["detailed_targeting"]) {
            console.log("detailed_targeting : ",data["detailed_targeting"][detailed_targeting])
            detailedTargetings += data["detailed_targeting"][detailed_targeting].map(l => l["name"]).join(", ");
            detailedTargetings += " AND "
          }
          detailedTargetings = detailedTargetings.slice(0, -4);
        } else if (data["detailed_targeting"]) {
          detailedTargetings = data["detailed_targeting"].map(l => l["name"]).join(", ");
        } 

        let excludedDetailedTargetings = "";
        if (data["excluded_targeting"] && data["excluded_targeting"][0] && data["excluded_targeting"][0][0]) {
          for (let excluded_targeting in data["excluded_targeting"]) {
            console.log("excluded_targeting : ",data["excluded_targeting"][excluded_targeting])
            excludedDetailedTargetings += data["excluded_targeting"][excluded_targeting].map(l => l["name"]).join(", ");
            excludedDetailedTargetings += " AND "
          }
          excludedDetailedTargetings = excludedDetailedTargetings.slice(0, -4);
        } else if (data["excluded_targeting"]) {
          excludedDetailedTargetings = data["excluded_targeting"].map(l => l["name"]).join(", ");
        } 
        
        audienceNameDisplay.innerHTML = "Audience: <b>" + data.name + "</b>";
        audienceLocationDisplay.innerHTML = "Location: <b>" + locations + "</b>";
        audienceExcludeLocationDisplay.innerHTML = "Excluded Location: <b>" + exclude_locations + "</b>";
        audienceAgeDisplay.innerHTML = "Age: <b>" + data.min_age + " - " + data.max_age + "</b>";
        audienceGenderDisplay.innerHTML = "Gender: <b>" + genderDisplay + "</b>";
        audiencedetailedTargetingDisplay.innerHTML = "Detailed Targeting: <b>" + detailedTargetings + "</b>";
        audienceexcludedDetailedTargetingDisplay.innerHTML = "Excluded Targeting: <b>" + excludedDetailedTargetings + "</b>";
        
        if(data.languages) audienceLanguageDisplay.innerHTML = "Languages: <b>" + data.languages + "</b>";
        if (data["location_type"]) {
          let location_type;
          switch(data["location_type"]) {
            case "recent_or_home":
              location_type = "People living in or recently in this location";
              break;
            case "home":
              location_type = "People living in this location";
              break;
            case "recent":
              location_type = "People recently in this location";
              break;
            case "travel_in":
              location_type = "People travelling in this location";
              break;
            default:
              location_type = "";
          }
          audienceLocationTypeDisplay.innerHTML = "Location Type: <b>" + location_type + "</b>";
        }

        // Remove modal
        modalTarget.classList.add("hidden");
        bgModal.remove();
      }
    })
  }
  // Show audience data based on what is selected
  showAudienceData(e){
    let gender;
    let audienceName = document.getElementById("save-audience-name");
    let audienceLocation = document.getElementById("save-audience-location");
    let audienceExcludeLocation = document.getElementById("save-audience-exclude-location");
    let audienceAge = document.getElementById("save-audience-age");
    let audienceGender = document.getElementById("save-audience-gender");
    let audienceLocationType = document.getElementById("save-audience-location-type"); 
    let audiencedetailedTargeting = document.getElementById("save-audience-detailed-targeting"); 
    let audienceexcludedDetailedTargeting = document.getElementById("save-audience-excluded-detailed-targeting"); 
    let audienceLanguage = document.getElementById("save-audience-languages"); 
    let select_tag = document.getElementById("saved_audience");
    if (select_tag.value !== ""){
      Rails.ajax({
        url: '/clients/' + e.target.dataset.clientId + '/find_audience_data',
        type: 'POST',
        data: "client_id=" + e.target.dataset.clientId + "&audience_id=" + e.target.value,
        success: function(data) {
          let locations = data["location"].map(l => l["name"]).join(", ");
          let exclude_locations = data["exclude_location"].map(l => l["name"]).join(", ");
          let detailedTargetings = "";
          if (data["detailed_targeting"] && data["detailed_targeting"][0] && data["detailed_targeting"][0][0]) {
            for (let detailed_targeting in data["detailed_targeting"]) {
              console.log("detailed_targeting : ",data["detailed_targeting"][detailed_targeting])
              detailedTargetings += data["detailed_targeting"][detailed_targeting].map(l => l["name"]).join(", ");
              detailedTargetings += " AND "
            }
            detailedTargetings = detailedTargetings.slice(0, -4);
          } else if (data["detailed_targeting"]) {
            detailedTargetings = data["detailed_targeting"].map(l => l["name"]).join(", ");
          } 
          
          let excludedTargetings = "";
          if (data["excluded_targeting"] && data["excluded_targeting"][0] && data["excluded_targeting"][0][0]) {
            for (let excluded_targeting in data["excluded_targeting"]) {
              console.log("excluded_targeting : ",data["excluded_targeting"][excluded_targeting])
              excludedTargetings += data["excluded_targeting"][excluded_targeting].map(l => l["name"]).join(", ");
              excludedTargetings += " AND "
            }
            excludedTargetings = excludedTargetings.slice(0, -4);
          } else if (data["excluded_targeting"]) {
            excludedTargetings = data["excluded_targeting"].map(l => l["name"]).join(", ");
          } 
  
          let languages = data["language"].map(l => l["name"]).join(", ");
          if(data.gender === null || parseInt(data.gender[0]) == 0){
            gender = "All Gender";
          } else if (parseInt(data.gender[0]) == 1){
            gender = "Male";
          } else {
            gender = "Female";
          }
          audienceName.innerHTML = "Audience: <b>" + data.name + "</b>";
          audienceLocation.innerHTML = "Location: <b>" + locations + "</b>";
          audienceExcludeLocation.innerHTML = "Excluded Location: <b>" + exclude_locations + "</b>";
          audienceAge.innerHTML = "Age: <b>" + data.min_age + " - " + data.max_age + "</b>";
          audienceGender.innerHTML = "Gender: <b>" + gender + "</b>";
          audiencedetailedTargeting.innerHTML = "Detailed Targeting: <b>" + detailedTargetings + "</b>";
          audienceexcludedDetailedTargeting.innerHTML = "Excluded Targeting: <b>" + excludedTargetings + "</b>";
          audienceLanguage.innerHTML = "Languages: <b>" + languages + "</b>";
          if (data["location_type"]) {
            let location_type;
            switch(data["location_type"]) {
              case "recent_or_home":
                location_type = "People living in or recently in this location";
                break;
              case "home":
                location_type = "People living in this location";
                break;
              case "recent":
                location_type = "People recently in this location";
                break;
              case "travel_in":
                location_type = "People travelling in this location";
                break;
              default:
                location_type = "";
            }
            audienceLocationType.innerHTML = "Location Type: <b>" + location_type + "</b>";
          }
          const trigger = new CustomEvent("refresh-upreviewpage");
          window.dispatchEvent(trigger);
        }

      })
    } else{
        audienceName.innerHTML = "Audience: <b>" + "" + "</b>";
        audienceLocation.innerHTML = "Location: <b>" + "" + "</b>";
        audienceExcludeLocation.innerHTML = "Excluded Location: <b>" + "" + "</b>";
        audienceAge.innerHTML = "Age: <b>" + "" + "</b>";
        audienceGender.innerHTML = "Gender: <b>" + "" + "</b>";
        audiencedetailedTargeting.innerHTML = "Detailed Targeting: <b>" + "" + "</b>";
        audienceexcludedDetailedTargeting.innerHTML = "Excluded Targeting: <b>" + "" + "</b>";
        audienceLanguage.innerHTML = "Languages: <b>" + "" + "</b>";
        audienceLocationType.innerHTML = "Location Type: <b>" + "" + "</b>";
    }
    
  }

  showNativeAudienceData(e){
    let gender; 
    let nativeaudienceName = document.getElementById("save-native-audience-name")
    let nativeaudienceLocation = document.getElementById("save-native-audience-location")
    let nativeaudienceLocationType = document.getElementById("save-native-audience-location-type")
    let nativeaudienceAge = document.getElementById("save-native-audience-age")
    let nativeaudienceGender = document.getElementById("save-native-audience-gender")
    let nativeaudiencedetailedTargeting = document.getElementById("save-native-audience-detailed-targeting")
    let select_tag = document.getElementById("native_saved_audiences")
    if (select_tag.value !== ""){
      Rails.ajax({
        url: '/facebook/get_native_saved_audience/',
        type: 'POST',
        data: "id=" + e.target.value,
        success: function(data) {
          let name = data.name;
          let targeting = data.targeting;
          let ageMax = targeting.age_max;
          let ageMin = targeting.age_min;
          let genders = targeting.genders;
          let flexible_spec = targeting.flexible_spec;
          let geoLocations = targeting.geo_locations;
          let locationType = geoLocations.location_types;
  
          let locations;
          for (let key in geoLocations) {
              if (Array.isArray(geoLocations[key]) && geoLocations[key].length > 0) {
                  locations = geoLocations[key];
                  break;
              }
          }
  
          let detailedTargetings = "";
          if (flexible_spec && flexible_spec.length > 0) {
            flexible_spec.forEach(spec => {
              if (spec["interests"] && spec["interests"].length > 0) {
                detailedTargetings += spec["interests"].map(interest => interest["name"]).join(", ");
                detailedTargetings += " AND ";
              }
            });
            detailedTargetings = detailedTargetings.slice(0, -5); // Remove the last " AND "
          } else if (data["interests"] && data["interests"].length > 0) {
            detailedTargetings = data["interests"].map(interest => interest["name"]).join(", ");
          }
          if(genders === undefined || genders === null || parseInt(genders[0]) == 0){
            gender = "All Gender";
          } else if (parseInt(genders[0]) == 1){
            gender = "Male";
          } else {
            gender = "Female";
          }
          nativeaudienceName.innerHTML = "Audience: <b>" + name + "</b>";
          nativeaudienceLocation.innerHTML = "Location: <b>" + locations + "</b>";
          nativeaudienceAge.innerHTML = "Age: <b>" + ageMin + " - " + ageMax + "</b>";
          nativeaudienceGender.innerHTML = "Gender: <b>" + gender + "</b>";
          nativeaudiencedetailedTargeting.innerHTML = "Detailed Targeting: <b>" + detailedTargetings + "</b>";
          if (locationType && locationType.length > 0) {
            let location_type = "";
            locationType.forEach(type => {
                switch(type) {
                    case "recent_or_home":
                        location_type += "People living in or recently in this location, ";
                        break;
                    case "home":
                        location_type += "People living in this location, ";
                        break;
                    case "recent":
                        location_type += "People recently in this location, ";
                        break;
                    case "travel_in":
                        location_type += "People travelling in this location, ";
                        break;
                    default:
                        location_type += "";
                }
            });
            location_type = location_type.slice(0, -2);
            nativeaudienceLocationType.innerHTML = "Location Type: <b>" + location_type + "</b>";
          }
  
          // Custom event we will use as our trigger || in fb form adset trigger this.
          const trigger = new CustomEvent("refresh-upreviewpage");
          window.dispatchEvent(trigger);
        }
  
      })
    }else{
      nativeaudienceName.innerHTML = "Audience: <b>" + "" + "</b>";
      nativeaudienceLocation.innerHTML = "Location: <b>" + "" + "</b>";
      nativeaudienceAge.innerHTML = "Age: <b>" + ""+ "</b>";
      nativeaudienceGender.innerHTML = "Gender: <b>" + "" + "</b>";
      nativeaudiencedetailedTargeting.innerHTML = "Detailed Targeting: <b>" + "" + "</b>";
      nativeaudienceLocationType.innerHTML = "Location Type: <b>" + "" + "</b>";
    }
  }  
}