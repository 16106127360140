import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to clients");
  }

  update_active_client(e){
    Rails.ajax({
      url: '/update_active_client',
      type: 'PATCH',
      data: "client_id=" + e.target.value,
      success: function(data) {
        if (data && data.path) {
          // redirect to /clients for required set business to client
          Turbo.visit(data.path)
        } else if (e.target.classList.toString().includes("root-client")) {
          Turbo.visit("/")
        } else {
          Turbo.visit("/clients/" + e.target.value)
        }
      }
    });
  }

  update_active_organization(e){
    Rails.ajax({
      url: '/update_active_organization',
      type: 'PATCH',
      data: "organization_id=" + e.target.value,
      success: function(data) {
        Turbo.visit("/clients/")
      }
    });
  }

  // Currently 2 separate methods to get gAds and fb accounts. Difference is mainly the modal ID and the endpoint of the AJAX.
  get_ad_accounts(e){
    let clientSpinner = document.getElementById("client-spinner");
    clientSpinner.classList.remove("hidden");
    Rails.ajax({
      url: '/clients/' + e.currentTarget.dataset.client + '/get_all_ad_accounts',
      type: 'GET',
      data: "client_id=" + e.currentTarget.dataset.client,
      success: function(data) {
        clientSpinner.classList.add("hidden");
        // Find modal based on clicked button's data-modal attribute
        let modal = document.getElementById("fb-ad-account-modal");
        let modalContent = document.getElementById("modal-content");
        // Unhide the modal
        modal.classList.remove("hidden");
        // empty modal content every refresh
        modalContent.innerHTML = "";
        data.forEach(ad_account => {
          let label = document.createElement("label");
          label.classList.add("my-1", "ml-3", "text-monochrome-grey-blue-dark-1");
          label.htmlFor = "ad_accounts";
          let input = document.createElement("input");
          input.type = "checkbox";
          input.name = "ad_accounts[]";
          input.id = "ad_accounts_";
          input.value = ad_account["fb_ad_account_id"];
          input.classList.add("my-2");
          let span = document.createElement("span");
          span.classList.add("p3", "ml-3");
          span.innerHTML = ad_account["name"] + " (" + ad_account["fb_ad_account_id"].slice(4) + ")"
          label.appendChild(input);
          label.appendChild(span);
          modalContent.appendChild(label);
        });
      }
    });
  }

  get_g_ad_accounts(e){
    let clientSpinner = document.getElementById("client-spinner");
    clientSpinner.classList.remove("hidden");
    Rails.ajax({
      url: '/clients/' + e.currentTarget.dataset.client + '/get_all_g_ad_accounts',
      type: 'GET',
      data: "client_id=" + e.currentTarget.dataset.client,
      success: function(data) {
        clientSpinner.classList.add("hidden");
        // Find modal based on clicked button's data-modal attribute
        let modal = document.getElementById("g-ad-account-modal");
        let modalContent = document.getElementById("g-ads-modal-content");
        // Unhide the modal
        modal.classList.remove("hidden");
        // empty modal content every refresh
        modalContent.innerHTML = "";
        data.forEach(ad_account => {
          let label = document.createElement("label");
          label.classList.add("my-1", "ml-3", "text-monochrome-grey-blue-dark-1");
          label.htmlFor = "ad_accounts";
          let input = document.createElement("input");
          input.type = "checkbox";
          input.name = "ad_accounts[]";
          input.id = "ad_accounts_";
          input.value = ad_account["google_ad_account_id"];
          input.classList.add("my-2");
          let span = document.createElement("span");
          span.classList.add("p3", "ml-3");
          span.innerHTML = ad_account["name"] + " (" + ad_account["google_ad_account_id"] + ")"
          label.appendChild(input);
          label.appendChild(span);
          modalContent.appendChild(label);
        });
      }
    });
  }

  selected_google_objective() {
    let formEl = document.forms.new_campaign.elements;
    let campaign_type_popup = document.getElementById("campaign-type-popup");
    // Iterate over the form controls
    // old flow with choosing objective
    // for (var i = 0; i < formEl.length; i++) {
    //   if (formEl[i].nodeName === "INPUT" && formEl[i].type === "radio" && formEl[i].checked === true) {
    //     // Update campaign type select option
    //     Rails.ajax({
    //       url: '/google/get_campaign_type_option',
    //       type: 'GET',
    //       data: "objective=" + formEl[i].value,
    //       success: function(data) {
    //         // reset appendchild from div
    //         campaign_type_popup.innerHTML = '';

    //         var div_e_count = 0
    //         let div_first = document.createElement("div");
    //         let div_second = document.createElement("div");
    //         for(let d in data) {
    //           let label = document.createElement("label");
    //           label.className += "text-monochrome-grey-blue-dark-1 font-normal capitalize pb-3";
    //           label.htmlFor = data[d];

    //           let input = document.createElement("INPUT");
    //           input.id = data[d];
    //           input.setAttribute("type", "radio");
    //           input.value = data[d];
    //           input.classList.add("mr-2");
    //           input.name = "campaign[g_campaign_type]";
    //           label.appendChild(input);

    //           let span = document.createElement("span");
    //           span.textContent = data[d].replace("_", " ");
    //           label.appendChild(span);

    //           if(div_e_count > 6) {
    //             campaign_type_popup.appendChild(div_first);
    //             div_first.appendChild(label);
    //           } else {
    //             campaign_type_popup.appendChild(div_second);
    //             div_second.appendChild(label);
    //           }
    //           div_e_count++
    //         }
    //       }
    //     });
    //     break;
    //   }
    // }

    // new flow auto select objective "without_goal_guidance"
    Rails.ajax({
      url: '/google/get_campaign_type_option',
      type: 'GET',
      data: "objective=without_goal_guidance",
      success: function(data) {
        // reset appendchild from div
        campaign_type_popup.innerHTML = '';

        var div_e_count = 0
        let div_first = document.createElement("div");
        let div_second = document.createElement("div");
        for(let d in data) {
          let label = document.createElement("label");
          label.className += "text-monochrome-grey-blue-dark-1 font-normal capitalize pb-3";
          label.htmlFor = data[d];

          let input = document.createElement("INPUT");
          input.id = data[d];
          input.setAttribute("type", "radio");
          input.value = data[d];
          input.classList.add("mr-2");
          input.name = "campaign[g_campaign_type]";
          label.appendChild(input);

          let span = document.createElement("span");
          span.textContent = data[d].replace("_", " ");
          label.appendChild(span);

          if(div_e_count > 6) {
            campaign_type_popup.appendChild(div_first);
            div_first.appendChild(label);
          } else {
            campaign_type_popup.appendChild(div_second);
            div_second.appendChild(label);
          }
          div_e_count++
        }
      }
    });
  }

  openModalCampaignType(e) {
    document.getElementById("campaign-type").click();
  }

  update_ad_account_facebook_page(e) {
    console.log("update_ad_account_facebook_page ")
    const ad_account = e.currentTarget.getAttribute('data-ad-account');
    const client = e.currentTarget.getAttribute('data-client-id');
    const selectElement = e.target;
    const selectedValue = selectElement.value;
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    Rails.ajax({
      url: '/update_ad_account',
      type: 'PATCH',
      data: `id=${ad_account}&fb_page_id=${selectedValue}&fb_page_name=${selectedOption.textContent}`,
      success: function(data) {
        Turbo.visit("/clients/" + client)
      }
    });
  }

  update_ad_account_ig_account(e) {
    const ad_account = e.currentTarget.getAttribute('data-ad-account');
    const client = e.currentTarget.getAttribute('data-client-id');
    const selectElement = e.target;
    const selectedValue = selectElement.value;
    const selectedOption = selectElement.options[selectElement.selectedIndex];    
    Rails.ajax({
      url: '/update_ad_account',
      type: 'PATCH',
      data: `id=${ad_account}&ig_id=${selectedValue}&ig_username=${selectedOption.textContent}`,
      success: function(data) {
        Turbo.visit("/clients/" + client)
      }
    });
  }
}