// The BulkController can be used for adding bulk operations to your index views.
// You can add a Select All checkbox and checkboxes for each record and easily grab
// the selected records.
//
// Usage:
//
//   import BulkController from "controllers/bulk_controller"
//
//   export default class extends BulkController {
//   }

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = [ "checkbox", "selectAll" ]

  connect(){
    console.log("Connected to bulk controller!")
    this.updateSelectedTabHeader();
  }

  updateSelectedTabHeader(e) {
    let params_obj = {};
    let params = document.URL.split("?")[1];
    if (params) {
      let param_arr = params.split("&");
      // clear empty array
      param_arr = param_arr.filter(n => n)
      for (let p in param_arr) {
        // add value to object => "campaigns[]" = [181,182,183]
        params_obj[`${param_arr[p].split("=")[0]}[]`] = param_arr[p].split("=")[1].split(",");
      }
      for (let a in this.checkboxTargets) {
        if (params_obj[this.checkboxTargets[a].name] && params_obj[this.checkboxTargets[a].name].includes(this.checkboxTargets[a].defaultValue)) {
          this.checkboxTargets[a].checked = true;
          this.updateTabHeader(params_obj[this.checkboxTargets[a].name], this.checkboxTargets[a].name.replace("[]",""));
        }
      }
    }
  }

  // Toggles all checkboxes based upon what is currently checked
  toggleSelectAll(event) {
    (this.noCheckboxesSelected) ? this.selectAll() : this.unselectAll()
    // update tab header count selected
    this.updateTabHeader(this.selected.map(x => x.value), event.target.name.replace("select_all_",""));
    // selected will change URL for filtering
    this.selectedFilter(this.selected.map(x => x.value), event.target.name.replace("select_all_",""));
  }

  // Selects all checkboxes
  selectAll() {
    this.selectAllTarget.checked = true
    this.selectAllTarget.indeterminate = false
    this.unselected.forEach(target => target.checked = true)
  }

  // Unselects all checkboxes
  unselectAll() {
    this.selectAllTarget.checked = false
    this.selectAllTarget.indeterminate = false
    this.selected.forEach(target => target.checked = false)
  }

  // Keep track of SelectAll state based upon how many checkboxes are selected
  change(event) {
    if (this.noCheckboxesSelected) {
      this.selectAllTarget.checked = false
      this.selectAllTarget.indeterminate = false

    } else if (this.allCheckboxesSelected) {
      this.selectAllTarget.checked = true
      this.selectAllTarget.indeterminate = false

    } else {
      this.selectAllTarget.indeterminate = true
    }

    // update tab header count selected
    this.updateTabHeader(this.selected.map(x => x.value), event.target.name.replace("[]",""));
    // selected will change URL for filtering
    this.selectedFilter(this.selected.map(x => x.value), event.target.name.replace("[]",""));
  }

  updateTabHeader(ids, from_tabs) {
    let tab_header = document.getElementById(`tab-header-${from_tabs}`);
    let tab_header_text = document.getElementById(`tab-header-${from_tabs}-text`);
    let ids_arr = ids.filter(n => n);
    if (ids_arr.length > 0) {
      tab_header.classList.remove("hidden");
      tab_header_text.textContent = `${ids_arr.length} Selected`;
    } else {
      tab_header.classList.add("hidden");
      tab_header_text.textContent = "";
    }
  }

  selectedFilter(ids, from_tabs) {
    let new_url = document.URL;
    let param_path = document.URL.split("?")[1];
    if (param_path) {
      for (let p in param_path.split("&")) {
        // for remove url param path duplicate
        let param = param_path.split("&")[p];
        // prevent wrong filter when re-choosing campaign or adset id
        if (from_tabs == "campaigns") {
          if (param.includes("adset_page=") || param.includes("ad_page=")) {
            new_url = new_url.replace(`&${param}`,"").replace(param,"");
          }
          if (param.includes("adsets=") || param.includes("ads=")) {
            new_url = new_url.replace(`&${param}`,"").replace(param,"");
          }
        } else if (from_tabs == "adsets") {
          if (param.includes("ad_page=")) {
            new_url = new_url.replace(`&${param}`,"").replace(param,"");
          }
          if (param.includes("ads=")) {
            new_url = new_url.replace(`&${param}`,"").replace(param,"");
          }
        }

        if (param.includes(`${from_tabs}=`)) {
          new_url = new_url.replace(`&${param}`,"").replace(param,"");
        }
        if (param.includes("tabs=")) {
          new_url = new_url.replace(`&${param}`,"").replace(param,"");
        }
      }
    }
    if (!document.URL.includes("?")) {
      new_url = `${new_url}?${from_tabs}=${ids}&tabs=${from_tabs}`;
      window.location.href = new_url.replace("?&","?");
    } else {
      new_url = `${new_url}&${from_tabs}=${ids}&tabs=${from_tabs}`;
      window.location.href = new_url.replace("?&","?");
    }
  }

  applyRulesByBulk(e){
    e.preventDefault();
    let selectedValues = this.selected.map(target => target.value)
    let checkedRuleTemplate = document.querySelector("input[name=rule_template]:checked");
    let appliedRuleType = e.target.dataset.appliedRuleType;
    let clientId = e.target.dataset.clientId;

    Rails.ajax({
      url: '/applied_rules',
      type: 'POST',
      data: "applied_id=" + selectedValues + "&rule_template_id=" + checkedRuleTemplate.value + "&applied_rule_type=" + appliedRuleType + "&client_id=" + clientId,
      success: function(data) {
        window.location.reload();
      }
    });
  }

  duplicateAll(e) {
    this.showPageLoader();
    let ids = this.selected.map(x => x.value);
    let object = e.target.dataset.object;
    Rails.ajax({
      url: '/facebook/duplicate_fb_' + object + 's',
      type: 'PATCH',
      data: object + "_ids=" + ids,
      success: function(data) {
        window.location.reload();
      },
      complete: (response) => {
        this.hiddenPageLoader();
      }
    });
  }

  selectedTabItem(e) {
    // get current path
    let current_url = document.URL;
    // remove url from tabs
    let new_url = current_url.replace("tabs=adsets","").replace("tabs=campaigns","").replace("tabs=ads").replace("?&","?").replace("&&","&");
    
    // set params from url
    let params = new_url.split("?")[1];
    // campaigns (tabs) || adsets (tabs) || ads (tabs)
    let to_tabs = e.target.id.split("-")[2];
    // campaigns || adsets || ads
    let selected_type = e.target.id.split("-")[0];
    // filter with id 
    let selected_id = e.target.id.split("-")[1];

    // set tabs into url
    if (params) {
      new_url = `${new_url}&tabs=${to_tabs}`;
    } else {
      new_url = `${new_url}?tabs=${to_tabs}`;
    }

    // set filtering type and id into url
    if (params) {
      for (let p in params.split("&")) {
        // for remove url param path duplicate
        let param = params.split("&")[p];
        if (param.includes(`${selected_type}=`)) {
          new_url = new_url.replace(`&${param}`,"").replace(param,"");
        }
        // remove filtering adset when choose campaign selection
        if (selected_type == "campaigns" && param.includes(`adsets=`)) {
          new_url = new_url.replace(`&${param}`,"").replace(param,"");
        }
      }
    }
    // prevent undefined inside url
    new_url = new_url.replace("&undefined","")
    new_url = new_url.replace("undefined","")
    window.location.href = `${new_url}&${selected_type}=${selected_id}`;
  }

  // Returns true if Select All checkbox is checked
  get selectedAll() {
    return this.selectAllTarget.checked
  }

  // Returns all selected checkboxes
  get selected() {
    return this.checkboxTargets.filter(target => target.checked)
  }

  // Returns all unselected checkboxes
  get unselected() {
    return this.checkboxTargets.filter(target => !target.checked)
  }

  // Returns data-id attributes for all selected checkboxes
  get selectedIds() {
    return this.selected.map(target => target.dataset.id)
  }

  // Returns true if all checkboxes are checked
  get allCheckboxesSelected() {
    return this.checkboxTargets.every(target => target.checked)
  }

  // Returns true if no checkboxes are checked
  get noCheckboxesSelected() {
    return this.checkboxTargets.every(target => !target.checked)
  }

  mass_edit(e) {
    let ids = this.selected.map(x => x.value);
    let object = e.target.dataset.object;
    let platform = e.target.dataset.platform;
    window.location.href = `/${platform}/mass_edit_${object}?id=${ids}`
  }

  showPageLoader(e) {
    let page_loader = document.getElementById("page-loader");
    page_loader.classList.remove("opacity-0");
    page_loader.classList.add("absolute");
    page_loader.classList.add("opacity-30");
  }

  hiddenPageLoader(e) {
    let page_loader = document.getElementById("page-loader");
    page_loader.classList.remove("opacity-30");
    page_loader.classList.remove("absolute");
    page_loader.classList.add("opacity-0");
  }
}
