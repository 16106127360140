import { Controller } from "@hotwired/stimulus"

let src, src1, src2, src3, src4, preview_src;

export default class extends Controller {
  static targets = ["field"]
  connect() {
    if (this.fieldTarget.getAttribute("data-name") == "image") {
      // This section is used in _image_preview_modal.html.slim to preview the 3 uploaded images for different placements
      if (this.fieldTarget.getAttribute("data-size") == "1200x628") {
        this.fieldTarget.src = src1;
      } else if (this.fieldTarget.getAttribute("data-size") == "1080x1080") {
        this.fieldTarget.src = src2;
      } else if (this.fieldTarget.getAttribute("data-size") == "1080x1920") {
        this.fieldTarget.src = src3;
      } else if (this.fieldTarget.getAttribute("data-size") == "1280x720") {
        this.fieldTarget.src = src1;
      } else if (this.fieldTarget.getAttribute("data-size") == "1200x720") {
        this.fieldTarget.src = src4;
      }
    }
  }

  // Display values in the preview card
  toggle() {
    if (this.fieldTarget.type == "select-one") {
      document.getElementById(this.fieldTarget.getAttribute("data-name")).innerHTML = this.fieldTarget.selectedOptions[0].text;
    } else {
      document.getElementById(this.fieldTarget.getAttribute("data-name")).innerHTML = this.fieldTarget.value;
    }
  }

  // Saves the images src and pass to _image_preview_modal.html.slim
  // Also validates the uploaded image sizes
  save_image() {
    let _URL = window.URL || window.webkitURL;

    let img, files;
    let dimensions = { "628": 1200, "1080": 1080, "1920": 1080 }
    let count = 0;
    let showAlert = true;
    files = this.fieldTarget.files
    let el = this.fieldTarget.closest(".carousel-card");
    let uploadedImages = document.getElementById("uploaded-images");

    // Set state back to default when user re-uploads an image
    // Remove files, remove checkbox styling, remove background styling
    let resetImageState = ()  => this.removeAttachedImages();
    resetImageState();

    // Set image to image_modal on frontend and validates image
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      img = new Image();
      img.onload = function() {
        src = URL.createObjectURL(file);
        if (files.length == 3) {
          if (this.width == dimensions[(this.height).toString()]) {
            count += 1;
            if (this.height == 628) {
              src1 = src;
              // Displays the uploaded images in the ads form, likewise for the similar code for the other placements
              uploadedImages.innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + src + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + this.width + ' x '  + this.height + ' (' + file.name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Facebook Feed (Desktop and Mobile)</div><div class="p4 text-monochrome-grey-blue-dark-2">Facebook Marketplace Ads</div><div class="p4 text-monochrome-grey-blue-dark-2">Facebook Article Ads</div></div></div></div>';
            } else if (this.height == 1080) {
              src2 = preview_src = src;
              uploadedImages.innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + src + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + this.width + ' x '  + this.height + ' (' + file.name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Instagram Feed</div><div class="p4 text-monochrome-grey-blue-dark-2">Instagram Lead Ads</div></div></div></div>';
            } else if (this.height == 1920) {
              src3 = src;
              uploadedImages.innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + src + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + this.width + ' x '  + this.height + ' (' + file.name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Facebook Story</div><div class="p4 text-monochrome-grey-blue-dark-2">Instagram Story</div></div></div></div>';
            }
            // Change the checked icon's colour to blue
            document.getElementById(this.width + "x" + this.height).classList.remove("text-monochrome-grey-blue-medium-2");
            document.getElementById(this.width + "x" + this.height).classList.add("text-brand-blue-medium");
            el.querySelector("#images-single").innerHTML += '<div class="div"><img class="w-full rounded aspect-square object-cover" src=' + src + '><div class="p4 text-monochrome-grey-medium-1 mt-2">' + file.name + '<br>(' + this.width + ' x '  + this.height + ')</div></div>';
            if (count == 3) {
              el.querySelector("#conditions").classList.remove("bg-brand-gray");
              el.querySelector("#conditions").classList.add("bg-signal-green-light");
              el.querySelector("#next-preview-btn").classList.remove("pointer-events-none");
              el.querySelector("#next-preview-btn").classList.remove("hidden");
              el.querySelector("#next-preview-btn").firstChild.classList.remove("btn-primary-disabled");
              el.querySelector("#next-preview-btn").firstChild.classList.add("btn-primary-signal-green");
              el.querySelector("#image_model_done_btn").classList.add("hidden");
            }
          } else {
            if (showAlert == true) {
              alert('Please upload the right dimension for the Ad: 1200 x 628, 1080 x 1080, or 1080 x 1920!');
              showAlert = false;
            }
          }
        } else if (files.length < 3){
          uploadedImages.innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + src + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + this.width + ' x '  + this.height + ' (' + file.name + ')' + '</h6></div></div>';
          el.querySelector("#images-single").innerHTML += '<div class="div"><img class="w-full rounded aspect-square object-cover" src=' + src + '><div class="p4 text-monochrome-grey-medium-1 mt-2">' + file.name + '<br>(' + this.width + ' x '  + this.height + ')</div></div>';

          if (!el.querySelector("#next-preview-btn").classList.contains("hidden")) el.querySelector("#next-preview-btn").classList.add("hidden");
          if (el.querySelector("#next-preview-btn").classList.contains("hidden")) el.querySelector("#image_model_done_btn").classList.remove("hidden");
        } else {
          alert('Only can upload Max 3 Images');
        }
      };
      img.src = _URL.createObjectURL(file);
    }
  }

  // Sets the src in the preview card using the square image
  preview_image() {
    document.getElementById("preview-image").src = preview_src;
  }

  toggle_preview(e){
    let previewAd = document.getElementById("preview-page");
    if(e.target.checked){
      previewAd.classList.remove("hidden");
    } else {
      previewAd.classList.add("hidden");
    }
  }

  save_carousel_image() {
    // Validates the uploaded image sizes
    let _URL = window.URL || window.webkitURL;

    let img;
    let file = this.fieldTarget.files.item(0);
    let el = this.fieldTarget.closest(".carousel-card");
    img = new Image();
    img.onload = function() {
      if (this.width == 1080 && this.height == 1080) {
        src = URL.createObjectURL(file);
        preview_src = src;
        el.querySelector("#carousel-dimension").classList.remove("text-monochrome-grey-blue-medium-2");
        el.querySelector("#carousel-dimension").classList.add("text-brand-blue-medium");
        el.querySelector("#images-carousel").innerHTML += '<div class="div"><img class="w-full rounded aspect-square object-cover" src=' + src + '><div class="p4 text-monochrome-grey-medium-1 mt-2">' + file.name + '<br>(' + this.width + ' x '  + this.height + ')</div></div>';
        el.querySelector("#conditions-carousel").classList.remove("bg-brand-gray");
        el.querySelector("#conditions-carousel").classList.add("bg-signal-green-light");
        el.querySelector(".carousel-image").src = src;
      }
      else {
        alert('Please upload the right dimension for the Ad: 1080 x 1080!');
      }
    };
    img.src = _URL.createObjectURL(file);    
  }

  // Validate and display the thumbnails of the video ads in video_modal and ads form
  save_video() {
    let video, files, canvas, context, dataURI, height, width, file, target, duration;
    let count = 0;
    let showAlert = true;
    let el = this.fieldTarget.closest(".carousel-card");
    files = this.fieldTarget.files;

     // Validate file names
    const validFileNamePattern = /^[a-zA-Z0-9._-]+$/;
    for (let i = 0; i < files.length; i++) {
      file = files.item(i);
      if (!validFileNamePattern.test(file.name)) {
        alert(`The file "${file.name}" contains special characters and is not allowed.`);
        this.fieldTarget.value = "";
        return;
      }
    }

    // Set state back to default when user re-uploads an image
    // Remove files, remove checkbox styling, remove background styling
    let resetImageState = ()  => this.removeAttachedImages();
    resetImageState();


    for (let i = 0; i < files.length; i++) {
      file = files.item(i);
      src = URL.createObjectURL(file);
      video = document.createElement('video');
      video.src = src;
      video.name = file.name
      canvas = document.createElement('canvas');
      context = canvas.getContext('2d');

      video.addEventListener('loadeddata', event => {
        target = event.target;
        name = event.target.name;
        src = event.target.src
        width = target.videoWidth;
        height = target.videoHeight;
        duration = target.duration;
        if ((width == 1280 && height == 720 && duration <= 900) || (width == 1080 && height == 1080 && duration <= 120) || (width == 1080 && height == 1920 && duration <= 15)) {
          showAlert = false;
          count += 1;
          target.pause();
          canvas.width = width;
          canvas.height = height;
          context.drawImage(target, 0, 0, width, height);
          dataURI = canvas.toDataURL('image/png');
          // Display thumbnail in ads form
          if (width == 1280 && height == 720) {
            src1 = src;
            document.getElementById("uploaded-images").innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + dataURI + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + width + ' x '  + height + ' (' + name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Facebook Shared Video</div></div></div></div>';
          } else if (width == 1080 && height == 1080) {
            src2 = src;
            preview_src = dataURI;
            document.getElementById("uploaded-images").innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + dataURI + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + width + ' x '  + height + ' (' + name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Facebook In-feed Video Ads</div><div class="p4 text-monochrome-grey-blue-dark-2">Instagram In-feed Video Ads</div></div></div></div>';
          } else if (width == 1080 && height == 1920) {
            src3 = src;
            document.getElementById("uploaded-images").innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + dataURI + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + width + ' x '  + height + ' (' + name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Facebook Story</div><div class="p4 text-monochrome-grey-blue-dark-2">Instagram Story</div></div></div></div>';
          }
          // Display thumbnail in video_modal and updates checkboxes colour
          document.getElementById(width + "x" + height + "v").classList.remove("text-monochrome-grey-blue-medium-2");
          document.getElementById(width + "x" + height + "v").classList.add("text-brand-blue-medium");
          el.querySelector("#videos-single").innerHTML += '<div class="div"><img class="w-full rounded aspect-square object-cover" src=' + dataURI + '><div class="p4 text-monochrome-grey-medium-1 mt-2">' + name + '<br>(' + width + ' x '  + height + ')</div></div>';
          // Change background-colour to light green if 3 correct videos are uploaded
          if (count == 3) {
            el.querySelector("#conditions-video").classList.remove("bg-brand-gray");
            el.querySelector("#conditions-video").classList.add("bg-signal-green-light");
            el.querySelector("#next-preview-btn-video").classList.remove("pointer-events-none");
            el.querySelector("#next-preview-btn-video").firstChild.classList.remove("btn-primary-disabled");
            el.querySelector("#next-preview-btn-video").firstChild.classList.add("btn-primary-signal-green");
          }
        }
        if (showAlert == true) {
          alert('Please upload the right dimension and duration!');
          showAlert = false;
        }
      });
      // Following code for creating thumbnail
      video.preload = 'metadata';
      video.muted = true;
      video.playsInline = true;
      video.setAttribute('crossOrigin', 'anonymous');
      video.play();
    }
  }
  // When uploading image in the modal, images were already attached to the frontend of the form. When user closes the modal, it should remove it on the frontend
  removeAttachedImages(e){
    // prevent default if this method comes from the button "Reset"
    if(typeof(e) != "undefined"){
      e.preventDefault();
      // Clear files within the file field
      let fileField = document.getElementById("file-input-modal");
      fileField.value = '';
      let fileFieldVideo = document.getElementById("file-input-modal-video");
      fileFieldVideo.value = '';
    }
    let attachedImages = document.getElementById("uploaded-images");
    let modalImages = document.getElementById("images-single");
    let modalVideos = document.getElementById("videos-single");
    // Remove images attached to frontend form
    attachedImages.innerHTML = ""
    // Remove media attached to modal
    modalImages.innerHTML = ""
    modalVideos.innerHTML = ""
    // Remove checkbox icon styling! Need to think how to refactor
    let firstImageDimension = document.getElementById("1280x720v");
    let secondImageDimension = document.getElementById("1080x1080v");
    let thirdImageDimension = document.getElementById("1080x1920v");
    firstImageDimension.classList.remove("text-brand-blue-medium");
    firstImageDimension.classList.add("text-monochrome-grey-blue-medium-2");
    secondImageDimension.classList.remove("text-brand-blue-medium");
    secondImageDimension.classList.add("text-monochrome-grey-blue-medium-2");
    thirdImageDimension.classList.remove("text-brand-blue-medium");
    thirdImageDimension.classList.add("text-monochrome-grey-blue-medium-2");
    let firstVideoDimension = document.getElementById("1200x628");
    let secondVideoDimension = document.getElementById("1080x1080");
    let thirdVideoDimension = document.getElementById("1080x1920");
    firstVideoDimension.classList.remove("text-brand-blue-medium");
    firstVideoDimension.classList.add("text-monochrome-grey-blue-medium-2");
    secondVideoDimension.classList.remove("text-brand-blue-medium");
    secondVideoDimension.classList.add("text-monochrome-grey-blue-medium-2");
    thirdVideoDimension.classList.remove("text-brand-blue-medium");
    thirdVideoDimension.classList.add("text-monochrome-grey-blue-medium-2");
    // Remove styling from checkbox background in image modal
    let conditions = document.getElementById("conditions");
    conditions.classList.remove("bg-signal-green-light");
    conditions.classList.add("bg-brand-gray");
    let conditionsVideo = document.getElementById("conditions-video");
    conditionsVideo.classList.remove("bg-signal-green-light");
    conditionsVideo.classList.add("bg-brand-gray");
    // Disabled button
    let nextPreviewButton = document.getElementById("next-preview-btn");
    nextPreviewButton.firstChild.classList.remove("btn-primary-signal-green");
    nextPreviewButton.classList.add("pointer-events-none");
    nextPreviewButton.firstChild.classList.add("btn-primary-disabled");
    let nextPreviewButtonVideo = document.getElementById("next-preview-btn-video");
    nextPreviewButtonVideo.firstChild.classList.remove("btn-primary-signal-green");
    nextPreviewButtonVideo.classList.add("pointer-events-none");
    nextPreviewButtonVideo.firstChild.classList.add("btn-primary-disabled");
  }

  // Called on website URL changes
  utm_link(e){
    // Check if name is blank
    let adName = (document.getElementById("ad-name").value == "" ? "add_an_ad_name" : document.getElementById("ad-name").value.trim().toLowerCase().replace(/ /g,"_"));
    let utmPreview = document.getElementById("utm-preview");
    // Underscore the campaign and adset name
    let campaignName = e.target.dataset.campaignName.trim().toLowerCase().replace(/ /g,"_");
    let adsetName = e.target.dataset.adsetName.trim().toLowerCase().replace(/ /g,"_");
    // If got query params, append the data in UTM links, else add a query param (?)
    if(e.target.dataset.utmSource == undefined){
      utmPreview.innerHTML = "Please add UTM template!"
    } else {
      utmPreview.innerHTML = (e.target.value.indexOf('?') != -1 ? ("https://" + e.target.value.replace("https://","").replace("http://","") + "&utm_source=" + e.target.dataset.utmSource + "&utm_medium=" + e.target.dataset.utmMedium + "&utm_campaign=" + e.target.dataset.campaignId + "_" + campaignName + "&utm_term=" + e.target.dataset.adsetId + "_" + adsetName + "&utm_content=" + e.target.dataset.latestAdCount + "_" + adName) : ("https://" + e.target.value.replace("https://","").replace("http://","") + "?utm_source=" + e.target.dataset.utmSource + "&utm_medium=" + e.target.dataset.utmMedium + "&utm_campaign=" + e.target.dataset.campaignId + "_" + campaignName + "&utm_term=" + e.target.dataset.adsetId + "_" + adsetName + "&utm_content=" + e.target.dataset.latestAdCount + "_" + adName))
    }
  }

  // Called on ad name changes: Need to think how to refactor with the method above in the future
  update_utm_link(e){
    let websiteUrl = document.getElementById("ad_website_url");
    let utmPreview = document.getElementById("utm-preview");
    // Underscore the campaign and adset name
    let campaignName = websiteUrl.dataset.campaignName.trim().toLowerCase().replace(/ /g,"_");
    let adsetName = websiteUrl.dataset.adsetName.trim().toLowerCase().replace(/ /g,"_");
    // Check if name is blank
    let adName = e.target.value.trim().toLowerCase().replace(/ /g,"_")
    if(websiteUrl.value == ""){
      utmPreview.innerHTML = "Please type in website URL."
    } else {
      utmPreview.innerHTML = (websiteUrl.value.indexOf('?') != -1 ? ("https://" + websiteUrl + "&utm_source=" + websiteUrl.dataset.utmSource + "&utm_medium=" + websiteUrl.dataset.utmMedium + "&utm_campaign=" + websiteUrl.dataset.campaignId + "_" + campaignName + "&utm_term=" + websiteUrl.dataset.adsetId + "_" + adsetName + "&utm_content=" + websiteUrl.dataset.latestAdCount + "_" + adName) : ("https://" + websiteUrl.value + "?utm_source=" + websiteUrl.dataset.utmSource + "&utm_medium=" + websiteUrl.dataset.utmMedium + "&utm_campaign=" + websiteUrl.dataset.campaignId + "_" + campaignName + "&utm_term=" + websiteUrl.dataset.adsetId + "_" + adsetName + "&utm_content=" + websiteUrl.dataset.latestAdCount + "_" + adName))
    }
  }

  update_utm_link_objective_traffic_facebook_post(e){    
    let utmPreview = document.getElementById("utm-preview");
    let websiteUrl = document.getElementById("ad_website_url");
    let ad_name_field = document.getElementById("ad-name");
    
    let campaignName = websiteUrl.dataset.campaignName.trim().toLowerCase().replace(/ /g,"_");
    let adsetName = websiteUrl.dataset.adsetName.trim().toLowerCase().replace(/ /g,"_");
    let adName = (ad_name_field) ? ad_name_field.value.trim().toLowerCase().replace(/ /g,"_") : "";
    if (e.params.object && e.params.object.website) {
      let website = (e.params.object.website.substr(e.params.object.website.length -1) == "/") ? e.params.object.website : e.params.object.website + "/";
      website = website.includes("https") ? website : "https://" + website;
      utmPreview.innerHTML = website + "?utm_source=" + websiteUrl.dataset.utmSource + "&utm_medium=" + websiteUrl.dataset.utmMedium + "&utm_campaign=" + websiteUrl.dataset.campaignId + "_" + campaignName + "&utm_term=" + websiteUrl.dataset.adsetId + "_" + adsetName + "&utm_content=" + websiteUrl.dataset.latestAdCount + "_" + adName
    } else {
      utmPreview.innerHTML = "https:///?" + utmPreview.innerHTML.split("?")[1]
    }
  }

  // validates the uploaded image sizes Google
  google_save_image(e) {
    // send from "preview-object-param"
    let type = e.params.object.type
    let condition = e.params.object.condition
    let done_btn = e.params.object.done_btn
    let reset_btn = e.params.object.reset_btn
    let uploaded_el = e.params.object.uploaded_el
    let contents = e.params.object.contents
    let max_item = e.params.object.max_item
    let _URL = window.URL || window.webkitURL;
    let img, files;
    let dimensions = (type == "image") ? ["1200 x 628", "600 x 314", "1200 x 1200", "300 x 300"] : ["1200 x 300", "512 x 128", "1200 x 1200", "128 x 128"]
    let showAlert = true;
    let square = false;
    let landspace = false;
    
    files = this.fieldTarget.files
    let el = this.fieldTarget.closest(".carousel-card");
    let uploadedImages = document.getElementById(uploaded_el);
    
    // console.log(uploadedImages)
    let count = 1;
    // Set state back to default when user re-uploads an image
    // Remove files, remove checkbox styling, remove background styling
    let param_reset_button = JSON.parse(document.getElementById(reset_btn).getAttribute('data-preview-object-param').replace('"file-input-modal-logo",', "").replace('"file-input-modal-image",', ""))
    
    // change params standard ==> e.params.object
    let resetImageState = ()  => this.googleRemoveAttachedImages({params:{object: param_reset_button}});
    resetImageState();    
    
    // Set image to image_modal on frontend and validates image
    for (let i = 0; i < files.length; i++) {
      if (count > max_item) { alert("You have the max number of images selected"); break;}
      let file = files.item(i);
      img = new Image();
      img.onload = function() {
        let size = `${this.width} x ${this.height}`
        if (dimensions.includes(size)) {
          src = URL.createObjectURL(file);
          if (["1200 x 628", "600 x 314", "1200 x 300", "512 x 128"].includes(size)) {
            src1 = src;
            landspace = true;
            // Displays the uploaded images in the ads form, likewise for the similar code for the other placements
            uploadedImages.innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + src + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + this.width + ' x '  + this.height + ' (' + file.name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Landscape</div></div></div></div>';
          // } else if (this.height == 1200 || this.height == 300 || this.height == 128) {
          } else if (["1200 x 1200", "300 x 300", "128 x 128"].includes(size)) {
            src2 = preview_src = src;
            square = true;
            uploadedImages.innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + src + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + this.width + ' x '  + this.height + ' (' + file.name + ')' + '</h6><div class="mt-1"><div class="p4 text-monochrome-grey-blue-dark-2">Square</div></div></div></div>';
          } 
          el.querySelector(`#${contents}`).innerHTML += '<div class="div"><img class="w-full rounded aspect-square object-cover" src=' + src + '><div class="p4 text-monochrome-grey-medium-1 mt-2">' + file.name + '<br>(' + this.width + ' x '  + this.height + ')</div></div>';
          if ((type == "image" && square == true && landspace == true) || (type != "image" && (square == true || landspace == true))) {
            el.querySelector(`#${condition}`).classList.remove("bg-brand-gray");
            el.querySelector(`#${condition}`).classList.add("bg-signal-green-light");
            el.querySelector(`#${done_btn}`).classList.remove("pointer-events-none");
            el.querySelector(`#${done_btn}`).classList.remove("btn-primary-disabled");
            el.querySelector(`#${done_btn}`).classList.add("btn-primary-signal-green");
          }
        } else {
          if (showAlert == true) {
            let msg_alert = (type == "image") ? "1200 x 628, 1200 x 1200, 600 x 314, or 300 x 300" : "1200 x 1200, 1200 x 300, 512 x 128, or 128 x 128"
            alert(`Please upload the right dimension for the Ad: ${msg_alert}`);
            showAlert = false;
          }
        }
      };
      img.src = _URL.createObjectURL(file);
      count++;
    }
  }

  googleRemoveAttachedImages(e){
    let element_ids = e.params.object.reset_element
    let button_ids = e.params.object.reset_button
    let condition_ids = e.params.object.reset_modal_condition
    
    // prevent default if this method comes from the button "Reset"
    for (let element in element_ids) {
      let resetField = document.getElementById(element_ids[element]);
      if (resetField && resetField.value !== undefined) {
        resetField.value = '';
      } else if (resetField) {
        resetField.innerHTML = ""
      }      
    }
    // Disabled button
    for (let b in button_ids) {
      let doneButtonID = document.getElementById(button_ids[b]);
      doneButtonID.classList.remove("btn-primary-signal-green");
      doneButtonID.classList.add("pointer-events-none");
      doneButtonID.classList.add("btn-primary-disabled");
    }

    // left component condition inside modal
    for (let e in condition_ids) {
      let conditions = document.getElementById(condition_ids[e]);
      conditions.classList.remove("bg-signal-green-light");
      conditions.classList.add("bg-brand-gray");
    }
  }

  // validates the uploaded image sizes Google
  google_save_image_uda(e) {
    // send from "preview-object-param"
    let condition = e.params.object.condition
    let done_btn = e.params.object.done_btn
    let uploaded_el = e.params.object.uploaded_el
    let contents = e.params.object.contents
    let max_item = e.params.object.max_item
    let _URL = window.URL || window.webkitURL;
    let img, files;

    let dimensions = ["200 x 200","240 x 400","250 x 250","250 x 360","300 x 250","336 x 280","580 x 400","120 x 600","160 x 600","300 x 600","300 x 1050","468 x 60","728 x 90","930 x 180","970 x 90","970 x 250","980 x 120","300 x 50","320 x 50","320 x 100"]
    let showAlert = true;
    
    files = this.fieldTarget.files
    let el = this.fieldTarget.closest(".carousel-card");
    let uploadedImages = document.getElementById(uploaded_el);
    
    let count = 1;
    // Set state back to default when user re-uploads an image
    // Remove files, remove checkbox styling, remove background styling
    let param_reset_button = {"reset_element": ["uploaded-imagefile","imagefile-single"], "reset_button": ["imagefile-done-btn"], "reset_modal_condition": ["conditions-imagefile"]}
    
    // change params standard ==> e.params.object
    let resetImageState = ()  => this.googleRemoveAttachedImages({params:{object: param_reset_button}});
    resetImageState();    
    
    // Set image to image_modal on frontend and validates image
    for (let i = 0; i < files.length; i++) {
      if (count > max_item) { alert("You have the max number of images selected"); break;}
      let file = files.item(i);
      img = new Image();
      img.onload = function() {
        let size = `${this.width} x ${this.height}`
        if (dimensions.includes(size)) {
          src = URL.createObjectURL(file);
          src1 = src;
          // Displays the uploaded images in the ads form, likewise for the similar code for the other placements
          let set_dimention_to_controller = `<input class="hidden" type="text" name="upimage[${file.name}]" id="upimage${file.name}" value="${this.width}x${this.height}" >`
          uploadedImages.innerHTML += '<div class="flex bg-brand-blue-light-2 border rounded-lg border-monochrome-grey-blue-medium-2 px-1 py-2 mt-2"><img class="rounded aspect-square object-cover rounded ml-1" src=' + src + ' width="48" height="48"><div class="div ml-3 my-auto"><h6>' + this.width + ' x '  + this.height + ' (' + file.name + ')' + '</h6><div class="mt-1">' + set_dimention_to_controller + '</div></div></div>';
          
          el.querySelector(`#${contents}`).innerHTML += '<div class="div"><img class="w-full rounded aspect-square object-cover" src=' + src + '><div class="p4 text-monochrome-grey-medium-1 mt-2">' + file.name + '<br>(' + this.width + ' x '  + this.height + ')</div></div>';
          
          el.querySelector(`#${condition}`).classList.remove("bg-brand-gray");
          el.querySelector(`#${condition}`).classList.add("bg-signal-green-light");
          el.querySelector(`#${done_btn}`).classList.remove("pointer-events-none");
          el.querySelector(`#${done_btn}`).classList.remove("btn-primary-disabled");
          el.querySelector(`#${done_btn}`).classList.add("btn-primary-signal-green");
          
        } else {
          if (showAlert == true) {
            let msg_alert = "Can see Image Dimension on left side upload pop up form"
            alert(`Please upload the right dimension for the Ad: ${msg_alert}`);
            showAlert = false;
          }
        }
      };
      img.src = _URL.createObjectURL(file);
      img.hshsh = "test"
      count++;
    }
  }
}
