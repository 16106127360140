import ApplicationController from '../application_controller'
import { TitleBar, Button, Redirect, AppLink, NavigationMenu } from '@shopify/app-bridge/actions'

export default class extends ApplicationController {
  initialize () {
    super.initialize()
  }

  connect () {
    super.connect()
    const app = this.appBridge
  }

  disconnect () {
    super.disconnect()
  }
}