import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { get, post } from "@rails/request.js"

let count = 2;

export default class extends Controller {
  connect() {
    console.log("Im connected to Ad", this.element);
    if (document.URL.includes("google")) {
      this.onChangeDisplayAdFormat();
    }
    if (document.URL.includes("mass_edit_ads")) this.onChangeBusinessRequired();
    // run if for facebook ads form
    if (document.URL.includes("facebook")) {
      this.showCreativeToolsCatalogInfo();
      this.showCreativeOptionCatalogInfo();
      this.showAdSetup();
      // update count if carousel present on action ( EDIT )
      if (this.data && this.data.get("carousel")) count = parseInt(this.data.get("carousel"));
      this.showAdsCreative();
      this.updateFacebookPageList();
      this.HiddenURLCallToActionObjetiveTraffic();
    }
  }

  showAdsCreative(e) {
    let singles = document.getElementsByClassName("single-creative");
    let carousels = document.getElementsByClassName("carousel-creative");
    let radio_carousel = document.getElementById("ad_format_carousel");
    if ((e && e.target.value == "carousel") || (radio_carousel && radio_carousel.checked)){
      [...singles].forEach((element) => {element.classList.add("hidden")});
      [...carousels].forEach((element) => {element.classList.remove("hidden")});
    } else {
      [...singles].forEach((element) => {element.classList.remove("hidden")});
      [...carousels].forEach((element) => {element.classList.add("hidden")});
    }
  }

  // Count is the number of cards
  addNewCard(e) {
    count += 1;
    if (count <= 10) {
      // Finds the top most hidden card and unhides it
      let card = document.querySelector(".new-card.hidden");
      card.classList.remove("hidden");
      // Finds the next top most hidden card
      let next_card = document.querySelector(".new-card.hidden");
      // Place the newly unhid card above the next hidden card
      next_card.before(card);
    } else {
      alert('Carousel can have a maximum of 10 cards!');
    }

  }

  deleteNewCard(e) {
    // Ensures that there is at least 2 card
    if (count > 2) {
      count -= 1;
      e.target.closest(".new-card").classList.add("hidden");
      // Clears the nested fields input
      let inputs = e.target.closest(".new-card").querySelectorAll('input');
      inputs.forEach(input => {
        input.value = '';
      });
    } else {
      alert('Carousel requires at least 2 cards!');
    }
  }

  showAdSetup(e){    
    if(document.getElementById("ad_setup") && document.getElementById("ad_setup").value == "existing_post"){
      document.getElementById("create-ad-format").classList.add("hidden");
      document.getElementById("create-ad-fields").classList.add("hidden");
      document.getElementById("existing-post-fields").classList.remove("hidden");
      if (document.getElementById("ad_website_url")) document.getElementById("ad_website_url").required = false;
    } else if (document.getElementById("ad_setup") && document.getElementById("ad_setup").value == "create_ad") {
      document.getElementById("existing-post-fields").classList.add("hidden");
      document.getElementById("create-ad-fields").classList.remove("hidden");
      document.getElementById("create-ad-format").classList.remove("hidden");
      if (e && e.target && e.target.dataset && e.target.dataset.websiteUrlRequired && document.getElementById("ad_website_url")) document.getElementById("ad_website_url").required = e.target.dataset.websiteUrlRequired;
    } else {
      if (document.getElementById("create-ad-format") && !document.getElementById("create-ad-format").classList.contains("hidden")) document.getElementById("create-ad-format").classList.add("hidden");
      if (document.getElementById("create-ad-fields") && !document.getElementById("create-ad-fields").classList.contains("hidden")) document.getElementById("create-ad-fields").classList.add("hidden");
      if (document.getElementById("existing-post-fields") && !document.getElementById("existing-post-fields").classList.contains("hidden")) document.getElementById("existing-post-fields").classList.add("hidden");
    }
  }

  // Runs after user selects a facebook page
  loadPosts(e) {
    let fb_id = document.getElementById("adset_page_id").value;
    let ig_name = (document.getElementById("ad_ig_account_id").value ? document.getElementById("ad_ig_account_id").options[document.getElementById("ad_ig_account_id").selectedIndex].text : "");
    get(`/facebook/load_ig_posts?fb_id=${fb_id}&ig_name=${ig_name}`, {
      responseKind: "turbo-stream"
    })
    console.log("loading posts");
  }

  // Runs after user clicks Enter post ID button
  showPostIdField(e) {
    document.getElementById("post-id-text").classList.add("hidden");
    document.getElementById("post-id-field").classList.remove("hidden");
  }

  // Runs after user inputs post ID and retrieves the facebook post
  getPostById(e) {
    let post_id = document.getElementById("ad_page_id").value + "_" + document.getElementById("input_post_id").value;
    let source = document.getElementById("source").value
    get(`/facebook/selected_existing_post?post_id=${post_id}&source=${source}`, {
      responseKind: "turbo-stream"
    })
    document.getElementById("post-id-text").classList.remove("hidden");
    document.getElementById("post-id-field").classList.add("hidden");
  }

  // Runs after user selects an existing post in the _post_modal and clicks save
  previewPost(e) {
    let post_id = document.querySelector('input[name=post_id]:checked').value
    let source = document.getElementById("source").value
    get(`/facebook/selected_existing_post?post_id=${post_id}&source=${source}`, {
      responseKind: "turbo-stream"
    })
    this.HiddenURLCallToActionObjetiveTraffic();
  }

  // Hidden element for Objective Traffic Existing post - Facebook Post
  HiddenURLCallToActionObjetiveTraffic(e) {
    let source = document.getElementById("source")
    let link_traffic = document.getElementById("link_traffic_")
    let call_to_action = document.getElementById("call_to_action_type_traffic")
    let website_url_div = document.getElementById("website-url-field")
    let call_to_action_div = document.getElementById("call_to_action_traffic")
    let selected_existing_post = document.getElementById("selected_existing_post")
    
    if (website_url_div && website_url_div.classList.contains("hidden")) website_url_div.classList.remove("hidden");
    if (call_to_action_div && call_to_action_div.classList.contains("hidden")) call_to_action_div.classList.remove("hidden");
    if (call_to_action && call_to_action.classList.contains("hidden")) call_to_action.classList.remove("hidden");
    
    if ((source && source.value == "facebook") || (source && selected_existing_post && source.value == "" && selected_existing_post.innerHTML.includes("Facebook post"))) {
      if (link_traffic) link_traffic.value = "http://fb.me/"
      if (website_url_div) website_url_div.classList.add("hidden");
      if (call_to_action) call_to_action.classList.add("hidden");
      if (call_to_action_div) call_to_action_div.classList.add("hidden");
    }
  }

  // Runs when user clicks on an existing post in _post_modal
  selectPost(e) {
    let row = e.currentTarget;
    document.getElementById("post-modal").querySelectorAll("tr").forEach((el) => el.classList.remove("bg-brand-blue-light"));
    row.querySelector("input").checked = true;
    row.classList.add("bg-brand-blue-light");
    // Determines whether existing post is from facebook or instagram in adapter
    document.getElementById("source").value = row.dataset.source;
  }

  onchangeHeadline(e) {
    let label_headline = document.getElementById(`label-headline`);
    let input_headline_count = 0;
    for (let i = 1; i < 16; i++) {
      if (document.getElementById(`headline-field${i}`) === null) break;
      let input_value = document.getElementById(`headlines_headline${i}`)  ;    
      if (input_value.value) input_headline_count++;
    }
    // Update headline field input from ( 0/15 )
    label_headline.textContent = `Headlines ${input_headline_count}/${label_headline.textContent.split("/")[1]}`;
  }

  onchangeLongHeadline(e) {
    let label_longheadline = document.getElementById(`label-longheadline`);
    let input_longheadline_count = 0;
    for (let i = 1; i < 16; i++) {
      if (document.getElementById(`longheadline-field${i}`) === null) break;
      let input_value = document.getElementById(`longheadlines_longheadline${i}`)  ;    
      if (input_value.value) input_longheadline_count++;
    }
    // Update long headline field input from ( 0/5 )
    label_longheadline.textContent = `Long Headlines ${input_longheadline_count}/${label_longheadline.textContent.split("/")[1]}`;
  }

  countInputLength(e) {
    let id = e.target.id.split("_");
    // field connect rails will use name of field || else will using string split id[1]
    let custom_id = id[1].length == 1 ? e.target.id : id[1];
    let desc_count_id = document.getElementById(`${custom_id}-max-text`);    
    desc_count_id.textContent = `${e.target.value.length}/${desc_count_id.textContent.split("/")[1]}`;
  }

  onchangeDescription(e) {
    let label_headline = document.getElementById(`label-description`);
    let input_headline_count = 0;
    for (let i = 1; i < 16; i++) {
      if (document.getElementById(`description-field${i}`) === null) break;
      let input_value = document.getElementById(`descriptions_description${i}`)  ;    
      if (input_value.value) input_headline_count++;
    }
    // Update Description field input from ( 0/4 )
    label_headline.textContent = `Descriptions ${input_headline_count}/${label_headline.textContent.split("/")[1]}`;
  }

  addField(e) {
    let id_field = e.params.object.idField
    let max_field = e.params.object.maxField
    let no_field = 1;
    for (let i = 1; i <= max_field; i++) {
      no_field = i;
      if (document.getElementById(`${id_field}-field${i}`) === null) {
        break;
      }
    }
    let uppercase_id_field = id_field[0].toUpperCase() + id_field.substring(1);
    let element_field = document.getElementById(`${id_field}-field${no_field - 1}`);
    let clone_element = element_field.cloneNode(true);
    let dynamic_reg = new RegExp(`${id_field}${no_field - 1}`, "g");
    // for void duplicate value and for last uppercase variable, use for "sitelink"
    let new_element = clone_element.innerHTML.replace(dynamic_reg, `${id_field}${no_field}`).replace(/required="required"/g, "").replace(/Required/g, "Optional").replace(`${uppercase_id_field} ${no_field - 1}`, `${uppercase_id_field} ${no_field}`).replace(`${uppercase_id_field} Text${no_field - 1}`, `${uppercase_id_field} Text${no_field}`).replace(`${id_field}s_pin${no_field - 1}`, `${id_field}s_pin${no_field}`).replace(`${id_field}s_pin${no_field - 1}`, `${id_field}s_pin${no_field}`);
    let elem = document.createElement('div');
    elem.id = `${id_field}-field${no_field}`;
    elem.innerHTML = new_element;
    if (elem.getElementsByTagName('input')[0]) elem.getElementsByTagName('input')[0].value = ""
    element_field.parentNode.insertBefore(elem, element_field.nextSibling);
    // reset input count
    let input_count_id = document.getElementById(`${id_field}${no_field}-max-text`);
    if (input_count_id) input_count_id.textContent = "0/" + input_count_id.textContent.split("/")[1];
    // reset pin for RSA
    let input_pin = document.getElementById(`${id_field}s_pin_${id_field}${no_field}`);
    let text_pin = document.getElementById(`${id_field}s_pin${no_field}`);
    if (input_pin && text_pin) { 
      input_pin.value = ""; 
      text_pin.textContent = ""; 
    };
    //hidden button add more field
    if (no_field == max_field) document.getElementById(`btn-add-${id_field}`).classList.add("hidden");
  }

  showHideElement(e) {
    // Just passing element id wanna show or hidden -> in array data
    let show_element_list = e.params.object.show;
    let hidden_element_list = e.params.object.hidden;
    for (let id_element in show_element_list) {
      let id = document.getElementById(show_element_list[id_element]);
      if (id.classList.contains("hidden")) id.classList.remove("hidden");
    }
    for (let id_element in hidden_element_list) {
      let id = document.getElementById(hidden_element_list[id_element]);
      if (!id.classList.contains("hidden")) id.classList.add("hidden");
    }
  }

  addAssetExtension(e) {
    let id_field = e.params.object.idField
    let max_field = e.params.object.maxField
    let req_url = e.params.object.reqUrl
    let id_list = document.getElementById(e.params.object.updateListId);
    let no_field = 1;
    let params = {};
    let child_params = {};
    for (let i = 1; i <= max_field; i++) {
      no_field = i;
      let define_id_field = `${id_field}-field${i}`;
      if (document.getElementById(define_id_field) === null) {
        break;
      } else {
        // prepare json params || input field
        let arr_input = document.getElementById(define_id_field).getElementsByTagName('input')
        if (arr_input[0].value) {
          let element_obj = {};
          for (let n = 0; n < arr_input.length; n++) {
            let input_element_id = arr_input[n].id.split('_')[1].replace("-","_");
            element_obj[input_element_id] = arr_input[n].value;
          }
          child_params[`${id_field}${i}`] = element_obj;
        }
        
        // prepare json params || select field
        let arr_select = document.getElementById(define_id_field).getElementsByTagName('select')
        if (arr_select.length > 0) child_params[arr_select[0].id.split('_')[1].replace("-","_")] = arr_select[0].value;
      }
    }
    
    params[id_field] = child_params;
    
    Rails.ajax({
      url: req_url,
      type: 'POST',
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        options.data = JSON.stringify(params);
        return true;
      },
      success: function(data) {
        for (let d in data) {
          let new_element_list = id_list.childNodes[0].cloneNode(true);
          // return from sitelink 
          if (data[d].sitelink_text) {
            new_element_list.getElementsByTagName('input')[0].value = `{"name": "${data[d].sitelink_text}" ,"id": "${data[d].id}"}`;
            new_element_list.getElementsByTagName('span')[0].textContent = data[d].sitelink_text;
            new_element_list.getElementsByTagName('span')[1].textContent = data[d].site_description1;
            new_element_list.getElementsByTagName('span')[2].textContent = data[d].site_description2;
            id_list.appendChild(new_element_list);
          } else if (data[d].callout_text) {
            // return callout
            new_element_list.getElementsByTagName('input')[0].value = `{"name": "${data[d].callout_text}" ,"id": "${data[d].id}"}`;
            new_element_list.getElementsByTagName('span')[0].textContent = data[d].callout_text;
          } else if (data[d].header) {
            // return structured snippet extensions
            new_element_list.getElementsByTagName('input')[0].value = `{"name": "${data[d].header}" ,"id": "${data[d].id}"}`;
            new_element_list.getElementsByTagName('span')[0].textContent = data[d].header;
          }
          id_list.appendChild(new_element_list);
        }
      },
      error: function(xhr) {
        alert(xhr.errors)
      }
    });
  }

  addCallExtensions(e) {
    let params = {};
    let arr_input = document.getElementById("call-extension").getElementsByTagName("input")
    let arr_select = document.getElementById("call-extension").getElementsByTagName("select")
    for (let i = 0; i < arr_input.length; i++) {
      let input_element_id = arr_input[i].id.split('_')[1].replace("-","_");
      params[input_element_id] = arr_input[i].value;
    }
    for (let s = 0; s < arr_select.length; s++) {
      let input_element_id = arr_select[s].id.split('_')[1].replace("-","_");
      params[input_element_id] = arr_select[s].value;
    }
    // If phone number empty
    if (params.number) {
      Rails.ajax({
        url: '/google/add_call_extension/',
        type: 'POST',
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
          options.data = JSON.stringify(params);
          return true;
        },
        success: function(data) {
          let id_list = document.getElementById("list-call");
          let new_element_list = id_list.childNodes[0].cloneNode(true);
          new_element_list.getElementsByTagName('input')[0].value = `{"name": "${data.number}" ,"id": "${data.id}"}`;
          new_element_list.getElementsByTagName('span')[0].textContent = data.number;
          id_list.appendChild(new_element_list);
        },
        error: function(xhr) {
          alert(xhr.errors)
        }
      });
    } else {
      alert("Number Cannot Empty!");
    }
  }

  onchangePin(e) {
    let textId = e.params.object.textId ? document.getElementById(e.params.object.textId) : false;
    let textPinId = e.params.object.textPinId ? document.getElementById(e.params.object.textPinId) : false;
    let pinnedField = e.params.object.pinnedField;
    let maxPin = e.params.object.maxPin;

    if (textId && textPinId) {      
      if (!textPinId.textContent) {
        textPinId.textContent = 1;
        textId.value = `${pinnedField}_1`
      } else {
        let pin_num = parseInt(textPinId.textContent) + 1;
        if (maxPin < pin_num) {
          textPinId.textContent = "";
          textId.value = "";
        } else {
          textPinId.textContent = pin_num;
          textId.value = `${pinnedField}_${pin_num}`;
        }
      }
    }
  }

  onChangeDisplayAdFormat(e) {
    let radio_format_rda = document.getElementById("ad_format_rda");
    let radio_format_uda = document.getElementById("ad_format_uda");
    let div_rda = document.getElementById("responsive-display-ads");
    let div_ud = document.getElementById("upload-display-ads");
    let uploaded_images = document.getElementById("uploaded-images");
    let uploaded_imagefile = document.getElementById("uploaded-imagefile");
    
    // value field
    let business_name = document.getElementById("ad_g_business_name");
    let headline = document.getElementById("headlines_headline1");
    let description = document.getElementById("descriptions_description1");
    let long_headline = document.getElementById("ad_g_long_headline");
    let ad_name = document.getElementById("ad-name");
    let ad_name_label = document.getElementById("ad-name-label");

    if (radio_format_rda && radio_format_uda) {
      if (div_rda && !div_rda.classList.contains("hidden")) div_rda.classList.add("hidden");
      if (div_ud && !div_ud.classList.contains("hidden")) div_ud.classList.add("hidden");
      if (div_rda && !ad_name_label.classList.contains("hidden")) ad_name_label.classList.add("hidden");
      if (div_ud && !ad_name.classList.contains("hidden")) ad_name.classList.add("hidden");
      if (uploaded_images && uploaded_images.hasAttribute("reviewpage")) uploaded_images.removeAttribute("reviewpage");
      if (uploaded_imagefile && uploaded_imagefile.hasAttribute("reviewpage")) uploaded_imagefile.removeAttribute("reviewpage");
      
      business_name.required = true;
      headline.required = true;
      description.required = true;
      long_headline.required = true;
      ad_name.required = true;

      if (div_rda && radio_format_rda && radio_format_rda.checked == true) {
        div_rda.classList.remove("hidden");
        ad_name.classList.remove("hidden");
        uploaded_images.setAttribute("reviewpage",'{"group": "Image","validate": "image", "dimension": ["Square","Landscape"]}')
      } else {
        div_ud.classList.remove("hidden");
        ad_name_label.classList.remove("hidden");
        business_name.required = false;
        headline.required = false;
        description.required = false;
        long_headline.required = false;
        ad_name.required = false;
        uploaded_imagefile.setAttribute("reviewpage",'{"group": "Image","validate": "image", "dimension": [], "no_duplicate_check": "yes"}')
      }
    }
  }

  onChangeMassEditHeadDesc(e) {
    let key = e.target.dataset.key;
    let fields = e.target.dataset.fields;
    let el_arr = []
    let set_required = false;
    for (let i = 1; i <= parseInt(fields); i++) {
      let el = document.getElementById(`${key}${i}`);
      el_arr.push(el);
      // if have input then set required field.
      if (el.value) set_required = true;
    }
    // set element required -> true | false
    for (let el in el_arr) {
      if (set_required) {
        el_arr[el].required = true;
      } else {
        el_arr[el].required = false;
      }
    }
  }

  onChangeBusinessRequired(e) {
    let business_name = document.getElementById("ad_g_business_name");
    let headline = document.getElementById("headlines_headline1");
    let description = document.getElementById("descriptions_description1");
    let long_headline = document.getElementById("ad_g_long_headline");
    let ad_name = document.getElementById("ad-name");
    business_name.required = false;
    headline.required = false;
    description.required = false;
    long_headline.required = false;
    ad_name.required = false;
  }

  // depend facebook page selected -> Campaign Conversion and Catalog Sales
  updateFacebookPageList(e) {
    let fb_page_id = document.getElementById("page");
    let parent_page_ids = document.querySelectorAll('[parent_page_id]');
    if (fb_page_id) {
      parent_page_ids.forEach(parent_page_id => {
        if (parent_page_id.getAttribute("parent_page_id") != fb_page_id.value){
          parent_page_id.classList.add("hidden");
        } else {
          parent_page_id.classList.remove("hidden");
        }
      })
      document.getElementById("adset_page_id").value = fb_page_id.options[fb_page_id.selectedIndex].text;
    }
  }

  showCatalogueInfo(e) {
    let image_layer_specs = document.getElementById("image-layer-specs");
    let overlay_text_checked = document.querySelector('input[name="overlay_text"]:checked');
    let content_type = document.getElementById("content_type");
    if (image_layer_specs) {
      // hidden element
      if (image_layer_specs.classList.contains("hidden")) image_layer_specs.classList.remove("hidden");
      // show element
      if (overlay_text_checked && overlay_text_checked.value == "option_manual" && content_type && content_type.value == "none") image_layer_specs.classList.add("hidden");

      // update price label
      let div_absolute = document.querySelectorAll('[id^="div_absolute_"]');
      let div_child_absolute = document.querySelectorAll('[id^="div_child_absolute_"]');
      let preview_product_set_id_label = document.querySelectorAll('[id^="preview_product_set_id_label"]');
      let triangle_label = document.querySelectorAll('[id^="triangle_label"]');

      // remove all class
      if (div_absolute) { div_absolute.forEach(item => { item.className = ""; }); }
      if (div_child_absolute) { div_child_absolute.forEach(item => { item.className = ""; item.style.backgroundColor=""; }); }
      if (preview_product_set_id_label) { preview_product_set_id_label.forEach(item => { item.className = ""; item.style.color=""; }); }
      if (triangle_label) { triangle_label.forEach(item => { item.className = ""; item.style.color=""; }); }

      if (overlay_text_checked && overlay_text_checked.value != "option_manual" || content_type && content_type.value != "none") {
        // process assign class
        let overlay_shape = document.getElementById("overlay_shape");
        let shape_color = document.getElementById("shape_color");
        let text_color = document.getElementById("text_color");
        let overlay_position = document.getElementById("overlay_position");
        let opacity = document.getElementById("opacity");
        
        switch(overlay_shape.value) {
          case "rectangle":
            if (div_absolute && overlay_position && overlay_position.value) { div_absolute.forEach(item => { 
              item.classList.add("absolute","m-2","w-20","overflow-hidden","inline-block",`${overlay_position.value.split("_")[0]}-0`,`${overlay_position.value.split("_")[1]}-0`);
            }); }
            if (div_child_absolute && overlay_position && overlay_position.value) { div_child_absolute.forEach(item => { 
              item.classList.add("flex","transform",`origin-${overlay_position.value.split("_")[0]}-${overlay_position.value.split("_")[1]}`,`opacity-${opacity.value}`);
              item.style.backgroundColor = (shape_color.value && shape_color.value != "auto") ? `#${shape_color.value}` : "#7D3C98";
            }); }
            if (preview_product_set_id_label && overlay_position && overlay_position.value) { preview_product_set_id_label.forEach(item => { 
              item.classList.add("w-full","self-center","ml-2");
              item.style.color = (text_color.value && text_color.value != "auto") ? `#${text_color.value}` : "#FFFFFF";
            }); }
            if (triangle_label && overlay_position && overlay_position.value) { triangle_label.forEach(item => { 
              item.classList.add("hidden");
            }); }
            break;
          case "pill":
            if (div_absolute && overlay_position && overlay_position.value) { div_absolute.forEach(item => { 
              item.classList.add("absolute","m-2","w-20","overflow-hidden","inline-block",`${overlay_position.value.split("_")[0]}-0`,`${overlay_position.value.split("_")[1]}-0`);
            }); }
            if (div_child_absolute && overlay_position && overlay_position.value) { div_child_absolute.forEach(item => { 
              item.classList.add("flex","transform","h-6","rounded-full",`origin-${overlay_position.value.split("_")[0]}-${overlay_position.value.split("_")[1]}`,`opacity-${opacity.value}`);
              item.style.backgroundColor = (shape_color.value && shape_color.value != "auto") ? `#${shape_color.value}` : "#7D3C98";
            }); }
            if (preview_product_set_id_label && overlay_position && overlay_position.value) { preview_product_set_id_label.forEach(item => { 
              item.classList.add("w-full","self-center","ml-2");
              item.style.color = (text_color.value && text_color.value != "auto") ? `#${text_color.value}` : "#FFFFFF";
            }); }
            if (triangle_label && overlay_position && overlay_position.value) { triangle_label.forEach(item => { 
              item.classList.add("hidden");
            }); }
            break;
          case "circle":
            if (div_absolute && overlay_position && overlay_position.value) { div_absolute.forEach(item => { 
              item.classList.add("absolute","m-2","w-20","overflow-hidden","inline-block",`${overlay_position.value.split("_")[0]}-0`,`${overlay_position.value.split("_")[1]}-0`);
            }); }
            if (div_child_absolute && overlay_position && overlay_position.value) { div_child_absolute.forEach(item => { 
              item.classList.add("flex","transform","h-20","rounded-full",`origin-${overlay_position.value.split("_")[0]}-${overlay_position.value.split("_")[1]}`,`opacity-${opacity.value}`);
              item.style.backgroundColor = (shape_color.value && shape_color.value != "auto") ? `#${shape_color.value}` : "#7D3C98";
            }); }
            if (preview_product_set_id_label && overlay_position && overlay_position.value) { preview_product_set_id_label.forEach(item => { 
              item.classList.add("w-full","self-center","ml-2");
              item.style.color = (text_color.value && text_color.value != "auto") ? `#${text_color.value}` : "#FFFFFF";
            }); }
            if (triangle_label && overlay_position && overlay_position.value) { triangle_label.forEach(item => { 
              item.classList.add("hidden");
            }); }
            break;
          case "triangle":
            if (div_absolute && overlay_position && overlay_position.value) { div_absolute.forEach(item => { 
              item.classList.add("absolute","m-2","w-20","overflow-hidden","inline-block",`${overlay_position.value.split("_")[0]}-0`,`${overlay_position.value.split("_")[1]}-0`);
            }); }
            if (div_child_absolute && overlay_position && overlay_position.value) { div_child_absolute.forEach(item => { 
              // DEPEND OVERLAY POSITION
              switch(overlay_position.value) {
                case "top_left":
                  item.classList.add("flex","transform","h-28","origin-top-right","rotate-45",`opacity-${opacity.value}`);
                  break;
                case "top_right":
                  item.classList.add("flex","transform","h-28","origin-top-left","-rotate-45",`opacity-${opacity.value}`);
                  break;
                case "bottom_left":
                  item.classList.add("flex","transform","h-28","origin-bottom-right","-rotate-45",`opacity-${opacity.value}`);
                  break;
                case "bottom_right":
                  item.classList.add("flex","transform","h-28","origin-bottom-left","rotate-45",`opacity-${opacity.value}`);
                  break;
                default:
                  false
              }
              item.style.backgroundColor = (shape_color.value && shape_color.value != "auto") ? `#${shape_color.value}` : "#7D3C98";
            }); }
            if (preview_product_set_id_label && overlay_position && overlay_position.value) { preview_product_set_id_label.forEach(item => { 
              item.classList.add("hidden");
            }); }
            if (triangle_label && overlay_position && overlay_position.value) { triangle_label.forEach(item => { 
              item.classList.add("absolute","m-2",`${overlay_position.value.split("_")[0]}-0`,`${overlay_position.value.split("_")[1]}-0`);
              item.style.color = (text_color.value && text_color.value != "auto") ? `#${text_color.value}` : "#FFFFFF";
            }); }
            break;
          default:
            false
        }
      } else {
        if (div_absolute) { div_absolute.forEach(item => { item.classList.add("hidden"); }); }
        if (div_child_absolute) { div_child_absolute.forEach(item => { item.classList.add("hidden"); }); }
        if (preview_product_set_id_label) { preview_product_set_id_label.forEach(item => { item.classList.add("hidden"); }); }
        if (triangle_label) { triangle_label.forEach(item => { item.classList.add("hidden"); }); }
      }
    }
  }

  showCreativeToolsCatalogInfo(e) {
    let creativetools = document.getElementById("creativetools");
    let creative_option = document.querySelector('input[name="creative_option"]:checked');
    // hidden element
    if (creativetools && creative_option) {
      if (!creativetools.classList.contains("hidden")) creativetools.classList.add("hidden");
      if (creative_option.value == "option_single_image") creativetools.classList.remove("hidden")
    }
  }

  showCreativeOptionCatalogInfo(e) {
    let title_carousel_cards = document.getElementById("title_carousel_cards");
    let title_in_card = document.getElementById("title_in_card");
    let creativeoption_div = document.getElementById("creativeoption_div");
    let ad_format_carousel = document.getElementById("ad_format_carousel");
    
    if (ad_format_carousel && ad_format_carousel.checked) {
      if (creativeoption_div && creativeoption_div.classList.contains("hidden")) creativeoption_div.classList.remove("hidden");
      if (title_carousel_cards) title_carousel_cards.textContent = "Carousel Cards"
      if (title_in_card) title_in_card.textContent = "Catalogue"
    } else {
      if (creativeoption_div && !creativeoption_div.classList.contains("hidden")) creativeoption_div.classList.add("hidden");
      if (title_carousel_cards) title_carousel_cards.textContent = ""
      if (title_in_card) title_in_card.textContent = ""
    }
  }
}