import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ "query", "query_exclude", "location", "input", "modal", "locale", "language", "exclude_location", "keyword_url", "keyword_product", "keyword_youtube", "specific", "keyword_theme"]
  connect() {
    console.log("Im connected to search!");
  }

  // --------------------- Searching for detailed targeting ----------------------------
  searchObject(e) {
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("result-dropdown");
    let checkboxOptions = document.getElementById("checkbox-options");
    let spinner = document.getElementById(`loader`);;
    let dropdownOptionContainer = document.createElement("div");

    // Load the spinner if the button is clicked again
    spinner.classList.remove("hidden");

    Rails.ajax({
      url: '/facebook/search_targeting',
      type: 'POST',
      data: "query=" + this.queryTarget.value,
      success: function(data) {
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        spinner.classList.add("hidden");
        // Populate data inside dropdown
        const resultArr = [...document.getElementById("search-results").children].map(element => JSON.parse(element.querySelector("input").value).id);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          let temp = result.audience_size_lower_bound;
          delete result.audience_size_lower_bound;
          result.audience_size_lower_bound = temp;
          temp = result.audience_size_upper_bound;
          delete result.audience_size_upper_bound;
          result.audience_size_upper_bound = temp;
          checkbox.value = JSON.stringify(result);
          if (resultArr.includes(result.id)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displaySearchResults input->adsets#getReachEstimation";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name +" "+ "("+result.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') + ")"
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText)
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    });
  }

  searchExcludedObject(e) {
    e.preventDefault();
    // Remove previous search results
    let excludeDropdownOptions = document.getElementById("remove-excluded-options");
    if (excludeDropdownOptions){
      excludeDropdownOptions.remove();
    }

    // Get the relevant elements
    let excludedResultDropdown = document.getElementById("exclude-result-dropdown");
    let excludeCheckboxOptions = document.getElementById("exclude-checkbox-options");
    let excludeSpinner = document.getElementById(`exclude-loader`);
    let dropdownOptionContainer = document.createElement("div");

    // Load the spinner if the button is clicked again
    excludeSpinner.classList.remove("hidden");
    Rails.ajax({
      url: '/facebook/search_targeting',
      type: 'POST',
      data: "query=" + this.query_excludeTarget.value,
      success: function(data) {
        // Open the dropdown
        excludedResultDropdown.parentElement.ariaExpanded = "true";
        excludedResultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        excludedResultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        excludeSpinner.classList.add("hidden");
        // Populate data inside dropdown
        const resultArr = [...document.getElementById("exclude-search-results").children].map(element => JSON.parse(element.querySelector("input").value).id);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-excluded-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          let temp = result.audience_size_lower_bound;
          delete result.audience_size_lower_bound;
          result.audience_size_lower_bound = temp;
          temp = result.audience_size_upper_bound;
          delete result.audience_size_upper_bound;
          result.audience_size_upper_bound = temp;
          checkbox.value = JSON.stringify(result);
          if (resultArr.includes(result.id)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayExcludedSearchResults input->adsets#getReachEstimation";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name +" "+ "("+result.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') + ")"
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText)
          excludeCheckboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    });
  }

  displaySearchResults(e) {
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query,"selected_items[]", "selected_items", check);
    displayResults(value, "search-results", "remove-options", `input[value*='"id":"${value.id}"']`);
    // let audienceSizeRange = document.getElementById("estimated-audience-size");
    // Set audience estimation
    // audienceSizeRange.innerHTML = "Estimated Audience Size: " + value["audience_size_lower_bound"] + " - " + value["audience_size_upper_bound"];
  }
  
  displayExcludedSearchResults(e) {
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayExcludedResults = (data, searchElementId, dropdownElementId, query_exclude)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query_exclude,"exclude_selected_items[]", "exclude_selected_items", check);
    displayExcludedResults(value, "exclude-search-results", "remove-excluded-options", `input[value*='"id":"${value.id}"']`);
  }

  removeSelectedItem(e){
    e.preventDefault();
    let container = e.target.parentElement.parentElement.closest("div");
    e.target.parentElement.parentElement.remove();
    if (container.children.length == 0) {
      container.classList.add("hidden");
    }

    // Also uncheck from dropdown
    let dropdown = document.getElementById(e.target.getAttribute("data-remove"));
    if (dropdown) {
      let checkbox = dropdown.querySelector(e.target.getAttribute("data-query"));
      if(checkbox) checkbox.checked = false;
    }
  }

  // --------------------- Searching for location targeting ----------------------------
  // ------------------Need to think about how to merge the 2 js------------------------
  // -------------------method to reuse stimulus component -----------------------------
  searchLocation(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-location-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("location-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("location-checkbox-options");


    Rails.ajax({
      url: '/facebook/search_location',
      type: 'POST',
      data: "query=" + this.locationTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const locationArr = [...document.getElementById("search-location-results").children].map(element => JSON.parse(element.querySelector("input").value).key);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-location-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          // need to delete and add back to swap key positions as JSON.generate() in Ruby on Rails and JSON.stringify() in JavaScript gives different results
          let temp = result.supports_region;
          delete result.supports_region;
          result.supports_region = temp;
          checkbox.value = JSON.stringify(result)
          if (locationArr.includes(result.key)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayLocationSearchResults input->adsets#getReachEstimation input->review-page#upReviewPage";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name + " (" + result.type.charAt(0).toUpperCase() +  result.type.slice(1) + ")";
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayLocationSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "location_lists[]", "location_lists", check);
    displayResults(value, "search-location-results", "remove-location-options", `input[value*='"key":"${value.key}"']`);
    // Set audience estimation
    // audienceSizeRange.innerHTML = "Estimated Audience Size: " + value["audience_size_lower_bound"] + " - " + value["audience_size_upper_bound"];
  }

  searchExcludeLocationFb(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-exclude-location-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("exclude-location-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("exclude-location-checkbox-options");

    Rails.ajax({
      url: '/facebook/search_location',
      type: 'POST',
      data: "query=" + this.exclude_locationTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-exclude-location-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          checkbox.dataset.action = "input->search#displayExcludeLocationSearchResults input->adsets#getReachEstimation";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name + " (" + result.type.charAt(0).toUpperCase() +  result.type.slice(1) + ")";
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayExcludeLocationSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId)  => this.displaySearchResultsChip(data, searchElementId, "exclude_location_lists[]", "exclude_location_lists", check);
    displayResults(value, "search-exclude-location-results");
    // Set audience estimation
    // audienceSizeRange.innerHTML = "Estimated Audience Size: " + value["audience_size_lower_bound"] + " - " + value["audience_size_upper_bound"];
  }
  
  // --------------------- Searching for locale targeting ----------------------------
  // ------------------Need to think about how to merge the 2 js------------------------
  // -------------------method to reuse stimulus component -----------------------------
  searchLocale(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-locale-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("locale-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("locale-checkbox-options");
    
    Rails.ajax({
      url: '/facebook/search_locale',
      type: 'POST',
      data: "query=" + this.localeTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const localeArr = [...document.getElementById("search-locale-results").children].map(element => JSON.parse(element.querySelector("input").value).key);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-locale-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          let temp = result.name;
          delete result.name;
          result.name = temp;
          checkbox.value = JSON.stringify(result);
          if (localeArr.includes(result.key)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayLocaleSearchResults input->adsets#getReachEstimation";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name;
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayLocaleSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "locale_lists[]", "locale_lists", check);
    displayResults(value, "search-locale-results", "remove-locale-options", `input[value*='"key":${value.key}']`);
  }

  // This method is to match the locations with facebook's locations before creating location list
  matchLocationResults(e) {
    // Get relevant elements
    let locationList = document.getElementById("location-list");
    let locationListResults = document.getElementById("location-list-results");
    let locationType = document.getElementById("location-type");
    let successBanner = document.getElementById("success-matching-location");
    let errorBanner = document.getElementById("error-matching-location");
    let errorMessage = document.getElementById("error-message");

    let checkboxOptions = document.getElementById("checkbox-list");
    let dropdownOptionContainer = document.createElement("div");

    // Remove the checkbox options everytime a user starts matching location
    while (locationListResults.firstChild) {
      locationListResults.removeChild(locationListResults.lastChild);
    }

    let displayResults = (data, searchElementId)  => this.displayLocationCheckbox(data, searchElementId);

    Rails.ajax({
      url: '/facebook/match_locations',
      type: 'POST',
      data: "locationList=" + locationList.value + "&locationType=" + locationType.value,
      success: function(data) {
        if (data.every(arr => arr[2] == true)){
          successBanner.classList.remove("hidden");
          errorBanner.classList.add("hidden");
        } else {
          successBanner.classList.add("hidden");
          errorBanner.classList.remove("hidden");
          let locationNotFound = data.filter(e => e[2] == false)
          let count = locationNotFound.length
          errorMessage.innerHTML = count + " location(s) (" + locationNotFound.map(e => e[0]).join(", ") + ") couldn’t be added. Please edit your list."
        }
        data.forEach(function(list){
          if(list[2] == true){
            list[1].forEach(function(elem){
              displayResults(elem, "location-list-results")
            })
          }
        })
      }
    })
  }

  saveLocationSuccess(e){
    e.preventDefault();
    // Get Age data
    let listName = document.getElementById("list-name");
    let locationType = document.getElementById("location-type");
    let locationList = document.getElementById("location-list");

    // Get results location chosen by users in the location modal
    let chosenResults = Array.from(document.querySelectorAll('[name="chosen_locations[]"]')).filter(elem => elem.checked);
    let chosenResultsValueArr = chosenResults.map(elem => elem.value)

    let displayResults = (data, searchElementId)  => this.displaySearchResultsChip(data, searchElementId, "location_lists[]", "location_lists", true);
    let modalTarget = this.modalTarget;

    // Send data to adapter for reach estimates returns
    Rails.ajax({
      url: '/facebook/create_location_list',
      type: 'POST',
      data: "client_id=" + e.target.dataset.clientId + "&list_name=" + listName.value + "&location_type=" + locationType.value + "&chosen_locations=" + chosenResultsValueArr,
      success: function(data) {
        chosenResults.forEach(function(elem){
          // Display chosen results as chips
          displayResults(JSON.parse(elem.value), "search-location-results")
        });
        // Remove modal
        modalTarget.classList.add("hidden");
      }
    });
  }

  loadList(e){
    let displayResults = (data, searchElementId)  => this.displaySearchResultsChip(data, searchElementId, "location_lists[]", "location_lists", true);

    // Send data to adapter for reach estimates returns
    Rails.ajax({
      url: '/facebook/load_location_list',
      type: 'POST',
      data: "client_id=" + e.target.dataset.clientId + "&name=" + e.target.value,
      success: function(data) {
        data.forEach(function(elem){
          if(e.target.checked == true){
            displayResults(elem, "search-location-results");
          } else {
            // Remove results from search
            let resultChip = Array.from(document.querySelectorAll('[name="location_lists[]"]')).filter(item => item.value == JSON.stringify(elem));
            let container = resultChip[0].parentElement.closest("div");
            resultChip[0].parentElement.remove();
            if (container.children.length) {
              container.classList.add("hidden");
            }
          }
        })
      }
    });
  }

  // copy from loadList ( old get list from client table, new one get from audience table )
  loadListAudience(e){
    let displayResults = (data, searchElementId)  => this.displaySearchResultsChip(data, searchElementId, "location_lists[]", "location_lists", true);
    // Send data to adapter for reach estimates returns
    Rails.ajax({
      url: '/load_location_list_from_audience',
      type: 'POST',
      data: "&name=" + e.target.value,
      success: function(data) {
        data.forEach(function(elem){
          if(e.target.checked == true){
            displayResults(elem, "search-location-results");
          } else {
            // Remove results from search
            let resultChip = Array.from(document.querySelectorAll('[name="location_lists[]"]')).filter(item => item.value == JSON.stringify(elem));
            resultChip[0].parentElement.remove();
          }
        })
      }
    });
  }

  displaySearchResultsChip(value, searchResult, removeOption, query, inputName, inputId, state) {
    let searchResults = document.getElementById(searchResult);
    if (state) {
      // Create selected items checkbox for submitting to controller
      let selectedItemsContainer = document.createElement("span");
      let selectedItemsText = document.createElement("span");
      let selectedItemsInput = document.createElement("input");
      let selectedItemsButton = document.createElement("button");
      let selectedItemsIcon = document.createElement("i");

      // Add class attributes to created elements
      selectedItemsContainer.classList.add("flex", "justify-center", "m-1", "px-3", "py-0", "rounded-full", "bg-monochrome-grey-blue-dark-1", "text-sm");
      selectedItemsText.classList.add("mt-1", "text-monochrome-white");
      selectedItemsText.innerHTML = value.name;
      selectedItemsInput.type = "checkbox";
      selectedItemsInput.name = inputName;
      selectedItemsInput.id = inputId;
      selectedItemsInput.checked = true;
      selectedItemsInput.value = JSON.stringify(value);
      selectedItemsInput.style.visibility = "hidden";
      selectedItemsButton.classList.add("bg-transparent", "hover", "focus:outline-none", "remove-chip");
      selectedItemsButton.dataset.action = "click->search#removeSelectedItem";
      selectedItemsIcon.classList.add("material-symbols-outlined", "text-monochrome-grey-blue-dark-3", "mt-1.5");
      selectedItemsIcon.innerHTML = "cancel";
      selectedItemsIcon.style.fontSize = "18px";
      selectedItemsIcon.style.fontVariationSettings = "'FILL' 1";
      selectedItemsIcon.setAttribute("data-remove", removeOption);
      selectedItemsIcon.setAttribute("data-query", query);

      // Unhide div to show selected items
      searchResults.classList.remove("hidden");

      // Append elements together
      selectedItemsButton.appendChild(selectedItemsIcon);
      selectedItemsContainer.appendChild(selectedItemsText);
      selectedItemsContainer.appendChild(selectedItemsInput);
      selectedItemsContainer.appendChild(selectedItemsButton);
      searchResults.appendChild(selectedItemsContainer);
    } else {
      let selectedItemsContainer = document.querySelector("input[name='" + inputName + "'][value='" + JSON.stringify(value) + "']").closest("span");
      selectedItemsContainer.remove();
      if (searchResults.children.length == 0) {
        searchResults.classList.add("hidden");
      }
    }
  }

  displayLocationCheckbox(value, searchResult) {
    let searchResults = document.getElementById(searchResult);

    // Create selected items checkbox for submitting to controller
    let selectedItemsContainer = document.createElement("span");
    let selectedItemsText = document.createElement("span");
    let selectedItemsInput = document.createElement("input");

    // Add class attributes to created elements
    selectedItemsText.classList.add("mt-1", "mr-5", "text-monochrome-grey-blue-dark-1");
    selectedItemsText.innerHTML = value.name;
    selectedItemsInput.type = "checkbox";
    selectedItemsInput.name = "chosen_locations[]";
    selectedItemsInput.id = "chosen_locations";
    selectedItemsInput.classList.add("mr-1");
    selectedItemsInput.value = JSON.stringify(value);
    selectedItemsInput.checked = false;

    // Unhide div to show selected items
    searchResults.classList.remove("hidden");

    // Append elements together
    selectedItemsContainer.appendChild(selectedItemsInput);
    selectedItemsContainer.appendChild(selectedItemsText);
    searchResults.appendChild(selectedItemsContainer);
  }


  // --------------------- Google Language ----------------------------
  searchLanguage(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-language-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("language-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("language-checkbox-options");
    Rails.ajax({
      url: '/google/search_language',
      type: 'POST',
      data: "query=" + this.languageTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const languageArr = [...document.getElementById("search-language-results").children].map(element => JSON.parse(element.querySelector("input").value).id);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-language-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          if (languageArr.includes(result.id)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayLanguageSearchResults change->review-page#upReviewPage";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name;
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayLanguageSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "language_lists[]", "language_lists", check);
    displayResults(value, "search-language-results", "remove-language-options", `input[value*='"id":${value.id}']`);
  }

  // --------------------- Google Include Location ----------------------------
  searchIncludeLocation(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-include-location-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("include-location-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("include-location-checkbox-options");
    
    Rails.ajax({
      url: '/google/search_location',
      type: 'POST',
      data: "query=" + this.locationTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const includeLocationArr = [...document.getElementById("search-include-location-results").children].map(element => JSON.parse(element.querySelector("input").value).id);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-include-location-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          if (includeLocationArr.includes(result.id)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayIncludeLocationSearchResults change->review-page#upReviewPage";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name + " (" + result.type.charAt(0).toUpperCase() +  result.type.slice(1) + ")"
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayIncludeLocationSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "include_location_lists[]", "include_location_lists", check);
    displayResults(value, "search-include-location-results", "remove-include-location-options", `input[value*='"id":${value.id}']`);
  }

  // --------------------- Google Exclude Location ----------------------------
  searchExcludeLocation(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-exclude-location-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("exclude-location-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("exclude-location-checkbox-options");
    
    Rails.ajax({
      url: '/google/search_location',
      type: 'POST',
      data: "query=" + this.exclude_locationTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const excludeLocationArr = [...document.getElementById("search-exclude-location-results").children].map(element => JSON.parse(element.querySelector("input").value).id);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-exclude-location-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          if (excludeLocationArr.includes(result.id)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayExcludeLocationSearchResults change->review-page#upReviewPage input->adsets#getReachEstimation";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name + " (" + result.type.charAt(0).toUpperCase() +  result.type.slice(1) + ")"
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayExcludeLocationSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "exclude_location_lists[]", "exclude_location_lists", check);
    displayResults(value, "search-exclude-location-results", "remove-exclude-location-options", `input[value*='"id":${value.id}']`);
  }

  // --------------------- Google Audience Segment ----------------------------

  displayAudienceSegmentResults(e) {
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "selected_audience_items[]", "selected_audience_items", check);
    displayResults(value, "search-results", "detailed_targeting_dropdown", `input[value*='"id":${value.id}']`);
  }

  // --------------------- Google Targeting Demographic Adgroup ----------------------------

  displayDemographicsResults(e) {
    let value = JSON.parse(e.target.value);
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "selected_demographics_items[]", "selected_demographics_items");
    displayResults(value, "search-demographics-results", null, null);
  }

  displayTopicsResults(e) {
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "selected_topics_items[]", "selected_topics_items", check);
    displayResults(value, "search-topics-results", "topics_dropdown", `input[value*='${JSON.stringify(value)}']`);
  }

  displayPlacementsResults(e) {
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "selected_placements_items[]", "selected_placements_items", check);
    displayResults(value, "search-placements-results", "placements_dropdown", `input[value*='"id":${value.id}']`);
  }

  // --------------------- Google Gen Keyword ----------------------------
  genKeyword(e){
    e.preventDefault();
    let area_keyword = document.getElementById("keyword_value");
    Rails.ajax({
      url: '/google/gen_keyword',
      type: 'POST',
      data: `keyword_url=${this.keyword_urlTarget.value}&keyword_product=${this.keyword_productTarget.value}`,
      success: function(data) {
        area_keyword.value = ""
        area_keyword.value = data.join("\r\n");
        const event_change = new Event("change");
        area_keyword.dispatchEvent(event_change);
      }
    })
  }

  displayCheckBoxTagResults(e) {
    let clearId = e.params.object.clearId ? document.getElementById(e.params.object.clearId) : false
    if (clearId) clearId.innerHTML = "";

    let id = e.params.object.id;
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, `selected_${id}_items[]`, `selected_${id}_items`, check);
    displayResults(value, `search-${id}-results`, `${id}-dropdown`, `input[value*='"id":"${value.id}"']`);
  }

  // --------------------- Google Gen Youtube ----------------------------
  displaySearchResultsChipGoogle(value, searchResult, removeOption, query, inputName, inputId) {
    let searchResults = document.getElementById(searchResult);
    
    if (value.check == false) {
      let remove_element_id = document.getElementById(inputId + value.id);
      if (remove_element_id) remove_element_id.remove();
    } else if (searchResults.childElementCount == 5) {
      alert(`You can only select up to 5 YouTube videos`);
    } else {
      // Create selected items checkbox for submitting to controller
      let selectedItemsContainer = document.createElement("span");
      let selectedItemsImage = document.createElement("img");
      let selectedItemsText = document.createElement("span");
      let selectedItemsInput = document.createElement("input");
      let selectedItemsButton = document.createElement("button");
      let selectedItemsIcon = document.createElement("i");
  
      // Add class attributes to created elements
      selectedItemsContainer.id = inputId + value.id;
      selectedItemsContainer.classList.add("flex","w-full", "m-1", "p-3", "rounded-md", "border", "border-gray-800", "text-sm", "bg-white");
      selectedItemsText.classList.add("mt-1", "w-full");
      selectedItemsText.innerHTML = value.name;
      selectedItemsInput.type = "checkbox";
      selectedItemsInput.name = inputName;
      selectedItemsInput.id = inputId;
      selectedItemsInput.checked = true;
      selectedItemsInput.value = JSON.stringify(value);
      selectedItemsInput.style.visibility = "hidden";
      selectedItemsButton.classList.add("bg-transparent", "hover", "focus:outline-none", "remove-chip");
      selectedItemsButton.dataset.action = "click->search#removeSelectedItem";
      selectedItemsIcon.classList.add("material-symbols-outlined", "text-monochrome-grey-blue-dark-3", "mt-1.5");
      selectedItemsIcon.innerHTML = "cancel";
      selectedItemsIcon.style.fontSize = "18px";
      selectedItemsIcon.style.fontVariationSettings = "'FILL' 1";
      selectedItemsIcon.setAttribute("data-remove", removeOption);
      selectedItemsIcon.setAttribute("data-query", query);
  
      // Unhide div to show selected items
      searchResults.classList.remove("hidden");
  
      // Append elements together
      selectedItemsButton.appendChild(selectedItemsIcon);
      if (value.image) {
        selectedItemsImage.src = value.image
        selectedItemsImage.width = "78";
        selectedItemsImage.height = "44";
        selectedItemsImage.classList.add("pr-3");
        selectedItemsContainer.appendChild(selectedItemsImage);
      }
      selectedItemsContainer.appendChild(selectedItemsText);
      selectedItemsContainer.appendChild(selectedItemsInput);
      selectedItemsContainer.appendChild(selectedItemsButton);
      searchResults.appendChild(selectedItemsContainer);
    }
  }


  searchYoutubeVideo(e) {
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-youtube-video-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("youtube-video-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("youtube-video-checkbox-options");
    
    Rails.ajax({
      url: '/google/search_youtube_keyword',
      type: 'POST',
      data: `keyword=${this.keyword_youtubeTarget.value}`,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          let image = document.createElement("img");
          dropdownOptionContainer.id = "remove-youtube-video-options";
          labelText.classList.add("my-5", "ml-3", "flex");
          labelText.for = "checkbox_label";
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          checkbox.dataset.action = "input->search#displayYoutubeVideoSearchResults";
          checkbox.classList.add("self-center");
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name;
          image.classList.add("pl-3");
          image.src = result.image;
          image.width = "78";
          image.height = "44";
          labelText.appendChild(checkbox);
          labelText.appendChild(image);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayYoutubeVideoSearchResults(e){
    let value = JSON.parse(e.target.value);
    value["check"] = e.target.checked;
    let displayResults = (data, searchElementId, removeOption, query)  => this.displaySearchResultsChipGoogle(data, searchElementId, removeOption, query, "youtube_video_lists[]", "youtube_video_lists");
    displayResults(value, "search-youtube-video-results", "youtube-video-checkbox-options", `input[value*='"id":"${value.id}"']`);
  }

  // --------------------- Google Near Location ----------------------------
  searchNearLocation(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-near-location-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("near-location-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("near-location-checkbox-options");
    
    Rails.ajax({
      url: '/google/search_location',
      type: 'POST',
      data: "query=" + this.locationTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const nearLocationArr = [...document.getElementById("search-near-location-results").children].map(element => JSON.parse(element.querySelector("input").value).id);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-near-location-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          if (nearLocationArr.includes(result.id)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayNearLocationSearchResults";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name + " (" + result.type.charAt(0).toUpperCase() +  result.type.slice(1) + ")"
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayNearLocationSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "near_location_lists[]", "near_location_lists", check);
    displayResults(value, "search-near-location-results", "remove-near-location-options", `input[value*='"id":${value.id}']`);
  }

  // --------------------- Google Specific Location ----------------------------
  searchSpecificLocation(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-specific-location-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("specific-location-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("specific-location-checkbox-options");
    
    Rails.ajax({
      url: '/google/search_location',
      type: 'POST',
      data: "query=" + this.specificTarget.value,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const specificLocationArr = [...document.getElementById("search-specific-location-results").children].map(element => JSON.parse(element.querySelector("input").value).id);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-specific-location-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          if (specificLocationArr.includes(result.id)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displaySpecificLocationSearchResults";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name + " (" + result.type.charAt(0).toUpperCase() +  result.type.slice(1) + ")"
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displaySpecificLocationSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "specific_location_lists[]", "specific_location_lists", check);
    displayResults(value, "search-specific-location-results", "remove-specific-location-options", `input[value*='"id":${value.id}']`);
  }

  // --------------------- Google Gen Keyword Theme Suggestion Location ----------------------------
  searchKeywordTheme(e){
    e.preventDefault();
    // Remove previous search results
    let dropdownOptions = document.getElementById("remove-keyword-theme-options");
    if (dropdownOptions){
      dropdownOptions.remove();
    }

    // Get the relevant elements
    let resultDropdown = document.getElementById("keyword-theme-result-dropdown");
    // let spinner = document.querySelector(".loader");
    let dropdownOptionContainer = document.createElement("div");
    let checkboxOptions = document.getElementById("keyword-theme-checkbox-options");
    const str_url = window.location.href
    const c_id = str_url.slice(str_url.indexOf('campaigns/') + 10, str_url.lastIndexOf('/adsets'));
    Rails.ajax({
      url: '/google/gen_keyword_theme_suggestion',
      type: 'POST',
      data: `keyword=${this.keyword_themeTarget.value}&id=${c_id}`,
      success: function(data) {
        // clear checkbox data
        checkboxOptions.innerHTML = "";
        // Open the dropdown
        resultDropdown.parentElement.ariaExpanded = "true";
        resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
        // Add dropdown stimulus action when data is successfully passed back
        resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
        // Hide the loader spinner
        // spinner.classList.add("hidden");
        // Populate data inside dropdown
        const keywordArr = [...document.getElementById("search-keyword-theme-results").children].map(element => JSON.parse(element.querySelector("input").value).name);
        data.forEach(result => {
          let labelText = document.createElement("label");
          let checkbox = document.createElement("input");
          let checkBoxText = document.createElement("span");
          dropdownOptionContainer.id = "remove-keyword-theme-options";
          labelText.classList.add("my-5", "ml-3");
          labelText.for = "checkbox_label"
          checkbox.type = "checkbox";
          checkbox.name = "search_options";
          checkbox.value = JSON.stringify(result);
          if (keywordArr.includes(result.name)) checkbox.checked = true;
          checkbox.dataset.action = "input->search#displayKeywordThemeSearchResults";
          checkBoxText.classList.add("p3", "ml-3");
          checkBoxText.innerHTML = result.name;
          labelText.appendChild(checkbox);
          labelText.appendChild(checkBoxText);
          dropdownOptionContainer.appendChild(labelText);
          checkboxOptions.appendChild(dropdownOptionContainer);
        })
      }
    })
  }

  displayKeywordThemeSearchResults(e){
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query, "keyword_theme_lists[]", "keyword_theme_lists", check);
    displayResults(value, "search-keyword-theme-results", "remove-keyword-theme-options", `input[value*='"id":"${value.id}"']`);
  }

  // --------------------- Searching for detailed targeting Narrow Further ----------------------------
  searchObjectNarrowFuther(e) {
    let searchFieldValue = "";
    let action_no = "";
    if (e.params.object && e.params.object.action_no) {
      action_no = e.params.object.action_no;
      searchFieldValue = document.getElementById(`search${action_no}`).value;
    }
    let setDivID = `search-results${action_no}`;

    e.preventDefault();
    if (searchFieldValue && e.params.object && e.params.object.action_no) {
      // Remove previous search results
      let dropdownOptions = document.getElementById("remove-options");
      if (dropdownOptions){
        dropdownOptions.remove();
      }
  
      // Get the relevant elements
      let resultDropdown = document.getElementById(`result-dropdown${action_no}`);
      let checkboxOptions = document.getElementById(`checkbox-options${action_no}`);
      let spinner = document.getElementById(`loader${action_no}`);;
      let dropdownOptionContainer = document.createElement("div");
  
      // Load the spinner if the button is clicked again
      spinner.classList.remove("hidden");
  
      Rails.ajax({
        url: '/facebook/search_targeting',
        type: 'POST',
        data: "query=" + searchFieldValue,
        success: function(data) {
          // Open the dropdown
          resultDropdown.parentElement.ariaExpanded = "true";
          resultDropdown.parentElement.dataset.dropdownOpenValue = "true";
          // Add dropdown stimulus action when data is successfully passed back
          resultDropdown.dataset.action = "click->dropdown#toggle click@window->dropdown#hide touch->dropdown#toggle touch@window->dropdown#hide";
          // Hide the loader spinner
          spinner.classList.add("hidden");
          // Populate data inside dropdown

          const resultArr = [...document.getElementById(setDivID).children].map(element => JSON.parse(element.querySelector("input").value).id);
          data.forEach(result => {
            let labelText = document.createElement("label");
            let checkbox = document.createElement("input");
            let checkBoxText = document.createElement("span");
            dropdownOptionContainer.id = "remove-options";
            labelText.classList.add("my-5", "ml-3");
            labelText.for = "checkbox_label"
            checkbox.type = "checkbox";
            checkbox.name = "search_options";
            let temp = result.audience_size_lower_bound;
            delete result.audience_size_lower_bound;
            result.audience_size_lower_bound = temp;
            temp = result.audience_size_upper_bound;
            delete result.audience_size_upper_bound;
            result.audience_size_upper_bound = temp;
            checkbox.value = JSON.stringify(result);
            if (resultArr.includes(result.id)) checkbox.checked = true;
            checkbox.dataset.action = "input->search#NarrowFutherdisplaySearchResults";
            checkbox.setAttribute("data-search-object-param", `{"action_no": "${action_no}"}`);
            checkBoxText.classList.add("p3", "ml-3");
            checkBoxText.innerHTML = result.name +" "+ "("+result.type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') + ")"
            labelText.appendChild(checkbox);
            labelText.appendChild(checkBoxText);
            dropdownOptionContainer.appendChild(labelText)
            checkboxOptions.appendChild(dropdownOptionContainer);
          })
        }
      });
    }
  }

  NarrowFutherdisplaySearchResults(e) {
    let action_no = "";
    if (e.params.object && e.params.object.action_no) action_no = e.params.object.action_no;
    let value = JSON.parse(e.target.value);
    let check = e.target.checked;
    let displayResults = (data, searchElementId, dropdownElementId, query)  => this.displaySearchResultsChip(data, searchElementId, dropdownElementId, query,`selected_items${action_no}[]`, `selected_items${action_no}`, check);
    displayResults(value, `search-results${action_no}`, "remove-options", `input[value*='"id":"${value.id}"']`);
    // let audienceSizeRange = document.getElementById("estimated-audience-size");
    // Set audience estimation
    // audienceSizeRange.innerHTML = "Estimated Audience Size: " + value["audience_size_lower_bound"] + " - " + value["audience_size_upper_bound"];
  }
}