import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ "modal" ]
  connect() {
    console.log("Connected to Rule Templates");
  }

  showBudgetField(e){
    let budgetField = document.getElementById("max-daily-cap");
    let actionAmount = document.getElementById("action-amount");
    let maxBudgetCap = document.getElementById("max-budget-cap");
    let capLabel = document.getElementById("cap-label");
    if (e.target.value != "turn_off"){
      budgetField.classList.remove("hidden");
      actionAmount.required = true;
      maxBudgetCap.required = true;
      // Change label name when value is decrease budget
      if (e.target.value == "decrease_budget"){
        capLabel.innerHTML = "Minimum daily budget floor";
      } else {
        capLabel.innerHTML = "Max. daily budget cap";
      }
    } else {
      budgetField.classList.add("hidden");
      actionAmount.required = false;
      maxBudgetCap.required = false;
    }
  }

  setLogicalOperatorValue(e){
    let hiddenField = document.getElementById("rule-group-operator");
    hiddenField.value = e.target.value;
  }

  displaySchedule(e){
    let scheduleFields = document.querySelectorAll(".disabled-fields-" + e.target.dataset.scheduleIndex);
    let scheduleButton = document.querySelectorAll(".disabled-button-" + e.target.dataset.scheduleIndex);
    if(e.target.checked){
      scheduleFields.forEach((el) => el.disabled = false);
      scheduleButton.forEach((el) => {
        el.classList.remove("btn-primary-disabled");
        el.classList.add("btn-tertiary");
      })
    } else {
      scheduleFields.forEach((el) => el.disabled = true);
      scheduleButton.forEach((el) => {
        el.classList.remove("btn-tertiary");
        el.classList.add("btn-primary-disabled");
      })
    }
  }

  updateCustomSchedule(e){
    let modalTarget = this.modalTarget;
    let bgModal = document.getElementById("modal-background");
    let ruleSchedule = document.querySelectorAll(".rule-schedule");
    // Remove hidden time range selection
    let timeRangeSelection = document.getElementById("time-range-selection");
    timeRangeSelection.classList.remove("hidden");
    ruleSchedule.forEach((el) => {
      if (el.querySelector('.day-of-weeks-checkbox').checked){
        let timeRange = el.querySelectorAll(".rule-time-range");
        timeRange.forEach((tr) => {
          // Convert time from 24 hours format to AM/PM format
          // Display time range chosen
          let timeConversion = (time)  => this.tConvert(time);
          let elem = "<p class='p3 ml-7 my-2 tracking-wide'>" + el.querySelector('.day-of-weeks-checkbox').value[0].toUpperCase() + el.querySelector('.day-of-weeks-checkbox').value.slice(1).toLowerCase() + " " + timeConversion(tr.querySelector('.schedule-start-time').value) + " - " + timeConversion(tr.querySelector('.schedule-end-time').value) + "</p>"
          timeRangeSelection.innerHTML += elem;
        })
      }
    })
  }

  // Time conversion to display on custom schedule
  tConvert(time){
    // Check correct time format and split into components
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join (''); // return adjusted time or original string
  }
}