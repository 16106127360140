import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Im connected to review page");
    this.upReviewPage();
  }

  // below function for review page ( upReviewPage, isValidUrl, pageReviewValid )
  upReviewPage(e) {
    let el_reviewpage = document.querySelectorAll('[reviewpage]');
    let review_page = document.getElementById("review_page");
    if (review_page && el_reviewpage) {
      let invalid_el_group = {};
      let invalid_review_page = [];
      let duplicate_check = true;
      review_page.innerHTML = "";
      for (let el in el_reviewpage) {
        let review_page_info = el_reviewpage[el].id && el_reviewpage[el].getAttribute && el_reviewpage[el].getAttribute("reviewpage") ? JSON.parse(el_reviewpage[el].getAttribute("reviewpage")) : null;
        if (el_reviewpage[el].id && review_page_info) {
          // process validate
          let is_valid = true;
          is_valid = this.pageReviewValid(el_reviewpage[el], review_page_info, is_valid);
          // Form Info
          // styling page view form
          let el_group = document.getElementById(review_page_info["group"])
          let custom_html = "";
          let el_div = document.createElement('div');
          let title_field = "";
          if (review_page_info["group"]) {
            // element id only for group data ( headlines, description, etc )
            if (el_group) {
              // if element id exist 
              el_div = el_group;
            } else {
              // if not exist then set new id
              el_div.id = review_page_info["group"];
              custom_html += `<h5>${review_page_info["group"]}</h5>`;
            }
            title_field = review_page_info["group"];
          } else {
            el_div.id = review_page_info["name"];
            custom_html += `<h5>${review_page_info["name"]}</h5>`;
            title_field = review_page_info["name"];
          }
          el_div.classList.add("mb-5");
  
          // apply validation
          if (is_valid) {
            if (review_page_info["tick"]) {
              // when have set tick variable
              let label_for = document.querySelector(`label[for="${el_reviewpage[el].id}"]`);
              if (label_for && el_reviewpage[el].checked) {
                // now this function run on google adset -> Network
                let label_text = (label_for.getElementsByTagName("span") && label_for.getElementsByTagName("span")[0]) ? label_for.getElementsByTagName("span")[0].textContent : false;    
                if (label_text) custom_html += `<span>${label_text}</span>`;
              } else {
                // now this function run on google campaign -> Goals
                let label_for_goal = document.querySelector(`label[for="${el_reviewpage[el].value}"]`);
                if (label_for_goal && el_reviewpage[el].checked) {
                  let label_text_goal = (label_for_goal.getElementsByTagName("span") && label_for_goal.getElementsByTagName("span")[0]) ? label_for_goal.getElementsByTagName("span")[0].textContent : false;
                  if (label_text_goal) custom_html += `<span>${label_text_goal.replaceAll(" (Account Default)","")}</span>`;
                }
              }
            } else if (review_page_info["radio"]) {
              // when have set radio variable
              if(el_reviewpage[el].checked) {
                custom_html += `<span>${this.capitalizeFirstLetter(this.customString(el_reviewpage[el].value, review_page_info["title"]))}</span>`;
              }
            } else if (el_group) {
              custom_html += (el_reviewpage[el].value) ? `<span>, ${el_reviewpage[el].value}</span>` : `<span>${el_reviewpage[el].value}</span>`;
            } else if (review_page_info["selection"]) {
              // when have set "selection" variable
              if (el_reviewpage[el].childNodes) {
                for (let childnode in el_reviewpage[el].childNodes) {
                  // only get span or div element
                  if(!isNaN(childnode)){
                    if (el_reviewpage[el].childNodes[childnode].childNodes && el_reviewpage[el].childNodes[childnode].childNodes[0]) {
                      let selected_str = el_reviewpage[el].childNodes[childnode].childNodes[0].textContent
                      if (selected_str) {
                        // for all using reviewpage "selection"
                        custom_html += (selected_str && childnode != 0) ? `<span>, ${selected_str}</span>` : `<span>${selected_str}</span>`;
                      } else {
                        // for youtube list
                        if (el_reviewpage[el].childNodes[childnode] && el_reviewpage[el].childNodes[childnode].getElementsByTagName("span") && el_reviewpage[el].childNodes[childnode].getElementsByTagName("span")[0]) custom_html += (childnode != 0) ? `<span>, ${el_reviewpage[el].childNodes[childnode].getElementsByTagName("span")[0].textContent}</span>` : `<span>${el_reviewpage[el].childNodes[childnode].getElementsByTagName("span")[0].textContent}</span>`
                      }
                    }
                  }
                }
              }
            } else {
              if (review_page_info["selectbox"]) {
                // when have set selectbox variable
                // get text from element ( for selectbox key only )
                // if selectbox not empty then add custom_html
                if(el_reviewpage[el].selectedIndex !== -1) custom_html += `<span>${el_reviewpage[el].options[el_reviewpage[el].selectedIndex].text}</span>`;
              } else {
                if (review_page_info["textarea"]) {
                  // when have set textarea variable
                  custom_html += `<span>${el_reviewpage[el].value.replaceAll("\n",", ")}</span>`;
                } else {
                  // get value from element
                  custom_html += `<span>${el_reviewpage[el].value}</span>`;
                }
              }
            }
          } else {
            // below for display error for non el group
            let text_info = (el_reviewpage[el].value) ? el_reviewpage[el].value : `Please include ${title_field}`
            custom_html += `<span class="text-signal-red">${text_info}</span>`;
            // record down invalid el group -> for display error message el group
            if (review_page_info["group"] && invalid_el_group[review_page_info["group"]] == undefined) {
              invalid_el_group[review_page_info["group"]] = {"id": el_div, "msg": `<h5>${review_page_info["group"]}</h5> <span class="text-signal-red">${text_info}</span>`, "el": el_reviewpage[el], "reviewpage": review_page_info};
            }
            invalid_review_page.push(el_div.id);
          }
          // custom html append to pagereview form
          el_div.innerHTML += custom_html;
          review_page.appendChild(el_div)

          // re update review page form status for element tick
          if (review_page_info["tick"]) {
            let tick_el = document.getElementById(review_page_info["group"]);
            if (tick_el.getElementsByTagName("span").length > 1) {
              tick_el.innerHTML = tick_el.innerHTML.replaceAll("</span><span>","</span><span>, ").replaceAll(", , ",", ")
            }
          }
          if (review_page_info["group"] == "Gender") {
            // for facebook gender adset
            let tick_el = document.getElementById(review_page_info["group"]);
            tick_el.innerHTML = tick_el.innerHTML.replaceAll("0","All").replaceAll("1","Men").replaceAll("2","Women")
          }
          if (review_page_info["group"] == "Age") {
            // for facebook age adset
            let tick_el = document.getElementById(review_page_info["group"]);
            tick_el.innerHTML = tick_el.innerHTML.replaceAll(", "," - ")
          }

          // to not process check Headlines and Descriptions -> prevent cannot submit on Campaign type Display ( UDA )
          if (review_page_info["no_duplicate_check"]) duplicate_check = false;
        }
      }

      // only for ads page -> check duplicate value on headlines and description
      if (duplicate_check && (invalid_el_group["Descriptions"] === undefined || invalid_el_group["Headlines"] === undefined || invalid_el_group["Long Headlines"] === undefined)) {
        let Headlines = document.getElementById("Headlines");
        let Descriptions = document.getElementById("Descriptions");
        let LongHeadlines = document.getElementById("Long Headlines");
        if (Headlines && this.isDuplicateSpanValue(Headlines)) {
          Headlines.innerHTML = `<h5>Headlines</h5> <span class="text-signal-red">Headlines are identical. Enter a different headline.</span>`
          invalid_review_page.push(Headlines.id)
        }
        if (Descriptions && this.isDuplicateSpanValue(Descriptions)) {
          Descriptions.innerHTML = `<h5>Descriptions</h5> <span class="text-signal-red">Descriptions are identical. Enter a different description.</span>`
          invalid_review_page.push(Descriptions.id)
        }
        if (LongHeadlines && this.isDuplicateSpanValue(LongHeadlines)) {
          LongHeadlines.innerHTML = `<h5>Long Headlines</h5> <span class="text-signal-red">Long Headlines are identical. Enter a different long headline.</span>`
          invalid_review_page.push(LongHeadlines.id)
        }
      }
  
      // re update review page form status for element group
      for (let invalid_el in invalid_el_group) {
        // for image and logo
        if (invalid_el_group[invalid_el]["reviewpage"] && invalid_el_group[invalid_el]["reviewpage"]["dimension"]) {
          let image_valid = this.reviewPageImageValidation(invalid_el_group[invalid_el]["el"], invalid_el_group[invalid_el]["reviewpage"]);
          if (image_valid) {
            // remove invalid_review_page value item if image or logo is valid
            invalid_review_page = invalid_review_page.filter(e => e !== invalid_el_group[invalid_el]["id"].id);
            // remove title and text on review page 
            invalid_el_group[invalid_el]["id"].remove();
          } else {
            invalid_el_group[invalid_el]["id"].innerHTML = invalid_el_group[invalid_el]["msg"];
          }
        } else {
          // for all using el group
          invalid_el_group[invalid_el]["id"].innerHTML = invalid_el_group[invalid_el]["msg"];
        }
      }

      // check if saved audience is used -> swap out data if true
      let check_saved_audience = document.getElementById("audience_saved_audience")
      let native_saved_audience = document.getElementById("audience_native_saved_audience")
      if (check_saved_audience && check_saved_audience.checked) {
        this.switchOutAudienceData();
        // use saved audience - Facebook Adset
        let location_data = document.querySelector("#save-audience-location > b") ? document.querySelector("#save-audience-location > b").textContent : false;
        if (location_data) {
          // remove invalid for include location
          invalid_review_page = invalid_review_page.filter(function(x) { return x != "Include Location"; });
        }
      } else if (native_saved_audience && native_saved_audience.checked) {
        this.switchOutNativeAudienceData();
        let location_data = document.querySelector("#save-native-audience-location > b")? document.querySelector('#save-native-audience-location > b').textContent : false;
        if (location_data){
          invalid_review_page = invalid_review_page.filter(function(x) {return x != "Include Location";});
        }
      } else {
        // unuse saved audience - Facebook Adset
        let saved_audience_name_tag = document.querySelector("#review_page #Use\\ Saved\\ Audience")
        if (saved_audience_name_tag && saved_audience_name_tag.getElementsByTagName("span") && saved_audience_name_tag.getElementsByTagName("span")[0] && this.containsOnlyNumbers(saved_audience_name_tag.getElementsByTagName("span")[0].textContent)) {
          saved_audience_name_tag.getElementsByTagName("span")[0].innerHTML = "";
        }
      }

      let elements = document.querySelector("input[type=submit]");
      let btn_submit = document.getElementById('gotoreviewtab');
      // remove styling class for submit button
      if (elements.classList.contains("btn-primary-signal-red")) elements.classList.remove("btn-primary-signal-red");
      if (elements.classList.contains("btn-primary-signal-green")) elements.classList.remove("btn-primary-signal-green");
      if (elements.classList.contains("btn-primary-brand")) elements.classList.remove("btn-primary-brand");
      if (elements.classList.contains("hidden")) elements.classList.remove("hidden");

      if (btn_submit.classList.contains("btn-primary-signal-red")) btn_submit.classList.remove("btn-primary-signal-red");
      if (btn_submit.classList.contains("btn-primary-signal-green")) btn_submit.classList.remove("btn-primary-signal-green");
      if (btn_submit.classList.contains("btn-primary-brand")) btn_submit.classList.remove("btn-primary-brand");
      if (btn_submit.classList.contains("hidden")) btn_submit.classList.remove("hidden");

      // validation submit button - review page 
      if (invalid_review_page.length > 0 && elements) {
        elements.disabled = true
        elements.classList.add("btn-primary-signal-red");
        elements.classList.add("hidden");
        btn_submit.classList.add("btn-primary-signal-red");
      } else {
        if (elements.value == "Next") {
          elements.classList.add("btn-primary-brand");
          btn_submit.classList.add("btn-primary-brand");
          btn_submit.classList.add("hidden");
        } else {
          elements.classList.add("btn-primary-signal-green");
          btn_submit.classList.add("btn-primary-signal-green");
          btn_submit.classList.add("hidden");
        }
        elements.disabled = false
      }
    }
  }

  isValidUrl(str) {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  }

  reviewPageImageValidation(el, reviewpage_obj) {
    let count_validation = 0;
    for (let dimension in reviewpage_obj["dimension"]) {
      if (el.innerHTML.includes(reviewpage_obj["dimension"][dimension])) count_validation += 1;      
    }
    if (reviewpage_obj["dimension"].length == 0) {
      if (el.innerHTML.includes("img")) return true;
    } else if (count_validation == reviewpage_obj["dimension"].length) {
      return true;
    } 
    return false;
  }

  pageReviewValid(element, review_page_info, is_valid) {
    if (is_valid) {
      // validation url
      if (review_page_info["validate"] && review_page_info["validate"] == "url") {
        return (!this.isValidUrl(element.value)) ? false : true;
      } else if (review_page_info["validate"] && review_page_info["validate"] == "include_location") {
        return element.childNodes.length !== 0;
      } else if (review_page_info["validate"] && (review_page_info["validate"] == "image" || review_page_info["validate"] == "logo")) {
        // return not valid, check validation image and logo will be end of function "upReviewPage()"
        return false;
      } else {
        // check default component element validation
        if (element.required == true && element.value) {
          return true;
        } else if (element.required == false || element.required == undefined) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  capitalizeFirstLetter(string) {
    string = string.replaceAll("_", " ").toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  customString(base, custom = false) {
    if (custom) {
      return custom;
    } else {
      return base;
    }
  }

  // use on check headline and description
  isDuplicateSpanValue(el) {
    if (el.getElementsByTagName("span")) {
      let arr = [];
      for (let s in el.getElementsByTagName("span")) {
        // only get span data
        if(!isNaN(s)) {
          if (el.getElementsByTagName("span")[s].textContent.replaceAll(", ","").trim() != "") {
            arr.push(el.getElementsByTagName("span")[s].textContent.replaceAll(", ","").trim())
          }
        }
      }
      let tofindDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
      const duplicateElements = tofindDuplicates(arr);
      return (duplicateElements.length) > 0 ? true : false
    }
    return false
  }

  switchOutAudienceData() {
    let location_type_tag = document.querySelector("#review_page #Location\\ Type");
    let location_tag = document.querySelector("#review_page #Include\\ Location");
    let exclude_location_tag = document.querySelector("#review_page #Exclude\\ Location");
    let age_tag = document.querySelector("#review_page #Age");
    let gender_tag = document.querySelector("#review_page #Gender");
    let detailed_tag = document.querySelector("#review_page #Detailed\\ Targeting");
    let language_tag = document.querySelector("#review_page #Languages");
    let saved_audience_name_tag = document.querySelector("#review_page #Use\\ Saved\\ Audience")

    let location_type_data = document.querySelector("#save-audience-location-type > b") ? document.querySelector("#save-audience-location-type > b").textContent : "";
    let location_data = document.querySelector("#save-audience-location > b") ? document.querySelector("#save-audience-location > b").textContent : "";
    let exclude_location_data = document.querySelector("#save-audience-exclude-location > b") ? document.querySelector("#save-audience-exclude-location > b").textContent : "";
    let age_data = document.querySelector("#save-audience-age > b") ? document.querySelector("#save-audience-age > b").textContent : "";
    let gender_data = document.querySelector("#save-audience-gender > b") ? document.querySelector("#save-audience-gender > b").textContent : "";
    let detailed_targeting_data = document.querySelector("#save-audience-detailed-targeting > b") ? document.querySelector("#save-audience-detailed-targeting > b").textContent : "";
    let languages_data = document.querySelector("#save-audience-languages > b") ? document.querySelector("#save-audience-languages > b").textContent : "";
    let saved_audience_name_data = document.querySelector("#save-audience-name > b") ? document.querySelector("#save-audience-name > b").textContent : "";
    
    location_type_tag.innerHTML = `<h5>${location_type_tag.id}</h5><span>${location_type_data}</span>`;
    location_tag.innerHTML = `<h5>${location_tag.id}</h5><span>${location_data}</span>`;
    exclude_location_tag.innerHTML = `<h5>${exclude_location_tag.id}</h5><span>${exclude_location_data}</span>`;
    age_tag.innerHTML = `<h5>${age_tag.id}</h5><span>${age_data}</span>`;
    gender_tag.innerHTML = `<h5>${gender_tag.id}</h5><span>${gender_data}</span>`;
    detailed_tag.innerHTML = `<h5>${detailed_tag.id}</h5><span>${detailed_targeting_data}</span>`;
    language_tag.innerHTML = `<h5>${language_tag.id}</h5><span>${languages_data}</span>`;
    saved_audience_name_tag.innerHTML = `<h5>${saved_audience_name_tag.id}</h5><span>${saved_audience_name_data}</span>`;
  }

  switchOutNativeAudienceData(){
    let location_type_tag = document.querySelector("#review_page #Location\\ Type");
    let location_tag = document.querySelector("#review_page #Include\\ Location");
    let exclude_location_tag = document.querySelector("#review_page #Exclude\\ Location");
    let age_tag = document.querySelector("#review_page #Age");
    let gender_tag = document.querySelector("#review_page #Gender");
    let detailed_tag = document.querySelector("#review_page #Detailed\\ Targeting");
    let saved_audience_name_tag = document.querySelector("#review_page #Use\\ Saved\\ Audience")

    let location_type_data = document.querySelector("#save-native-audience-location-type > b") ? document.querySelector("#save-native-audience-location-type > b").textContent : "";
    let location_data = document.querySelector("#save-native-audience-location > b") ? document.querySelector("#save-native-audience-location > b").textContent : "";
    let exclude_location_data = document.querySelector("#save-native-audience-exclude-location > b") ? document.querySelector("#save-native-audience-exclude-location > b").textContent : "";
    let age_data = document.querySelector("#save-native-audience-age > b") ? document.querySelector("#save-native-audience-age > b").textContent : "";
    let gender_data = document.querySelector("#save-native-audience-gender > b") ? document.querySelector("#save-native-audience-gender > b").textContent : "";
    let detailed_targeting_data = document.querySelector("#save-native-audience-detailed-targeting > b") ? document.querySelector("#save-native-audience-detailed-targeting > b").textContent : "";
    let saved_audience_name_data = document.querySelector("#save-native-audience-name > b") ? document.querySelector("#save-native-audience-name > b").textContent : "";
    
    location_type_tag.innerHTML = `<h5>${location_type_tag.id}</h5><span>${location_type_data}</span>`;
    location_tag.innerHTML = `<h5>${location_tag.id}</h5><span>${location_data}</span>`;
    exclude_location_tag.innerHTML = `<h5>${exclude_location_tag.id}</h5><span>${exclude_location_data}</span>`;
    age_tag.innerHTML = `<h5>${age_tag.id}</h5><span>${age_data}</span>`;
    gender_tag.innerHTML = `<h5>${gender_tag.id}</h5><span>${gender_data}</span>`;
    detailed_tag.innerHTML = `<h5>${detailed_tag.id}</h5><span>${detailed_targeting_data}</span>`;
    saved_audience_name_tag.innerHTML = `<h5>${saved_audience_name_tag.id}</h5><span>${saved_audience_name_data}</span>`;
  }

  containsOnlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }

  goToReviewTab(e) {
    document.getElementById('reviewtabview').click();
  }
}