import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ "output", "input" ]
  connect() {
    console.log("Im connected to preview");

  }

  previewClientLogo() {
    let input = this.inputTarget;
    let output = this.outputTarget;
    if (input.files && input.files[0]) {
      let reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result;
      }

      reader.readAsDataURL(input.files[0]);
    }
  }
}