import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to adset", this.element);
    if (this.element.id == "google_adset") {
      this.displayBidStrategyTargetOptional();
      this.onChangeBidStrategy();
      this.checkLinkAccount();
      this.smartTargetLocation();
    } else {
      // Initialize bid strategy for optimization
      let bidStrategy;
      let optimizationGoal = document.getElementById("adset_optimization_goal");
      let costPerResultGoal = document.getElementById("cost-per-result-goal");
      let bidControl = document.getElementById("bid-control");
      if(optimizationGoal.value === 'ad_recall_lift' || optimizationGoal.value === 'link_clicks' || optimizationGoal.value === 'landing_page_views' || optimizationGoal.value === 'thruplay' || optimizationGoal.value === 'app_installs' || optimizationGoal.value === 'lead_generation' || optimizationGoal.value === 'quality_lead') {
        if (costPerResultGoal) costPerResultGoal.classList.remove("hidden");
        if (costPerResultGoal) costPerResultGoal.querySelector("input[type=hidden]").disabled = false;
        if (bidControl) bidControl.querySelector("input[type=hidden]").disabled = true;
      } else if(optimizationGoal.value === 'reach' || optimizationGoal.value === 'impressions' || optimizationGoal.value === 'offsite_conversions' || optimizationGoal.value === 'post_engagement' || optimizationGoal.value === 'conversations'){
        if (bidControl) bidControl.classList.remove("hidden");
        if (bidControl) bidControl.querySelector("input[type=hidden]").disabled = false;
        if (costPerResultGoal) costPerResultGoal.querySelector("input[type=hidden]").disabled = true;
      }
      this.updateFBAdsetStartEndTime();
      this.checkBudgetType();
      this.showBidStrategyConversion();
      this.retargetAdsDisplay();
      this.changeOptimizationText();
      this.onChangePixelCustomConversion();
    }
  }

  displayEndDate(e) {
    let form = document.getElementById("end-date");
    if(e.target.checked){
      form.classList.remove("hidden");
    } else {
      form.classList.add("hidden");
    }
  }

  displayLifetimeBudget(e) {
    let endDateLabel = document.getElementById("end-date-label");
    let endDateCheckbox = document.getElementById("end-date-checkbox");
    let endDate = document.getElementById("end-date");
    if(e.target.value == "lifetime_budget"){
      endDateLabel.innerHTML = "End Date";
      endDateCheckbox.classList.add("hidden");
      endDate.classList.remove("hidden");
    } else {
      endDateLabel.innerHTML = "End Date (Optional)";
      endDateCheckbox.classList.remove("hidden");
      endDate.classList.add("hidden");
    }
    this.checkBudgetType();
  }

  checkBudgetType(e) {
    let budget_type = document.getElementById("adset_budget_type");
    let end_time_field = document.getElementById("adset_end_time");
    if(budget_type.value) {
      if(budget_type.value == "lifetime_budget") {
        end_time_field.required = true;
      } else {
        end_time_field.required = false;
      }
    }
  }

  displayBidOptions(e) {
    let form = document.getElementById("cost-per-result-goal");
    if(e.target.checked){
      form.classList.remove("hidden");
    } else {
      form.classList.add("hidden");
    }
  }

  displayOptimizationOptions(e) {
    let showMoreOptions = document.getElementById("show-more-options");
    let billingForm = document.getElementById("billing-form");
    if(billingForm.classList.contains('hidden')){
      billingForm.classList.remove("hidden");
      showMoreOptions.classList.add("hidden");
    } else {
      billingForm.classList.add("hidden");
      showMoreOptions.classList.remove("hidden");
    }
  }

    showAudienceForm(e) {
    let createAudience = document.getElementById("create-audience");
    let saveAudience = document.getElementById("save-audience");
    let nativeAudience = document.getElementById("save-native-audience");
    if (e.target.value === "saved_audience") {
      createAudience.classList.add("hidden");
      nativeAudience.classList.add("hidden");
      saveAudience.classList.remove("hidden");
    } else if (e.target.value === "native_saved_audience") {
      nativeAudience.classList.remove("hidden");
      saveAudience.classList.add("hidden");
      createAudience.classList.add("hidden");
    } else {
      createAudience.classList.remove("hidden");
      nativeAudience.classList.add("hidden");
      saveAudience.classList.add("hidden");
    }
  }

  editAudienceSettings(e){
    let settingsSummary = document.getElementById("settings-summary");
    let settingsForm = document.getElementById("settings-forms");
    settingsSummary.classList.add("hidden");
    settingsForm.classList.remove("hidden");
  }

  showTargetingChoices(e) {
    e.preventDefault();
    let targetingDropdown = document.getElementById("targeting-dropdown");
    let excludedTargetingDropdown = document.getElementById("excluded-targeting-dropdown");
    if (e.target.id === "exclude_browse_button"){
      if (excludedTargetingDropdown.classList.contains('hidden')) {
        excludedTargetingDropdown.classList.remove("hidden");
      } else {
        excludedTargetingDropdown.classList.add("hidden");
      }
    }else{
      if (targetingDropdown.classList.contains('hidden')) {
        targetingDropdown.classList.remove("hidden");
      } else {
        targetingDropdown.classList.add("hidden");
      }
    } 
  }

  hideTargetingChoices(e) {
    let targetingDropdown = document.getElementById("targeting-dropdown");
    let excludedTargetingDropdown = document.getElementById("excluded-targeting-dropdown");
    if(e.target.id === "exclude_browse_button"){
      if (!this.element.contains(e.target) && !excludedTargetingDropdown.classList.contains('hidden')) {
        excludedTargetingDropdown.classList.add("hidden");
      }
    }
    if (!this.element.contains(e.target) && !targetingDropdown.classList.contains('hidden')) {
      targetingDropdown.classList.add("hidden");
    }
  }

  getReachEstimation(e){
    // Get Age data
    let minAge = document.getElementById("min_age");
    let maxAge = document.getElementById("max_age");
    // Get gender data
    let gender = document.querySelector('input[name="gender"]:checked');
    let placement = document.querySelector('input[name="placement"]:checked').value;
    let include_location = [...document.getElementById('search-location-results').children].map(element => JSON.parse(element.querySelector("input").value).key);
    let exclude_location = [...document.getElementById('search-exclude-location-results').children].map(element => JSON.parse(element.querySelector("input").value).key);
    let locale = [...document.getElementById('search-locale-results').children].map(element => JSON.parse(element.querySelector("input").value).key);

    // Get Detailed Targeting
    let interests = [...document.getElementById('search-results').children].map(element => JSON.parse(element.querySelector("input").value).id);
    // Get Narrow Further
    let flexible_spec = [];
    for (let i = 0; i < 11; i++) {
      let detailed_targeting = document.getElementById(`search-results${i}`);
      if (detailed_targeting) {
        let flex_interests = [...detailed_targeting.children].map(element => JSON.parse(element.querySelector("input").value).id);
        if (flex_interests.length > 0) {
          flexible_spec.push({ interests: flex_interests })
        }
      }
    }
    let interests_arr;
    if (flexible_spec.length > 0) {
      // push array to first key
      flexible_spec.unshift({"interests": interests})
      interests_arr = `&flexible_spec=${JSON.stringify(flexible_spec)}`;
    } else {
      interests_arr = `&interests=${interests}`
    }

    // // Get Exclude Detailed Targeting
    // let exclude_interests = [...document.getElementById('exclude-search-results').children].map(element => JSON.parse(element.querySelector("input").value).id);
    // let exclusions = [];
    // for(let i = 0; i < 11; i++){
    //   let exclude_detailed_targeting = document.getElementById(`exclude-search-results${i}`);
    //   if (exclude_detailed_targeting){
    //     let exclude_interest = [...exclude_detailed_targeting.children].map(element=> JSON.parse(element.querySelector("input").value).id);
    //     if (exclude_interest.length>0){
    //       exclusions.push({exclude_interests:exclude_interest})
    //     }
    //   }
    // }
    
    // let exclude_interest_arr;
    // if(exclusions.length >0){
    //   exclude_interest_arr.unshift({"interests": exclude_interests})
    //   exclude_interest_arr = `&exclusions=${JSON.stringify(exclusions)}`;
    // } else{
    //   exclude_interest_arr = `&interests=${exclude_interests}`
    // }
    
    // Get Exclude Custom Audience
    let exclude_custom_audience = document.getElementById('excluded_custom_audiences')
    let selected_exclude_custom_audience = [];
    if (exclude_custom_audience) {  
      for (let id_custom_audience of exclude_custom_audience.options) {
        if (id_custom_audience.selected) {
          selected_exclude_custom_audience.push(id_custom_audience.value);
        }
      }
    }
    // Get Custom Audience
    let custom_audience = document.getElementById('custom_audiences')
    let selected_custom_audience = [];
    if (custom_audience) {
      for (let id_custom_audience of custom_audience.options) {
        if (id_custom_audience.selected) {
          selected_custom_audience.push(id_custom_audience.value);
        }
      }
    }
    
    // Send data to adapter for reach estimates returns
    Rails.ajax({
      url: '/facebook/get_reach_estimates',
      type: 'GET',
      data: "min_age=" + minAge.value + "&max_age=" + maxAge.value + "&gender=" + gender.value + "&placement=" + placement + "&geo_locations=" + include_location + "&excluded_geo_locations=" + exclude_location + "&locales=" + locale + "&custom_audiences=" + selected_custom_audience + "&excluded_custom_audiences=" + selected_exclude_custom_audience + interests_arr,
      success: function(data) {
        let reachRange = document.getElementById("reach-range");
        let reachProgressBar = document.getElementById("reach-progress-bar");
        // Only change the range value if both range are available
        let audienceSizeRange = document.getElementById("estimated-audience-size");
        if ((data.users_lower_bound) && (data.users_upper_bound)) {
          reachRange.innerHTML = data.users_lower_bound.toLocaleString() + " - " + data.users_upper_bound.toLocaleString()
          reachProgressBar.style.width = Math.round((data.users_lower_bound / data.users_upper_bound) * 100) + "%"
          // Set audience estimation
          audienceSizeRange.innerHTML = "Estimated Audience Size: " + data.users_lower_bound.toLocaleString()  + " - " + data.users_upper_bound.toLocaleString()
        }
      }
    });
  }

  showBidStrategy(e){
    let costPerResultGoal = document.getElementById("cost-per-result-goal");
    let bidControl = document.getElementById("bid-control");
    let bidStrategy;
    if(e.target.value === 'ad_recall_lift' || e.target.value === 'link_clicks' || e.target.value === 'landing_page_views' || e.target.value === 'thruplay' || e.target.value === 'app_installs' || e.target.value === 'lead_generation' || e.target.value === 'two_second_continuous_video_views') {
      costPerResultGoal.classList.remove("hidden");
      bidControl.classList.add("hidden");
      costPerResultGoal.querySelector("input[type=hidden]").disabled = false;
      bidControl.querySelector("input[type=hidden]").disabled = true;
    } else if(e.target.value === 'reach' || e.target.value === 'impressions' || e.target.value === 'offsite_conversions' || e.target.value === 'post_engagement' || e.target.value === 'conversations'){
      bidControl.classList.remove("hidden");
      costPerResultGoal.classList.add("hidden");
      bidControl.querySelector("input[type=hidden]").disabled = false;
      costPerResultGoal.querySelector("input[type=hidden]").disabled = true;
    }
    // Change form bid statement
    let displayBidStatement = (optimizationGoal)  => this.changeBidStatement(optimizationGoal);
    displayBidStatement(e.target.value);
  }

  changeBidStatement(optimizationGoal){
    let bidStatement = document.getElementById("bid-statement");
    switch(optimizationGoal){
      case "ad_recall_lift":
        bidStatement.innerHTML = "Meta will aim to spend your entire budget and get the most ad recall using the highest volume bid strategy."
        break;
      case "offsite_conversions":
        bidStatement.innerHTML = "Meta will aim to spend your entire budget and get the most results using the highest volume bid strategy."
        break;
      case "conversations":
        bidStatement.innerHTML = "Meta will aim to spend your entire budget and get the most results using the highest volume bid strategy."
        break;
      case "post_engagement":
        bidStatement.innerHTML = "Meta will aim to get the most post engagements without bidding more than bid control amount in any auction using the bid cap bid strategy."
        break;
      case "link_clicks":
        bidStatement.innerHTML = "Meta will aim to spend your entire budget and get the most link clicks using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal."
        break;
      case "reach":
        bidStatement.innerHTML = "Meta will aim to get the most 1,000 impressions without bidding more than bid control amount in any auction using the bid cap bid strategy."
        break;
      case "thruplay":
        bidStatement.innerHTML = "Meta will aim to spend your entire budget and get the most ThruPlays using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal."
        break;
      default:
        bidStatement.innerHTML = "Meta will aim to spend your entire budget and get the most results using the highest volume bid strategy."
    }
  }

  showMoreOption() {
    let arrow_down = document.getElementById("arrow-down-option");
    let arrow_up = document.getElementById("arrow-up-option");
    let more_option = document.getElementById("more-option");
    
    if(arrow_down && arrow_down.classList.contains("hidden")) {
      arrow_down.classList.remove("hidden");
      arrow_up.classList.add("hidden");
      more_option.classList.add("hidden");
    } else {
      arrow_down.classList.add("hidden");
      arrow_up.classList.remove("hidden");
      more_option.classList.remove("hidden");
    }
  }

  displayAdsSchedule() {
    let ads_schedule = document.getElementById("ads-schedule");
    let run_ads_schedule = document.getElementById("run-ads-schedule");
    
    if(run_ads_schedule.checked == true) {
      ads_schedule.classList.remove("hidden");
    } else {
      ads_schedule.classList.add("hidden");
    }
  }

  displayBidStrategyTargetOptional() {
    let bid_strategy_target_value = document.getElementById("bid-strategy-target-value");
    let bid_strategy_target = document.getElementById("bid-strategy-target");
    let adset_bid_amount = document.getElementById("adset_bid_amount");
    
    if (bid_strategy_target_value) {
      if(bid_strategy_target.checked == true) {
        bid_strategy_target_value.classList.remove("hidden");
        adset_bid_amount.required = true
      } else {
        bid_strategy_target_value.classList.add("hidden");
        adset_bid_amount.required = false
      }
    }
  }

  onChangeBidStrategy() {
    if (document.getElementById("adset_bid_strategy")) {
      let bid_strategy_value = document.getElementById("adset_bid_strategy").value;
      let label_bid_strategy = document.getElementById("label-bid-strategy-type");
      let text_checkbox_bid_strategy = document.getElementById("text-checkbox-bid-amount");
      let bid_target_impression_location = document.getElementById("bid-target-impression-location");
      let adset_bid_target_impression_share = document.getElementById("adset_bid_target_impression_share");
      let adset_bid_amount = document.getElementById("adset_bid_amount");
      let bid_strategy_target = document.getElementById("bid-strategy-target");
      let div_bid_strategy_optional = document.getElementById("bid-strategy-optional");
      let div_bid_enhanced_cpc_enabled = document.getElementById("bid-enhanced-cpc-enabled");
      
      if (!bid_target_impression_location.classList.contains("hidden")) {
        bid_target_impression_location.classList.add("hidden");
        adset_bid_target_impression_share.classList.add("hidden");
        adset_bid_target_impression_share.required = false;
      }
  
      if (div_bid_strategy_optional.classList.contains("hidden")) div_bid_strategy_optional.classList.remove("hidden");
      if (bid_strategy_target.classList.contains("hidden")) bid_strategy_target.classList.remove("hidden");
      if (label_bid_strategy.classList.contains("hidden") || text_checkbox_bid_strategy.classList.contains("hidden")) {
        label_bid_strategy.classList.remove("hidden");
        text_checkbox_bid_strategy.classList.remove("hidden");
        bid_strategy_target.classList.remove("hidden");
      }
      if (!div_bid_enhanced_cpc_enabled.classList.contains("hidden")) div_bid_enhanced_cpc_enabled.classList.add("hidden");
      
      // bid_strategy for target_impression_share haven't implement it
      switch(bid_strategy_value) {
        case "maximize_conversions":
          label_bid_strategy.innerHTML = "Target Cost per Action (Optional)";
          text_checkbox_bid_strategy.textContent = "Set A Target CPA";
          adset_bid_amount.placeholder = "SGD";
          break;
        case "maximize_conversion_value":
          label_bid_strategy.innerHTML = "Target Return on Ad Spend (Optional)";
          text_checkbox_bid_strategy.textContent = "Target Return on Ad Spend (Optional)";
          adset_bid_amount.placeholder = "%";
          break;
        case "target_spend":
          label_bid_strategy.innerHTML = "Cost per Click Bid Limit";
          text_checkbox_bid_strategy.textContent = "Set a Maximun CPC Bid Limit";
          adset_bid_amount.placeholder = "SGD";
          break;
        case "target_impression_share":
          let bid_strategy_target_value = document.getElementById("bid-strategy-target-value");
          if (bid_strategy_target_value.classList.contains("hidden")) bid_strategy_target_value.classList.remove("hidden");
          if (!bid_strategy_target.classList.contains("hidden")) bid_strategy_target.classList.add("hidden");
          bid_strategy_target.checked = true;
          label_bid_strategy.classList.add("hidden");
          text_checkbox_bid_strategy.classList.add("hidden");
          adset_bid_amount.placeholder = "SGD";
          adset_bid_amount.classList.remove("hidden");
          adset_bid_amount.required = true;
          adset_bid_target_impression_share.classList.remove("hidden");
          adset_bid_target_impression_share.required = true;
          bid_target_impression_location.classList.remove("hidden");
          break;
        case "manual_cpc":
          if (this.data.get("campaign-type") && this.data.get("campaign-type") == "shopping") {
            div_bid_enhanced_cpc_enabled.classList.remove("hidden");
            label_bid_strategy.classList.add("hidden");
            text_checkbox_bid_strategy.classList.add("hidden");
            adset_bid_amount.placeholder = "SGD";
            bid_strategy_target.checked = true;
            bid_strategy_target.classList.add("hidden");
            this.displayBidStrategyTargetOptional();
          } else {
            div_bid_strategy_optional.classList.add("hidden");
            adset_bid_amount.required = false;
          }
          break;
        case "manual_cpm":
          div_bid_strategy_optional.classList.add("hidden");
          break;
        case "target_roas":
          label_bid_strategy.classList.add("hidden");
          text_checkbox_bid_strategy.classList.add("hidden");
          adset_bid_amount.placeholder = "%";
          bid_strategy_target.checked = true;
          bid_strategy_target.classList.add("hidden");
          this.displayBidStrategyTargetOptional();
          break;
        default:
          adset_bid_amount.required = false;
          bid_strategy_target.classList.add("hidden");
          div_bid_strategy_optional.classList.add("hidden");
      }
    }
  }

  onChangeLocation() {
    let radio_custom_country = document.getElementById("all_country_custom_country").checked;
    let custom_country = document.getElementById("custom-country");
    if (radio_custom_country) {
      custom_country.classList.remove("hidden");
    } else {
      custom_country.classList.add("hidden");
    }
  }

  changeAudienceSegmentForm(e) {
    let targetingDropdown = document.getElementById("targeting-dropdown");
    let targetingSearch = document.getElementById("targeting-search");

    if(e.target.value == "audience_browse"){
      targetingSearch.classList.add("hidden");
      targetingDropdown.classList.remove("hidden");
    } else {
      targetingSearch.classList.remove("hidden");
      targetingDropdown.classList.add("hidden");
    }
  }

  checkLinkAccount(e) {
    let radio_business_profile = document.getElementById("link_account_business_profile");
    let form_business_name = document.getElementById("business-name-form");
    let radio_your_website = document.getElementById("final_url_website");
    let radio_your_profile = document.getElementById("final_url_profile");
    let field_your_website = document.getElementById("url");
    let field_business_name = document.getElementById("business_name");
    let selection_link_account = document.getElementById("link_account_id");
    
    if (form_business_name) {
      // reset component
      if (form_business_name.classList.contains("hidden")) form_business_name.classList.remove("hidden");
      field_your_website.required = false;
      field_business_name.required = false;
      radio_your_profile.disabled = false;
      selection_link_account.required = false;
  
      if (radio_business_profile.checked) {
        form_business_name.classList.add("hidden");
        selection_link_account.required = true;
      } else {
        radio_your_website.checked = true;
        field_your_website.required = true;
        field_business_name.required = true;
        radio_your_profile.disabled = true;
      }
  
      if (radio_your_website.checked) field_your_website.required = true;
    }
  }

  smartTargetLocation(e) {
    let radio_near_location = document.getElementById("advertise_location_near");
    let radio_specific_location = document.getElementById("advertise_location_specific");
    let div_near_location = document.getElementById("near-location-form");
    
    if (div_near_location) {
      // reset component
      if(div_near_location.classList.contains("hidden")) div_near_location.classList.remove("hidden");
  
      if (!radio_near_location.checked) {
        div_near_location.classList.add("hidden");
      }
    }
  }

  updateFBAdsetStartEndTime(e) {
    // Force Datetime become UTC || Flatpicker can't set default date into timezone UTC ( Always auto set as browser Timezone )
    let start_time = document.getElementById("adset_start_time");
    let end_time = document.getElementById("adset_end_time");
    if (start_time && start_time.value) {
      let new_start_time = new Date(start_time.value.replace(" UTC","")).toISOString();
      start_time.setAttribute("data-flatpickr-default-date", new_start_time)
    }
    if (end_time && end_time.value) {
      let new_end_time = new Date(end_time.value.replace(" UTC","")).toISOString();
      end_time.setAttribute("data-flatpickr-default-date", new_end_time)
    }    
  }

  showBidStrategyConversion(e) {
    let bid_control_conversion = document.getElementById("bid-control-conversion");
    let bid_strategy_value = document.getElementById("adset_bid_strategy");
    let bid_control_label = document.getElementById("bid_control_label");
    let bid_strategy_radio_bid_bid_cap = document.getElementById("bid_strategy_radio_bid_bid_cap");
    
    if (bid_control_conversion) {
      if (bid_strategy_radio_bid_bid_cap && bid_strategy_radio_bid_bid_cap.checked == true) {
        bid_control_label.textContent = "Bid control";
        bid_strategy_value.value = "lowest_cost_with_bid_cap";
      } else {
        bid_control_label.textContent = "Cost per result goal";
        bid_strategy_value.value = "cost_cap";
      }
    }
  }

  narrowFuther(e) {
    let detailed_targeting_default = document.getElementById("detailed_targeting_default");
    let detailed_targeting_include_people = document.getElementById("detailed_targeting_include_people");
    let no_action = parseInt(detailed_targeting_default.getAttribute("no_action"));
    // copy element
    let new_detailed_targeting_include_people = document.createElement("div");
    // replace ID become dynamic
    new_detailed_targeting_include_people.innerHTML = detailed_targeting_include_people.innerHTML.replace("search-results",`search-results${no_action}`).replace(`data-action="click->search#searchObject"`,`data-action="click->search#searchObjectNarrowFuther" data-search-object-param='{"action_no": "${no_action}"}'`).replace(`id="search"`,`id="search${no_action}"`).replace("result-dropdown",`result-dropdown${no_action}`).replace("checkbox-options",`checkbox-options${no_action}`).replace(`id="loader`,`id="loader${no_action}"`).replace("targeting-dropdown", `targeting-dropdown${no_action}`).replace(`data-action="click->adsets#showTargetingChoices`, `data-adsets-object-param='{"action_no": "${no_action}"}' data-action="click->adsets#narrowFuthershowTargetingChoices`).replaceAll("group-demographics", `group-demographics${no_action}`).replaceAll("sub-group-demo-",`sub-group-demo${no_action}-`).replaceAll("group-interests", `group-interests${no_action}`).replaceAll("sub-group-int-",`sub-group-int${no_action}-`).replaceAll("group-behaviors", `group-behaviors${no_action}`).replaceAll("sub-group-behavior-",`sub-group-behavior${no_action}-`).replaceAll(`data-action="input->search#displaySearchResults"`, `data-search-object-param='{"action_no": "${no_action}"}' data-action="input->search#NarrowFutherdisplaySearchResults"`).replace("Include people who match","and must also match").replace(`data-action="click->adsets#removeNarrowFuther"`, `data-adsets-object-param='{"action_no": "${no_action}"}' data-action="click->adsets#removeNarrowFuther"`).replace("removeNarrowFutherbtn", `removeNarrowFutherbtn${no_action}`).replaceAll("selected_items", `selected_items${no_action}`);
    
    new_detailed_targeting_include_people.id = `detailed_targeting_include_people${no_action}`
    
    // remove seleteced value from copier.
    let elem = new_detailed_targeting_include_people.childNodes[1].querySelectorAll("span");
    elem.forEach(e => e.remove());
    // append to top div
    detailed_targeting_default.append(new_detailed_targeting_include_people)
    // add no_action
    detailed_targeting_default.setAttribute("no_action",no_action + 1);
    // remove hidden close button for new narrow futher
    let removeNarrowFutherBtnElement = document.getElementById(`removeNarrowFutherbtn${no_action}`);
    removeNarrowFutherBtnElement.classList.remove("hidden");
  }
  
  narrowFuthershowTargetingChoices(e) {
    e.preventDefault();
    let targetingDropdown = document.getElementById(`targeting-dropdown${e.params.object.action_no}`);
    if (targetingDropdown.classList.contains('hidden')) {
      targetingDropdown.classList.remove("hidden");
    } else {
      targetingDropdown.classList.add("hidden");
    }
  }

  removeNarrowFuther(e) {
    e.preventDefault();
    let detailed_targeting_include_people_element = document.getElementById(`detailed_targeting_include_people${e.params.object.action_no}`);
    if (detailed_targeting_include_people_element) detailed_targeting_include_people_element.remove();
  }

  upBidAmount(e) {
    // this function for update both value of duplicate ID in html
    let bid_amount = document.querySelectorAll('input[name="adset[bid_amount]"]');
    for (let a in bid_amount) {
      if (bid_amount[a].id) {
        bid_amount[a].value = e.target.value
      }
    }
  }

  retargetAdsDisplay(e) {
    let retarget_ads = document.getElementById(`audience-retargetads`);
    let checked_retarget_ads = document.querySelector('input[name="audience_retarget_ads"]:checked');
    let retarget_ads_days_ids = document.querySelectorAll('input[name^="retarget_ads_days_"]');
    let checked_audience_catalog_sales = document.querySelector('input[name="audience_catalog_sales"]:checked');
    
    if(retarget_ads) {
      if (!retarget_ads.classList.contains("hidden")) retarget_ads.classList.add("hidden");
      if (checked_audience_catalog_sales.value == "retarget_ads") retarget_ads.classList.remove("hidden");
    } 

    // disable all input day
    for (let i in retarget_ads_days_ids) {
      if (retarget_ads_days_ids[i].id) {
        retarget_ads_days_ids[i].disabled = true;

        // hidden div ( up sell, cross sell, custom combination )
        let split_string_id = retarget_ads_days_ids[i].id.split("_");
        // get last string of id for check
        let div_element_to_hide = document.getElementById(`div_${split_string_id[split_string_id.length - 1]}`);
        if(div_element_to_hide && !div_element_to_hide.classList.contains('hidden')){
          div_element_to_hide.classList.add("hidden");
        }
        // set false required productset
        retarget_ads_productset_crosssell
        let select_element_product_set = document.getElementById(`retarget_ads_productset_${split_string_id[split_string_id.length - 1]}`);
        if(select_element_product_set) select_element_product_set.required = false;
      }
    }

    if (retarget_ads && checked_retarget_ads && checked_retarget_ads.value) {
      // current radio button checked - id
      let StringId = checked_retarget_ads.value.split("_");
      let lastStringId = StringId[StringId.length - 1];
      
      // enable input - day
      for (let i in retarget_ads_days_ids) {
        if (retarget_ads_days_ids[i].id && retarget_ads_days_ids[i].id.includes(lastStringId)) {
          retarget_ads_days_ids[i].disabled = false;

          // show div from hidden default
          let div_element_to_show = document.getElementById(`div_${lastStringId}`);
          if (div_element_to_show) {
            div_element_to_show.classList.remove("hidden");
          }
          // set true required productset
          let select_element_product_set_required = document.getElementById(`retarget_ads_productset_${lastStringId}`);
          if(select_element_product_set_required) select_element_product_set_required.required = true;
        }
      }
    }
  }

  getProductSets() {
    let product_catalog_id = document.getElementById("product_catalog_id");
    let product_sets = document.getElementById("product_sets");
    let selected_product_set = this.data.get("selected-product-set")
    Rails.ajax({
      url: '/facebook/get_product_sets',
      type: 'GET',
      data: "product_catalog_id=" + product_catalog_id.value,
      success: function(data) {
        // reset product_sets option
        product_sets.innerHTML = ''
        for(let d in data) {
          let option = document.createElement("option");
          let option_text = data[d]["name"].replace("_", " ");
          option.text = option_text[0].toUpperCase() + option_text.substring(1);
          option.value = data[d]["id"];
          product_sets.add(option);
        }
        (selected_product_set) ? product_sets.value = selected_product_set : "";
      }
    });
  }
  showExcludeTargeting(){
    let exclude_element = document.getElementById("exclude_detailed_targeting_default");
    exclude_element.classList.remove("hidden");

    let exclude_button = document.getElementById("excludeButton");
    exclude_button.classList.add("hidden");
  }

  onChangePixelCustomConversion(e) {
    let select_pixel_id = document.getElementById("pixel_id");
    let select_custom_event_type = document.getElementById("custom_event_type");
    let customconversion_value = this.data.get("customconversion-value")
    let customconversion_selected = this.data.get("customconversion-selected")
    let arr = JSON.parse(customconversion_value)
    const filteredArray = arr.filter(item => (item.pixel && item.pixel.id == select_pixel_id.value) || !item.pixel).sort((a, b) => a.name.localeCompare(b.name));
    
    // Clear Select Option
    select_custom_event_type.innerHTML = '';
    // Create a new option element
    const newOption = document.createElement('option');
    newOption.value = ""; // Set the value
    newOption.textContent = "Choose an event"; // Set the display text
    // Append the new option to the select element
    select_custom_event_type.appendChild(newOption);

    // Set New Option Depend Pixel Selected
    filteredArray.forEach(item => {
      // Create a new option element
      const newOption = document.createElement('option');
      newOption.value = item.id; // Set the value
      if (item.pixel) {
        newOption.textContent = `${item.name} (Custom)`; // Set the display text
      } else {
        newOption.textContent = item.name; // Set the display text
      }
      // Append the new option to the select element
      select_custom_event_type.appendChild(newOption);
    });

    // set default value
    if (customconversion_selected && customconversion_selected != "") select_custom_event_type.value = customconversion_selected
  }

  onChangeConversionEvent(){
    let dest_type = document.getElementById("adset_destination_type")
    let pixel_dropdown = document.getElementById("pixel-div-leads")
    let page_form = document.getElementById("page-form")
    let catalogue_form = document.getElementById("catalogue-form")
    let customEventSelect = document.getElementById("custom_event_type");
    if (dest_type.value === "website"){
      pixel_dropdown.classList.remove('hidden')
      customEventSelect.setAttribute('required', 'required')
      page_form.classList.add('hidden')
      catalogue_form.classList.add('hidden')
    }else if(dest_type.value === "on_ad"){
      pixel_dropdown.classList.add('hidden')
      customEventSelect.removeAttribute("required")
      page_form.classList.remove('hidden')
      catalogue_form.classList.remove('hidden')
    }
  }

  getOptimization() {
    let adset_destination_type = document.getElementById("adset_destination_type");
    let adset_optimization_goal = document.getElementById("adset_optimization_goal");
    let window_campaign_url = window.location.href;
    let campaign_id = window_campaign_url.match(/\/campaigns\/(\d+)\//)[1];
    if (adset_destination_type !== null){
      Rails.ajax({
        url: '/facebook/get_optimization',
        type: 'GET',
        data: "destination_type=" + adset_destination_type.value + "&campaign_id=" + campaign_id,
        success: function(data) {
          adset_optimization_goal.innerHTML = ''
          for(let d in data) {
            let option = document.createElement("option");
            let option_text = "";
            switch(data[d]){
              case "offsite_conversions":
                option_text = "Maximise number of conversions"
                break;
              case "landing_page_views":
                option_text = "Maximise number of landing page views"
                break;
              case "link_clicks":
                option_text = "Maximise number of link clicks"
                break;
              case "lead_generation":
                option_text = "Maximise number of leads"
                break; 
              case "quality_lead":
                option_text = "Maximise number of conversions leads"
                break;
            }
            option.text = option_text;
            option.value = data[d];
            adset_optimization_goal.add(option);
          }
        }
      });
    }
  }
  changeOptimizationText(){
    let window_campaign_url = window.location.href;
    let campaign_id = window_campaign_url.match(/\/campaigns\/(\d+)\//)[1];
    let selectElement = document.getElementById('adset_optimization_goal');

    Rails.ajax({
      url: '/facebook/get_campaign_type',
      type: 'GET',
      data: "campaign_id=" + campaign_id,
      success: function(data) {
        if(data == "outcome_awareness"){
          for (let i = 0; i < selectElement.options.length; i++) {
            let option = selectElement.options[i];
            switch(option.value){
              case 'ad_recall_lift':
                option.textContent = "Maximise ad recall lift"
                break;
              case 'reach':
                option.textContent = "Maximise reach of ads"
                break;
              case 'impressions':
                option.textContent = "Maximise number of impressions"
                break; 
              case 'thruplay':
                option.textContent = "Maximise ThruPlay views"
                break; 
              case 'two_second_continuous_video_views':
                option.textContent = "Maximise 2-second continous video plays"
                break; 
              
            }
          }
        } else{
          for (let i = 0; i < selectElement.options.length; i++) {
            let option = selectElement.options[i];
            switch(option.value){
              case 'link_clicks':
                option.textContent = "Maximise number of link clicks"
                break;
              case 'impressions':
                option.textContent = "Maximise number of impressions"
                break;
              case 'landing_page_views':
                option.textContent = "Maximise number of landing page views"
                break; 
              case 'reach':
                option.textContent = "Maximise daily unique reach"
                break; 
              case 'conversations':
                option.textContent = "Maximise number of conversations"
                break; 
              case 'post_engagement':
                option.textContent = "Maximise engagement with a post"
                break;
              case 'lead_generation':
                option.textContent = "Maximise number of leads"
                break; 
              case 'quality_lead':
                option.textContent = "Maximise number of conversions leads"
                break; 
              case 'offsite_conversions':
                option.textContent = "Maximise number of conversions"
                break; 
            }
          }
        }
      }
    });
  }

  updateBudget(){
    let window_adset_url = window.location.href;
    let adset_id = window_adset_url.match(/\/adsets\/(\d+)\//)[1];
    let budget_amount = document.getElementById("adset_daily_budget").value;
    Rails.ajax({
      url:'/facebook/update_budget',
      type: 'POST',
      data: "adset_id=" + adset_id + "&budget_amount=" + budget_amount,
      success: function(){
        location.reload();
      }
    })
  } 
}
