import ApplicationController from '../application_controller'
import { Redirect, AppLink, NavigationMenu } from '@shopify/app-bridge/actions'

export default class extends ApplicationController {
  initialize () {
    super.initialize()
  }

  connect () {
    super.connect()
    const app = this.appBridge
    
    this.show_image_step();

    // ----- App Link / Navigation | START Code
    const viewCampaignsLink = AppLink.create(app, {
        label: 'View Campaigns',
        destination: '/api_app_shopify',
      });
      const createCampaignLink = AppLink.create(app, {
        label: 'Create Campaign',
        destination: '/shopify_create_campaign',
      });
    //   create NavigationMenu with no active links
      const navigationMenu = NavigationMenu.create(app, {
        items: [viewCampaignsLink, createCampaignLink],
        active: createCampaignLink,
      });
    // ----- App Link / Navigation | END Code
    
  }

  show_image_step() {
    let step_form = document.getElementById("step_form");
    let step = parseInt(step_form.getAttribute("step"))
    for (let i = 1; i <= 5; i++) {
      if (document.getElementById(`image_step_${i}`) && !document.getElementById(`image_step_${i}`).classList.contains("hidden")) document.getElementById(`image_step_${i}`).classList.add("hidden")
    }
    if (step >= 1 && step <= 3) {
      if (document.getElementById(`image_step_1`) && document.getElementById(`image_step_1`).classList.contains("hidden")) document.getElementById(`image_step_1`).classList.remove("hidden")
    } else if (step >= 4 && step <= 7) {
      if (document.getElementById(`image_step_2`) && document.getElementById(`image_step_2`).classList.contains("hidden")) document.getElementById(`image_step_2`).classList.remove("hidden")  
    } else if (step >= 8 && step <= 9) {
      if (document.getElementById(`image_step_3`) && document.getElementById(`image_step_3`).classList.contains("hidden")) document.getElementById(`image_step_3`).classList.remove("hidden")
    }
  }

  prev_btn() {
    let step_form = document.getElementById("step_form");
    let prev_btn = document.getElementById("prev_btn");
    let next_btn = document.getElementById("next_btn");
    let submit_form = document.getElementById("submit_form");
    
    let step_value_after_change = (parseInt(step_form.getAttribute("step")) - 1);

    // update step_form
    step_form.setAttribute("step", step_value_after_change );

    // hidden prev button
    if (step_value_after_change == 1) {
      prev_btn.style.visibility = "hidden";
    } 

    if (step_value_after_change == 9) {
      next_btn.style.visibility = "visible";
      submit_form.style.visibility = "hidden";
    } else {
      next_btn.style.visibility = "visible";
      submit_form.style.visibility = "hidden";
      submit_form.style.display = "none";
    }

    // hidden or show form input element
    let step_form_before_prev = document.getElementById(`step_form_${step_value_after_change + 1}`);
    let step_form_after_prev = document.getElementById(`step_form_${step_value_after_change}`);
    if (step_form_before_prev) step_form_before_prev.classList.add("hidden");
    if (step_form_after_prev) step_form_after_prev.classList.remove("hidden");

    this.show_image_step();
  };

  validation_field(step_value_after_change) {
    let step_form = document.getElementById(`step_form_${step_value_after_change - 1}`);
    let inputs = step_form.getElementsByClassName(`data_${step_value_after_change - 1}`);
    for(var key in inputs) {
      if (Number.isInteger(parseInt(key)) && inputs[key] && inputs[key].name) {
        // validation field required 
        if (inputs[key].required && !inputs[key].value) return [false];
        
        // validation not allow select same country 
        if (inputs[key].name.includes("country")) {
          let include_country = Array.from(document.getElementById("included_country_")).filter(function (option) { return option.selected; }).map(function (option) { return option.value; });
          let exclude_country = Array.from(document.getElementById("excluded_country_")).filter(function (option) { return option.selected; }).map(function (option) { return option.value; });
          const check_country = include_country.filter(element => exclude_country.includes(element));
          if (check_country.length > 0) return [false,"Can't choose the same Country!"];
        }

        // validation social media
        if (inputs[key].name.includes("social_media")) {
          let social_media_ids = document.querySelectorAll("[id^='social_media']");
          let social_media_arr = [];
          for(var social_media in social_media_ids) {
            if (social_media_ids[social_media].value) social_media_arr.push(social_media_ids[social_media].value);
          }
          if (social_media_arr.length == 0) return [false,"Please fill in the information, at least one, before the next step"];
        }
      }
    }
    return [true];
  };

  next_btn() {
    let step_form = document.getElementById("step_form");
    let prev_btn = document.getElementById("prev_btn");
    let next_btn = document.getElementById("next_btn");
    let submit_form = document.getElementById("submit_form");
    let step_value_after_change = (parseInt(step_form.getAttribute("step")) + 1);
    let is_valid = this.validation_field(step_value_after_change);
    
    if (is_valid[0]) {
      // update step_form
      step_form.setAttribute("step", step_value_after_change );
  
      // hidden prev button
      if (step_value_after_change > 1) {
        if (prev_btn) prev_btn.style.visibility = "visible";
        if(submit_form) submit_form.style.visibility = "hidden";
      } 
  
      if (step_value_after_change == 9) {
        if(next_btn) next_btn.style.visibility = "hidden";
        if(submit_form) submit_form.style.visibility = "visible";
        if(submit_form) submit_form.style.display = "";
      }
  
      // hidden or show form input element
      let step_form_before_next = document.getElementById(`step_form_${step_value_after_change - 1}`);
      let step_form_after_next = document.getElementById(`step_form_${step_value_after_change}`);
      if (step_form_before_next) step_form_before_next.classList.add("hidden");
      if (step_form_after_next) step_form_after_next.classList.remove("hidden");
  
      this.show_image_step();
    } else {
      const toastErrorOptions = {
        message: (is_valid[1]) || 'Please fill in the information, before the next step',
        duration: 5000
      }
      this.flashError(toastErrorOptions)
    }
  }

  req_form_data() {
    var form_data = {};
    // prepare params input
    var inputs = document.getElementsByTagName('input');
    for(var key in inputs) {
      if (inputs[key] && inputs[key].name && inputs[key].name.includes("target_age")) {
        form_data[inputs[key].name] = inputs[key].checked;
      } else if (inputs[key] && inputs[key].type && inputs[key].type == "radio") {
        form_data[inputs[key].name] = document.querySelector(`input[type=radio][name=${inputs[key].name}]:checked`).value;
      } else if (inputs[key] && inputs[key].name) {
        form_data[inputs[key].name] = inputs[key].value;
      }
    }

    // prepare params select
    var selects = document.getElementsByTagName('select');
    for(var key in selects) {
      if (selects[key] && selects[key].name && selects[key].name.includes("[]")) {
        form_data[selects[key].name.replace("[]","")] = Array.from(selects[key]).filter(function (option) { return option.selected; }).map(function (option) { return option.value; });
      } else if (selects[key] && selects[key].name) {
        form_data[selects[key].name] = selects[key].value;
      }
    }

    form_data["shop"] = this.data.get("myShop");
    form_data["credential"] = this.data.get("myShopToken")

    return form_data;
  }

  submit_form() {
    var form_data = this.req_form_data()
    const formData = new FormData();
    let monthly_budget_amount = document.getElementById('monthly_budget_amount');

    if (form_data["fileInput"]) delete form_data["fileInput"];
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      const file = fileInput.files[0];
      if (file != undefined) formData.append('file', file);
    }

    for(var key in form_data) {
      // Remove not use data
      if (!['fileInput','item','namedItem'].includes(key)) {
        formData.append(key, form_data[key]);
      }
    }

    const toastErrorOptions = {
      message: 'Submit Failed, Please Contact to Admate Support',
      duration: 5000
    }

    if (monthly_budget_amount && monthly_budget_amount.value <= 1000 && monthly_budget_amount.value > 0) {
      var myHeaders = new Headers();
      fetch(`${window.location.protocol + '//' + window.location.host + "/shopify_campaigns"}`, {
        method: "POST",
        body: formData,
        headers: myHeaders
      }).then((response) => {
        // response.json()
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then((json) => {
        if (json["status"] == "success") {
          const toastOptions = {
            message: 'Request Submitted',
            duration: 5000
          }
          this.flashNotice(toastOptions)
          const redirect = Redirect.create(this.appBridge);
          redirect.dispatch(Redirect.Action.REMOTE, json["payment_url"])
        } else {
          this.flashError(toastErrorOptions)
        }
        
      })
      .catch((error) => {
        this.flashError(toastErrorOptions)
      });
    } else {
      const toastErrorOptions = {
        message: 'Max Budget USD 1000',
        duration: 5000
      }
      this.flashError(toastErrorOptions)
    }
    
  }

  up_budget() {
    let monthly_budget_amount = document.getElementById('monthly_budget_amount');
    let monthly_budget = document.querySelector(`input[type=radio][name=montly_budget_option]:checked`);
    if (monthly_budget_amount && monthly_budget && monthly_budget.value == 'no') {
      monthly_budget_amount.value = 100;
    }    
  }

  disconnect () {
    super.disconnect()
  }
}