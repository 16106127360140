import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    console.log("Im connected to GA Accounts!");
  }

  getGaProperties(e){
    Rails.ajax({
      url: '/retrieve_ga_properties',
      type: 'POST',
      data: "ga_account_id=" + e.target.value,
      success: function(data) {
        let gaProperties = document.getElementById("ga-properties");
        let gaAccounts = document.getElementById("ga-account");
        // Remove existing hidden field and add input hidden field
        let gaAccountsHiddenField = gaAccounts.querySelector('input[type="hidden"]');
        if (gaAccountsHiddenField !== null){
          gaAccountsHiddenField.remove();
        }
        // Add hidden field to send display name, set display name on the event target
        let hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = "ga_account[name]";
        hiddenField.id = "ga_account_name";
        hiddenField.value = e.target.dataset.gaAccountDisplayName;
        gaAccounts.appendChild(hiddenField);

        // Remove property radio button when user rechoose options
        while (gaProperties.firstChild) {
          gaProperties.removeChild(gaProperties.lastChild);
        }
        data.forEach((d) => {
          let label = document.createElement("label");
          label.classList.add("text-monochrome-grey-blue-dark-1", "font-normal", "capitalize", "my-5")
          label.for = "ga_property_ga_property_id_" + d.id;
          let input = document.createElement("input");
          input.type = "radio";
          input.value = JSON.stringify({
            property_id: d.name,
            display_name: d.display_name
          });
          input.classList.add("mr-2");
          input.name = "ga_property[ga_property_id]";
          input.id = "ga_property_ga_property_id_property" + d.id;
          input.dataset.action = "change->ga-integration#checkAllChecked"
          input.dataset.checked = "ga_property"
          let span = document.createElement("span");
          span.classList.add("text-sm");
          span.innerHTML = d.display_name;
          label.appendChild(input);
          label.appendChild(span);
          gaProperties.appendChild(label);
        })
      }
    });
  }
}